// Dependencies
import angular from 'angular';
import angularCookies from 'angular-cookies';
import angularSanitize from 'angular-sanitize';
import angularModalService from 'angular-modal-service';
import angularHammer from 'angular-hammer';
import '../node_modules/angular-paging/dist/paging.js';
import './modules/utilities/touchevents.js';

// Modules
import userModule from './modules/user';
import navModule from './modules/nav';
import searchModule from './modules/search';
import patchModule from './modules/patch';
import modalModule from './modules/modal';
import commonModule from './modules/common';
import controlModule from './modules/control';
import ngTemplates from './modules/ngtemplates.js';
import deviceModule from './modules/device';

// App
angular.module('app', [
  angularHammer,
  angularCookies,
  angularSanitize,
  angularModalService,
  'bw.paging',
  commonModule,
  userModule,
  navModule,
  searchModule,
  patchModule,
  modalModule,
  controlModule,
  ngTemplates,
])
.constant('AppSettings', {
  dpiScale: 2, // DPI scaling for patch cables (reduces fuzziness on retina screens)
  displayScale: 1, // Scale factor for devices (enforces maximum display width)
  cableWidth: 15,
  cableDroop: 50,
  chainSockets: true,
  alwaysAddPatchbay: false,
  keepPatchbayAtBottom: true,
  fixedWidth: 1300,
  userSettings: {
    cableType:  'cables',
    cableColor: 'group',
    deviceSize: 'proportional',
  },
  cableColors: {
    default: 'gray',
    signal: {
      unknown:    'orange',
      audio:      'orange',
      modulation: 'steelblue',
      pitch:      'mediumseagreen',
      gate:       'indianred',
      trigger:    'salmon',
      clock:      'orchid',
    },
    group: [
      'aqua', 'aquamarine', 'burlywood', 'cadetblue', 'coral', 'cornflowerblue', 'crimson', 'cyan', 'darkcyan', 'darkgoldenrod', 'darkkhaki', 'darkolivegreen', 'darkorange', 'darkseagreen', 'deepskyblue', 'forestgreen', 'goldenrod', 'greenyellow', 'hotpink', 'indianred',
    ],
  },
})
.constant('Devices', deviceModule)
.config(['$locationProvider', '$httpProvider',
function($locationProvider, $httpProvider) {
    $httpProvider.interceptors.push('httpInterceptor'); 
}])
.factory('httpInterceptor', ['$q', '$cookies', 
  function($q, $cookies) {
    return {
      request: function(config) {
        config.data = config.data || {};
        // Add CSRF token to POST requests - Ref: http://teknosains.com/i/how-to-ajax-csrf-token-in-codeigniter-3-and-angularjs
        config.data['csrf_token'] = $cookies.get('csrf_cookie');
        config.headers['X-Csrf-Token'] = $cookies.get('csrf_cookie'); 
        // Prevent mobile Safari from caching POST requests
        if(config.method.toUpperCase() === 'POST') {
          config.headers['cache-control'] = 'no-cache';
        }
        return config;
      },
      responseError: function(response) {
        return $q.reject(response);
      }
    }
  }
]);

