export default [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'upgrade-message.html',
      scope: {
        context: '@',
      },
      controller: ['$scope', function($scope){

        $scope.signin = function(){
          $scope.$root.$broadcast('signin');
        }

        $scope.profile = function(){
          $scope.$root.$broadcast('profile');
        }

      }]
    };
  }
]
