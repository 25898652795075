import angular from 'angular';

import rootController from './root.controller';
import commonService from './common.service';
import subnavDirective from './subnav.directive';
import cookiesDirective from './cookies-message.directive';

const module = angular.module('commonModule', [])
  .controller('RootController', rootController)
  .service('CommonService', commonService)
  .directive('subnav', subnavDirective)
  .directive('cookiesMessage', cookiesDirective);

export default module.name;