export default [
  function() {
    return {
      restrict: 'EA',
      scope: true,
      controller: ['$scope', function($scope){
        if(!localStorage.getItem('cookies')) {
          $scope.active = true;
        }

        $scope.close = function(){
          localStorage.setItem('cookies', 1);
          $scope.active = false;
        }
      }]
    };
  }
]
