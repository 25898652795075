
export default ['$scope', 'UserService', 'close',
  function($scope, UserService, close) {

    $scope.status = null;
    $scope.error = null;
    $scope.success = null;
    
    $scope.formdata = {
      email: '',
    }

    $scope.init = function(){
      $scope.$watch('formdata', function(){
        $scope.canSubmit = $scope.userForgotForm.$valid;
      }, true);
    }

    $scope.submit = function(){
      $scope.error = null;
      $scope.success = null;
      $scope.status = 'submitting';

      const data = {
        email: $scope.formdata.email,
      };

      UserService.forgotPassword(data).then(function(result){
        $scope.status = null;

        if(result.success) {
          $scope.success = true;
        }
        else {
          $scope.error = result.error;
        }
      });
    }

    $scope.cancel = function(){
      close(false);
      history.replaceState(null, null, location.pathname);
    }

    $scope.init();

  }
]
