import angular from 'angular';

export default ['$q', 'SearchService', 'PatchService', 'ModalService', 'UserService', 'CommonService',
  function($q, SearchService, PatchService, ModalService, UserService, CommonService){
    return {
      restrict: 'E',
      templateUrl: 'search.html',
      replace: true,
      scope: {
        user: '@',
        device: '@',
      },
      controller: ['$scope', function($scope){

        $scope.status = null;
        $scope.advancedSearch = false;
        $scope.deviceList = {};

        $scope.formdata = {
          page: 1,
          sort: 'created',
          user: $scope.user ? $scope.user : null,
          device: $scope.device ? $scope.device : null,
          favourites: null,
          category: null,
          style: null,
          single: null,
          fuzzy: null,
        };
        
        $scope.sortOptions = [
          { 
            slug: 'created', 
            title: 'Sort by Newest' 
          },
          { 
            slug: 'popular', 
            title: 'Sort by Popularity' 
          },
        ];

        $scope.init = function(){
          $scope.status = 'loading';
          $scope.formdataClean = angular.copy($scope.formdata);

          $q.all([
            PatchService.getCategories(),
            PatchService.getDevices(true),
            CommonService.getConfig(),
          ])
          .then(function(result){
            $scope.status = null;
            $scope.categories = result[0].data;
            $scope.devices = result[1].data;
            $scope.config = result[2].data;
            $scope.populateSearch();
            $scope.buildDeviceList();

            // Update querystring & search on formdata change
            $scope.$watch('formdata', function(value, oldValue){
              if(!angular.equals(value, oldValue)) {
                const params = new URLSearchParams;
                Object.keys($scope.formdata).map(function(key){
                  if($scope.formdata[key] && !$scope[key]) {
                    params.set(key, $scope.formdata[key]);
                  }
                });

                history.pushState(null, null, `?${params.toString().replace(/%2C/g, ',')}`);
                $scope.buildDeviceList();
              }
              
              $scope.doSearch();
            }, true);

          });
        }

        /** Set (or remove) a search filter. Pass a slug to remove matching item from comma-delimited filters (device list)
         */
        $scope.setFilter = function(key, value, slug){
          if(!slug) {
            $scope.formdata[key] = value ? value : null;
          }
          else {
            const values = $scope.formdata[key].split(',').filter(function(item){
              return item.indexOf(slug) === -1;
            }).join(',');

            $scope.formdata[key] = values ? values : null;
          }

          if(key !== 'page') {
            $scope.formdata.page = 1;
          }
        }

        /** Populate search form data from querystring
         */
        $scope.populateSearch = function(){
          const params = new URLSearchParams(location.search);

          $scope.formdata = {
            page: params.get('page') || 1,
            sort: params.get('sort') || 'created',
            user: $scope.user ? $scope.user : params.get('user'),
            device: $scope.device ? $scope.device : params.get('device'),
            favourites: params.get('favourites'),
            category: params.get('category'),
            style: params.get('style'),
            single: params.get('single'),
            fuzzy: params.get('fuzzy'),
          };
        }

        /** Perform a search
         */
        $scope.doSearch = function(){
          const data = {
            page: $scope.formdata.page,
            sort: $scope.formdata.sort,
            user: $scope.formdata.user,
            device: $scope.formdata.device,
            favourites: $scope.formdata.favourites,
            category: $scope.formdata.category,
            style: $scope.formdata.style,
            single: $scope.formdata.single,
            fuzzy: $scope.formdata.fuzzy,
          };

          $scope.status = 'loading';
          SearchService.searchPatches(data)
          .then(function(result){
            $scope.status = null;
            $scope.searched = true;

            if(result.success) {
              $scope.patches = result.data.patches;
              $scope.filters = result.data.filters;
              $scope.count = result.data.count;
            }
            else {
              $scope.error = result.error;
            }
          });
        }

        /** Build list of searched devices from formdata
         */
        $scope.buildDeviceList = function(){
          const deviceList = {};

          if($scope.formdata.device) {
            const devices = $scope.formdata.device;
            if(devices.match(/\-x[0-9]+/) || devices.indexOf(',') > -1) {
              $scope.advancedSearch = true;

              devices.split(',').map(function(item){
                const match = item.match(/\-x[0-9]+/);
                if(match) {
                  const id = item.substr(0, match.index);
                  deviceList[id] = {
                    slug: id,
                    name: `${$scope.devices[id].name} ${item.substr(match.index + 1)}`,
                    quantity: item.substr(match.index + 2),
                  };
                }
                else {
                  const id = item;
                  deviceList[id] = {
                    slug: id,
                    name: $scope.devices[id].name,
                    quantity: 'any',
                  };
                }
              });
            }
            else {
              $scope.advancedSearch = false;

              const id = devices;
              deviceList[id] = {
                slug: id,
                name: $scope.devices[id].name,
                quantity: 'any',
              };
            }
          }
          else {
            $scope.advancedSearch = false;
          }

          $scope.deviceList = deviceList;
        }

        /** Format searched devices as string
         */
        $scope.deviceListDisplay = function(){
          const list = [];
          if($scope.deviceList) {
            Object.keys($scope.deviceList).map(function(key){
              list.push($scope.deviceList[key].name);
            });
          }
          return list.join(' + ');
        }

        /** Invoke advanced search options & process the result
         */
        $scope.advanced = function(){
          ModalService.showModal({
            templateUrl: 'advanced-search.html',
            controller: 'AdvancedSearchController',
            inputs: {
              data: {
                fuzzy: parseInt($scope.formdata.fuzzy),
                single: parseInt($scope.formdata.single),
                devices: angular.copy($scope.deviceList),
              },
            }
          }).then(function(modal) {
            modal.close.then(function(result) {
              if(result) {
                UserService.setSetting('searchFuzzy', result.fuzzy);
                UserService.setSetting('searchSingle', result.single);

                const data = result.devices;
                const devices = [];

                Object.keys(data).map(function(key){
                  const item = data[key];
                  if(item.quantity === 'any') {
                    devices.push(key);
                  }
                  else if(item.quantity) {
                    devices.push(`${key}-x${item.quantity}`);
                  }
                });

                $scope.setFilter('fuzzy', result.fuzzy);
                $scope.setFilter('single', result.single);
                $scope.setFilter('device', devices.join(','));
              }
            });
          });
        }

        $scope.loadPage = function(page){
          $scope.setFilter('page', page);
          window.scrollTo(0, 0);
        }

        $scope.reset = function(){
          $scope.formdata = angular.copy($scope.formdataClean);
        }

        $scope.showReset = function(){
          const data = $scope.formdata;
          if(data.device || data.category || data.style) {
            return true;
          }
          else {
            return false;
          }
        }

        // Update formdata and search on history back/forward
        window.addEventListener('popstate', function(){
          $scope.$apply(function(){
            $scope.populateSearch();
          });
        }, false);

        $scope.init();

      }]
    };
  }
]
