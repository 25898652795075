export default {
  "name": "Grandmother",
  "id": "grandmother",
  "vendor": "Moog",
  "width": 2260,
  "height": 964,
  "scale": 1,
  "images": {
    "panel": "grandmother-panel.png",
    "thumbnail": "grandmother-thumbnail.png",
    "selector": "grandmother-selector.png"
  },
  "notes": "The Grandmother is a semi-modular keyboard sythesizer from Moog Music, with a built-in arpeggiator / sequencer and spring reverb.",
  "link": "https://www.moogmusic.com/products/grandmother",
  "sections": [
    {
      "id": "arpseq",
      "name": "Arp / Seq",
      "controls": [
        {
          "id": "arpseq-rate",
          "name": "Rate",
          "type": "knob",
          "class": "knob-1",
          "top": 255,
          "left": 157,
          "init": 180
        },
        {
          "id": "arpseq-mode",
          "name": "Mode",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Arp",
            "Seq",
            "Rec"
          ],
          "top": 379,
          "left": 157,
          "init": 0
        },
        {
          "id": "arpseq-direction",
          "name": "Direction",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Ordr",
            "Fwd/Bwd",
            "Rndm"
          ],
          "top": 477,
          "left": 157,
          "init": 0
        },
        {
          "id": "arpseq-octave",
          "name": "Oct / Seq",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "1",
            "2",
            "3"
          ],
          "top": 572,
          "left": 157,
          "init": 0
        }
      ],
      "sockets": [
        {
          "id": "arpseq-gate",
          "name": "Gate Out",
          "type": "output",
          "signal": "gate",
          "top": 79,
          "left": 157
        },
        {
          "id": "arpseq-kb",
          "name": "KB Out",
          "type": "output",
          "signal": "pitch",
          "top": 143,
          "left": 113
        },
        {
          "id": "arpseq-vel",
          "name": "KB Vel Out",
          "type": "output",
          "signal": "modulation",
          "top": 143,
          "left": 201
        }
      ]
    },
    {
      "id": "modulation",
      "name": "Modulation",
      "controls": [
        {
          "id": "modulation-rate",
          "name": "Rate",
          "type": "knob",
          "class": "knob-1",
          "top": 255,
          "left": 423,
          "init": 180
        },
        {
          "id": "modulation-pitch",
          "name": "Pitch Amt",
          "type": "knob",
          "class": "knob-1",
          "top": 406,
          "left": 341,
          "init": 180
        },
        {
          "id": "modulation-cutoff",
          "name": "Cutoff Amt",
          "type": "knob",
          "class": "knob-1",
          "top": 406,
          "left": 506,
          "init": 180
        },
        {
          "id": "modulation-pw",
          "name": "Pulse Width Amt",
          "type": "knob",
          "class": "knob-1",
          "top": 559,
          "left": 341,
          "init": 180
        },
        {
          "id": "modulation-waveform",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-2",
          "range": [
            135,
            225
          ],
          "values": [
            "Sine",
            "Ramp",
            "Saw",
            "Square"
          ],
          "top": 559,
          "left": 506,
          "init": 135
        }
      ],
      "sockets": [
        {
          "id": "modulation-ratein",
          "name": "Rate In",
          "type": "input",
          "signal": "modulation",
          "top": 79,
          "left": 297
        },
        {
          "id": "modulation-waveout",
          "name": "Wave Out",
          "type": "output",
          "signal": "modulation",
          "top": 79,
          "left": 549
        },
        {
          "id": "modulation-syncin",
          "name": "Sync In",
          "type": "input",
          "signal": "gate",
          "top": 143,
          "left": 381
        },
        {
          "id": "modulation-shout",
          "name": "S/H Out",
          "type": "output",
          "signal": "modulation",
          "top": 143,
          "left": 465
        }
      ]
    },
    {
      "id": "vco1",
      "name": "Oscillator 1",
      "controls": [
        {
          "id": "vco1-octave",
          "name": "Octave",
          "type": "knob",
          "class": "knob-2",
          "range": [
            135,
            225
          ],
          "values": [
            "32\"",
            "16\"",
            "8\"",
            "4\""
          ],
          "top": 255,
          "left": 690,
          "init": 135
        },
        {
          "id": "vco1-waveform",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-2",
          "range": [
            135,
            225
          ],
          "values": [
            "Triangle",
            "Ramp",
            "Square",
            "Pulse"
          ],
          "top": 559,
          "left": 690,
          "init": 135
        },
        {
          "id": "vco1-sync",
          "name": "Sync",
          "type": "switch",
          "class": "switch-2",
          "values": [
            "Off",
            "On"
          ],
          "top": 406,
          "left": 690,
          "init": 0
        }
      ],
      "sockets": [
        {
          "id": "vco1-wave",
          "name": "Wave Out",
          "type": "output",
          "signal": "audio",
          "top": 79,
          "left": 689
        },
        {
          "id": "vco1-pitch",
          "name": "Pitch In",
          "type": "input",
          "signal": "pitch",
          "top": 143,
          "left": 645
        },
        {
          "id": "vco1-pwm",
          "name": "PWM In",
          "type": "input",
          "signal": "modulation",
          "top": 143,
          "left": 733
        }
      ]
    },
    {
      "id": "vco2",
      "name": "Oscillator 2",
      "controls": [
        {
          "id": "vco2-octave",
          "name": "Octave",
          "type": "knob",
          "class": "knob-2",
          "range": [
            135,
            225
          ],
          "values": [
            "16\"",
            "8\"",
            "4\"",
            "2\""
          ],
          "top": 255,
          "left": 855,
          "init": 135
        },
        {
          "id": "vco2-waveform",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-2",
          "range": [
            135,
            225
          ],
          "values": [
            "Triangle",
            "Ramp",
            "Square",
            "Pulse"
          ],
          "top": 559,
          "left": 855,
          "init": 135
        },
        {
          "id": "vco2-freq",
          "name": "Frequency",
          "type": "knob",
          "class": "knob-1",
          "top": 406,
          "left": 855,
          "init": 180
        }
      ],
      "sockets": [
        {
          "id": "vco2-wave",
          "name": "Wave Out",
          "type": "output",
          "signal": "audio",
          "top": 79,
          "left": 853
        },
        {
          "id": "vco2-pitch",
          "name": "Pitch In",
          "type": "input",
          "signal": "pitch",
          "top": 143,
          "left": 809
        },
        {
          "id": "vco2-fm",
          "name": "Lin FM In",
          "type": "input",
          "signal": "modulation",
          "top": 143,
          "left": 897
        }
      ]
    },
    {
      "id": "mixer",
      "name": "Mixer",
      "controls": [
        {
          "id": "mixer-osc1",
          "name": "Oscillator 1",
          "type": "knob",
          "class": "knob-1",
          "top": 255,
          "left": 1038,
          "init": 180
        },
        {
          "id": "mixer-osc2",
          "name": "Oscillator 2",
          "type": "knob",
          "class": "knob-1",
          "top": 559,
          "left": 1038,
          "init": 30
        },
        {
          "id": "mixer-noise",
          "name": "Noise",
          "type": "knob",
          "class": "knob-1",
          "top": 406,
          "left": 1038,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "mixer-osc1in",
          "name": "Osc 1 In",
          "type": "input",
          "signal": "audio",
          "top": 79,
          "left": 993
        },
        {
          "id": "mixer-osc2in",
          "name": "Osc 2 In",
          "type": "input",
          "signal": "audio",
          "top": 79,
          "left": 1081
        },
        {
          "id": "mixer-noisein",
          "name": "Noise In",
          "type": "input",
          "signal": "audio",
          "top": 143,
          "left": 993
        },
        {
          "id": "mixer-out",
          "name": "Output",
          "type": "output",
          "signal": "audio",
          "top": 143,
          "left": 1081
        }
      ]
    },
    {
      "id": "utilities",
      "name": "Utilities",
      "controls": [
        {
          "id": "utilities-hpf",
          "name": "High Pass",
          "type": "knob",
          "class": "knob-1",
          "top": 255,
          "left": 1222,
          "init": 30
        },
        {
          "id": "utilities-att",
          "name": "Attenuator",
          "type": "knob",
          "class": "knob-1",
          "top": 483,
          "left": 1222,
          "init": 180
        }
      ],
      "sockets": [
        {
          "id": "utilities-mult1",
          "name": "Mult 1",
          "type": "any",
          "signal": "unknown",
          "pass": [
            "utilities-mult1",
            "utilities-mult2",
            "utilities-mult3",
            "utilities-mult4"
          ],
          "top": 79,
          "left": 1177
        },
        {
          "id": "utilities-mult2",
          "name": "Mult 2",
          "type": "any",
          "signal": "unknown",
          "pass": [
            "utilities-mult1",
            "utilities-mult2",
            "utilities-mult3",
            "utilities-mult4"
          ],
          "top": 79,
          "left": 1265
        },
        {
          "id": "utilities-mult3",
          "name": "Mult 3",
          "type": "any",
          "signal": "unknown",
          "pass": [
            "utilities-mult1",
            "utilities-mult2",
            "utilities-mult3",
            "utilities-mult4"
          ],
          "top": 143,
          "left": 1177
        },
        {
          "id": "utilities-mult4",
          "name": "Mult 4",
          "type": "any",
          "signal": "unknown",
          "pass": [
            "utilities-mult1",
            "utilities-mult2",
            "utilities-mult3",
            "utilities-mult4"
          ],
          "top": 143,
          "left": 1265
        },
        {
          "id": "utilities-hpfin",
          "name": "High Pass Input",
          "type": "input",
          "signal": "audio",
          "top": 355,
          "left": 1177
        },
        {
          "id": "utilities-hpfout",
          "name": "High Pass Output",
          "type": "output",
          "signal": "audio",
          "top": 355,
          "left": 1265
        },
        {
          "id": "utilities-attin",
          "name": "Attenuator Input",
          "type": "input",
          "pass": [
            "utilities-attin",
            "utilities-attout"
          ],
          "top": 583,
          "left": 1177
        },
        {
          "id": "utilities-attout",
          "name": "Attenuator Output",
          "type": "output",
          "signal": "modulation",
          "pass": [
            "utilities-attin",
            "utilities-attout"
          ],
          "top": 583,
          "left": 1265
        }
      ]
    },
    {
      "id": "filter",
      "name": "Filter",
      "controls": [
        {
          "id": "filter-cutoff",
          "name": "Cutoff",
          "type": "knob",
          "class": "knob-3",
          "top": 255,
          "left": 1489,
          "init": 330
        },
        {
          "id": "filter-env",
          "name": "Envelope Amt",
          "type": "knob",
          "class": "knob-1",
          "top": 559,
          "left": 1406,
          "init": 180
        },
        {
          "id": "filter-res",
          "name": "Resonance",
          "type": "knob",
          "class": "knob-1",
          "top": 559,
          "left": 1571,
          "init": 30
        },
        {
          "id": "filter-kbtrack",
          "name": "KBD Track",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "1:2",
            "Off",
            "1:1"
          ],
          "top": 416,
          "left": 1488,
          "init": 1
        }
      ],
      "sockets": [
        {
          "id": "filter-input",
          "name": "Input",
          "type": "input",
          "signal": "audio",
          "top": 79,
          "left": 1361
        },
        {
          "id": "filter-output",
          "name": "Output",
          "type": "output",
          "signal": "audio",
          "top": 79,
          "left": 1617
        },
        {
          "id": "filter-envin",
          "name": "Env Amt In",
          "type": "input",
          "signal": "modulation",
          "top": 143,
          "left": 1445
        },
        {
          "id": "filter-cutoffin",
          "name": "Cutoff In",
          "type": "input",
          "signal": "modulation",
          "top": 143,
          "left": 1533
        }
      ]
    },
    {
      "id": "envelope",
      "name": "Envelope",
      "controls": [
        {
          "id": "envelope-a",
          "name": "Attack",
          "type": "knob",
          "class": "knob-1",
          "top": 255,
          "left": 1755,
          "init": 30
        },
        {
          "id": "envelope-d",
          "name": "Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 406,
          "left": 1755,
          "init": 30
        },
        {
          "id": "envelope-r",
          "name": "Release",
          "type": "knob",
          "class": "knob-1",
          "top": 559,
          "left": 1755,
          "init": 30
        },
        {
          "id": "envelope-s",
          "name": "Sustain",
          "type": "slider",
          "class": "slider-1",
          "top": 430,
          "left": 1919,
          "init": 100
        }
      ],
      "sockets": [
        {
          "id": "envelope-trigin",
          "name": "Trigger In",
          "type": "input",
          "signal": "gate",
          "top": 79,
          "left": 1837
        },
        {
          "id": "envelope-posout",
          "name": "+ Env Out",
          "type": "output",
          "signal": "modulation",
          "top": 143,
          "left": 1753
        },
        {
          "id": "envelope-negout",
          "name": "- Env Out",
          "type": "output",
          "signal": "modulation",
          "top": 143,
          "left": 1921
        }
      ]
    },
    {
      "id": "output",
      "name": "Output",
      "controls": [
        {
          "id": "output-vol",
          "name": "Volume",
          "type": "knob",
          "class": "knob-1",
          "top": 255,
          "left": 2103,
          "init": 180
        },
        {
          "id": "output-vcamode",
          "name": "VCA Mode",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Env",
            "KB Rls",
            "Drone"
          ],
          "top": 381,
          "left": 2103,
          "init": 0
        }
      ],
      "sockets": [
        {
          "id": "output-vcaamtin",
          "name": "VCA Amt In",
          "type": "input",
          "signal": "modulation",
          "top": 79,
          "left": 2103
        },
        {
          "id": "output-vcain",
          "name": "VCA In",
          "type": "input",
          "signal": "audio",
          "top": 143,
          "left": 2061
        },
        {
          "id": "output-reverbin",
          "name": "Reverb In",
          "type": "input",
          "signal": "audio",
          "top": 143,
          "left": 2149
        }
      ]
    },
    {
      "id": "reverb",
      "name": "Spring Reverb",
      "controls": [
        {
          "id": "reverb-mix",
          "name": "Mix",
          "type": "knob",
          "class": "knob-1",
          "top": 559,
          "left": 2103,
          "init": 30
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "rear",
      "name": "Rear Panel",
      "controls": [
        
      ],
      "sockets": [
        {
          "id": "rear-mainout",
          "name": "Main Out",
          "type": "output",
          "signal": "audio",
          "top": 872,
          "left": 120
        },
        {
          "id": "rear-instin",
          "name": "Instrument In",
          "type": "input",
          "signal": "audio",
          "top": 872,
          "left": 220
        },
        {
          "id": "rear-erout",
          "name": "Eurorack Out",
          "type": "output",
          "signal": "audio",
          "top": 883,
          "left": 327
        },
        {
          "id": "rear-reverbout",
          "name": "Reverb Out",
          "type": "output",
          "signal": "audio",
          "top": 883,
          "left": 376
        },
        {
          "id": "rear-clockin",
          "name": "Clock In",
          "type": "input",
          "signal": "clock",
          "top": 883,
          "left": 467
        },
        {
          "id": "rear-onoffin",
          "name": "On/Off In",
          "type": "input",
          "signal": "gate",
          "top": 883,
          "left": 516
        },
        {
          "id": "rear-resetin",
          "name": "Reset In",
          "type": "input",
          "signal": "trigger",
          "top": 883,
          "left": 566
        },
        {
          "id": "rear-clockout",
          "name": "Clock",
          "type": "output",
          "signal": "clock",
          "top": 883,
          "left": 613
        }
      ]
    }
  ]
}
