export default {
  "name": "Patchbay",
  "id": "patchbay",
  "vendor": "",
  "width": 1090,
  "height": 145,
  "scale": 1,
  "images": {
    "panel": "patchbay-panel.png",
    "thumbnail": "patchbay-thumbnail.png",
    "selector": "patchbay-selector.png"
  },
  "notes": "This is a utility module to help you describe external sources and destinations in your patch. It does not effect allowed device numbers.",
  "link": "",
  "sections": [
    {
      "id": "patchbay",
      "name": "",
      "controls": [
        
      ],
      "sockets": [
        {
          "id": "socket-1",
          "name": "Socket 1",
          "type": "any",
          "signal": "unknown",
          "top": 42,
          "left": 157
        },
        {
          "id": "socket-2",
          "name": "Socket 2",
          "type": "any",
          "signal": "unknown",
          "top": 42,
          "left": 267
        },
        {
          "id": "socket-3",
          "name": "Socket 3",
          "type": "any",
          "signal": "unknown",
          "top": 42,
          "left": 377
        },
        {
          "id": "socket-4",
          "name": "Socket 4",
          "type": "any",
          "signal": "unknown",
          "top": 42,
          "left": 487
        },
        {
          "id": "socket-5",
          "name": "Socket 5",
          "type": "any",
          "signal": "unknown",
          "top": 41,
          "left": 597
        },
        {
          "id": "socket-6",
          "name": "Socket 6",
          "type": "any",
          "signal": "unknown",
          "top": 41,
          "left": 707
        },
        {
          "id": "socket-7",
          "name": "Socket 7",
          "type": "any",
          "signal": "unknown",
          "top": 41,
          "left": 817
        },
        {
          "id": "socket-8",
          "name": "Socket 8",
          "type": "any",
          "signal": "unknown",
          "top": 41,
          "left": 927
        }
      ],
      "labels": [
        {
          "id": "socket-1",
          "top": 105,
          "left": 157
        },
        {
          "id": "socket-2",
          "top": 105,
          "left": 267
        },
        {
          "id": "socket-3",
          "top": 105,
          "left": 377
        },
        {
          "id": "socket-4",
          "top": 105,
          "left": 487
        },
        {
          "id": "socket-5",
          "top": 105,
          "left": 597
        },
        {
          "id": "socket-6",
          "top": 105,
          "left": 707
        },
        {
          "id": "socket-7",
          "top": 105,
          "left": 817
        },
        {
          "id": "socket-8",
          "top": 105,
          "left": 927
        }
      ]
    }
  ]
}
