export default {
  "name": "S1 Mk2",
  "id": "s1-mk2",
  "vendor": "Cwejman",
  "width": 1727,
  "height": 1000,
  "scale": 1,
  "images": {
    "panel": "s1mk2-panel.png",
    "thumbnail": "s1mk2-thumbnail.png",
    "selector": "s1mk2-selector.png"
  },
  "notes": "The S1 Mk2 is a triple ocillator, dual filter semi-modular synthesizer featuring extensive CV control.",
  "link": "https://www.cwejman.net/s1mk2.htm",
  "sections": [
    {
      "id": "osc1",
      "name": "Osc 1",
      "controls": [
        {
          "id": "osc1-fm",
          "name": "FM Level",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 129,
          "init": 30
        },
        {
          "id": "osc1-range",
          "name": "Range",
          "type": "knob",
          "class": "knob-1",
          "range": [90, 270],
          "values": ['0', '+1', '+2', '+3', '+4', '+5', '+6'],
          "top": 98,
          "left": 269,
          "init": 180
        },
        {
          "id": "osc1-tune",
          "name": "Tune",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 391,
          "init": 180
        },
        {
          "id": "osc1-pw",
          "name": "Pulse Width",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 513,
          "init": 180
        },
        {
          "id": "osc1-wave",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-1",
          "range": [180, 360],
          "values": ['Sine', 'Triangle', 'Saw', 'Saw + Triangle', 'Pulse + Triangle', 'Saw + Pulse', 'Pulse'],
          "top": 98,
          "left": 643,
          "init": 180
        },
        {
          "id": "osc1-mode",
          "name": "Mode",
          "type": "switch",
          "class": "switch-1",
          "values": ['LF', 'Audio'],
          "top": 71,
          "left": 199,
          "init": 1
        },
      ],
      "sockets": [
        {
          "id": "osc1-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 681,
          "left": 870
        },
        {
          "id": "osc1-pwm-in",
          "name": "PWM",
          "type": "input",
          "signal": "modulation",
          "top": 681,
          "left": 923
        },
      ],
    },

    {
      "id": "osc2",
      "name": "Osc 2",
      "controls": [
        {
          "id": "osc2-fm",
          "name": "FM Level",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 129,
          "init": 30
        },
        {
          "id": "osc2-range",
          "name": "Range",
          "type": "knob",
          "class": "knob-1",
          "range": [90, 270],
          "values": ['0', '+1', '+2', '+3', '+4', '+5', '+6'],
          "top": 215,
          "left": 269,
          "init": 180
        },
        {
          "id": "osc2-tune",
          "name": "Tune",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 391,
          "init": 180
        },
        {
          "id": "osc2-pw",
          "name": "Pulse Width",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 513,
          "init": 180
        },
        {
          "id": "osc2-wave",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-1",
          "range": [180, 360],
          "values": ['Sine', 'Triangle', 'Saw', 'Saw + Triangle', 'Pulse + Triangle', 'Saw + Pulse', 'Pulse'],
          "top": 215,
          "left": 643,
          "init": 180
        },
        {
          "id": "osc2-sync",
          "name": "Sync",
          "type": "switch",
          "class": "switch-1",
          "values": ['Off', 'On'],
          "top": 155,
          "left": 199,
          "init": 0
        },
      ],
      "sockets": [
        {
          "id": "osc2-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 739,
          "left": 870
        },
        {
          "id": "osc2-pwm-in",
          "name": "PWM",
          "type": "input",
          "signal": "modulation",
          "top": 739,
          "left": 923
        },
      ],
    },

    {
      "id": "osc3",
      "name": "Osc 3",
      "controls": [
        {
          "id": "osc3-fm",
          "name": "FM Level",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 129,
          "init": 30
        },
        {
          "id": "osc3-range",
          "name": "Range",
          "type": "knob",
          "class": "knob-1",
          "range": [90, 270],
          "values": ['0', '+1', '+2', '+3', '+4', '+5', '+6'],
          "top": 333,
          "left": 269,
          "init": 180
        },
        {
          "id": "osc3-tune",
          "name": "Tune",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 391,
          "init": 180
        },
        {
          "id": "osc3-pw",
          "name": "Pulse Width",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 513,
          "init": 180
        },
        {
          "id": "osc3-wave",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-1",
          "range": [180, 360],
          "values": ['Sine', 'Triangle', 'Saw', 'Saw + Triangle', 'Pulse + Triangle', 'Saw + Pulse', 'Pulse'],
          "top": 333,
          "left": 643,
          "init": 180
        },
        {
          "id": "osc3-sync",
          "name": "Sync",
          "type": "switch",
          "class": "switch-1",
          "values": ['Off', 'On'],
          "top": 273,
          "left": 199,
          "init": 0
        },
      ],
      "sockets": [
        {
          "id": "osc3-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 870
        },
        {
          "id": "osc3-pwm-in",
          "name": "PWM",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 923
        },
      ],
    },

    {
      "id": "lfo",
      "name": "LFO",
      "controls": [
        {
          "id": "lfo-rate",
          "name": "Rate",
          "type": "knob",
          "class": "knob-1",
          "top": 460,
          "left": 530,
          "init": 180
        },
        {
          "id": "lfo-wave",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-1",
          "range": [180, 360],
          "values": ['Random', 'Random Slew 1', 'Random Slew 2', 'Triange', 'Ramp', 'Saw', 'Square'],
          "top": 460,
          "left": 643,
          "init": 180
        },
        {
          "id": "lfo-gate",
          "name": "Gate",
          "type": "switch",
          "class": "switch-4",
          "values": ['Gate 1', 'Ext', 'Gate 2'],
          "top": 752,
          "left": 628,
          "init": 1
        },
      ],
      "sockets": [
        {
          "id": "lfo-rate-in",
          "name": "Rate",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 518
        },
        {
          "id": "lfo-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 573
        },
        {
          "id": "lfo-gate-in",
          "name": "Ext Gate",
          "type": "input",
          "signal": "gate",
          "top": 796,
          "left": 628
        },
        {
          "id": "lfo-out",
          "name": "Output",
          "type": "output",
          "signal": "modulation",
          "top": 796,
          "left": 682
        },
      ],
    },

    {
      "id": "outputs",
      "name": "Outputs",
      "controls": [
      ],
      "sockets": [
        {
          "id": "outputs-noise-white",
          "name": "White Noise",
          "type": "output",
          "signal": "audio",
          "top": 681,
          "left": 750
        },
        {
          "id": "outputs-noise-red",
          "name": "Red Noise",
          "type": "output",
          "signal": "audio",
          "top": 738,
          "left": 750
        },
        {
          "id": "outputs-noise-low",
          "name": "Low Noise",
          "type": "output",
          "signal": "audio",
          "top": 796,
          "left": 750
        },
        {
          "id": "outputs-osc1",
          "name": "Osc 1",
          "type": "output",
          "signal": "audio",
          "top": 681,
          "left": 805
        },
        {
          "id": "outputs-osc2",
          "name": "Osc 2",
          "type": "output",
          "signal": "audio",
          "top": 738,
          "left": 805
        },
        {
          "id": "outputs-osc3",
          "name": "Osc 3",
          "type": "output",
          "signal": "audio",
          "top": 796,
          "left": 805
        },
      ],
    },

    {
      "id": "mixer",
      "name": "Mixer",
      "controls": [
        {
          "id": "mixer-level1",
          "name": "Level 1",
          "type": "knob",
          "class": "knob-1",
          "top": 592,
          "left": 538,
          "init": 30
        },
        {
          "id": "mixer-level2",
          "name": "Level 2",
          "type": "knob",
          "class": "knob-1",
          "top": 592,
          "left": 643,
          "init": 30
        },
      ],
      "sockets": [
        {
          "id": "mixer-in1",
          "name": "Ext Input 1",
          "type": "input",
          "signal": "unknown",
          "top": 681,
          "left": 506
        },
        {
          "id": "mixer-in2",
          "name": "Ext Input 2",
          "type": "input",
          "signal": "unknown",
          "top": 681,
          "left": 675 
        },
        {
          "id": "mixer-out1",
          "name": "+ Output",
          "type": "output",
          "signal": "unknown",
          "pass": ["mixer-in1", "mixer-in2"],
          "top": 681,
          "left": 560
        },
        {
          "id": "mixer-out2",
          "name": "- Output",
          "type": "output",
          "signal": "unknown",
          "pass": ["mixer-in1", "mixer-in2"],
          "top": 681,
          "left": 620
        },
      ],
    },

    {
      "id": "master",
      "name": "Master CV",
      "controls": [
        {
          "id": "master-modlevel",
          "name": "Modulation Level",
          "type": "knob",
          "class": "knob-1",
          "top": 460,
          "left": 74,
          "init": 30
        },
        {
          "id": "master-glide",
          "name": "Glide",
          "type": "knob",
          "class": "knob-1",
          "top": 460,
          "left": 184,
          "init": 30
        },
        {
          "id": "master-transpose",
          "name": "Range",
          "type": "knob",
          "class": "knob-1",
          "range": [90, 270],
          "values": ['-3', '-2', '-1', '0', '+1', '+2', '+3'],
          "top": 460,
          "left": 294,
          "init": 180
        },
        {
          "id": "master-finetune",
          "name": "Fine Tune",
          "type": "knob",
          "class": "knob-1",
          "top": 460,
          "left": 406,
          "init": 180
        },

        {
          "id": "master-osc1cv",
          "name": "Master CV Osc 1",
          "type": "switch",
          "class": "switch-1",
          "values": ["On", "Off"],
          "top": 571,
          "left": 54,
          "init": 0
        },
        {
          "id": "master-osc2cv",
          "name": "Master CV Osc 2",
          "type": "switch",
          "class": "switch-1",
          "values": ["On", "Off"],
          "top": 571,
          "left": 86,
          "init": 0
        },
        {
          "id": "master-osc3cv",
          "name": "Master CV Osc 3",
          "type": "switch",
          "class": "switch-1",
          "values": ["On", "Off"],
          "top": 571,
          "left": 119,
          "init": 0
        },

        {
          "id": "master-env1trig",
          "name": "Env 1 Trigg",
          "type": "switch",
          "class": "switch-2",
          "values": ["2", "Off", "1"],
          "top": 571,
          "left": 154,
          "init": 2
        },
        {
          "id": "master-env2gate",
          "name": "Env 2 Gate",
          "type": "switch",
          "class": "switch-1",
          "values": ["2", "1"],
          "top": 571,
          "left": 186,
          "init": 1
        },
        {
          "id": "master-env2trig",
          "name": "Env 2 Trigg",
          "type": "switch",
          "class": "switch-2",
          "values": ["2", "Off", "1"],
          "top": 571,
          "left": 219,
          "init": 2
        },

        {
          "id": "master-lpfcutoff-amnt",
          "name": "LPF Cutoff",
          "type": "knob",
          "class": "knob-1",
          "top": 592,
          "left": 294,
          "init": 180
        },
        {
          "id": "master-lpfcutoff-src",
          "name": "LPF Cutoff Source",
          "type": "switch",
          "class": "switch-4",
          "values": ["CV3 (Mod Wheel)", "Off", "CV4 (Aftertouch)"],
          "top": 681,
          "left": 294,
          "init": 1
        },
        {
          "id": "master-mmfcutoff",
          "name": "MMF Cutoff",
          "type": "knob",
          "class": "knob-1",
          "top": 592,
          "left": 406,
          "init": 180
        },
        {
          "id": "master-mmfcutoff-src",
          "name": "MMF Cutoff Source",
          "type": "switch",
          "class": "switch-4",
          "values": ["CV3 (Mod Wheel)", "Off", "CV4 (Aftertouch)"],
          "top": 681,
          "left": 406,
          "init": 1
        },
      ],
      "sockets": [
        {
          "id": "master-osc1-modin",
          "name": "Osc 1 Modulation",
          "type": "input",
          "signal": "modulation",
          "top": 661,
          "left": 62
        },
        {
          "id": "master-osc2-modin",
          "name": "Osc 2 Modulation",
          "type": "input",
          "signal": "modulation",
          "top": 661,
          "left": 111
        },
        {
          "id": "master-osc3-modin",
          "name": "Osc 3 Modulation",
          "type": "input",
          "signal": "modulation",
          "top": 661,
          "left": 161
        },
        {
          "id": "master-master-modin",
          "name": "Master Modulation",
          "type": "input",
          "signal": "modulation",
          "top": 661,
          "left": 212
        },

        {
          "id": "master-cv1-out",
          "name": "CV 1 Out",
          "type": "output",
          "signal": "modulation",
          "top": 729,
          "left": 62
        },
        {
          "id": "master-cv2-out",
          "name": "CV 2 Out",
          "type": "output",
          "signal": "modulation",
          "top": 729,
          "left": 111
        },
        {
          "id": "master-cv3-out",
          "name": "CV 3 Out",
          "type": "output",
          "signal": "modulation",
          "top": 729,
          "left": 161
        },
        {
          "id": "master-cv4-out",
          "name": "CV 4 Out",
          "type": "output",
          "signal": "modulation",
          "top": 729,
          "left": 212
        },

        {
          "id": "master-env1gate-in",
          "name": "Env 1 Gate",
          "type": "input",
          "signal": "gate",
          "top": 796,
          "left": 62
        },
        {
          "id": "master-env1trig-in",
          "name": "Env 1 Trigg",
          "type": "input",
          "signal": "trigger",
          "top": 796,
          "left": 111
        },
        {
          "id": "master-env2gate-in",
          "name": "Env 2 Gate",
          "type": "input",
          "signal": "gate",
          "top": 796,
          "left": 161
        },
        {
          "id": "master-env2trig-in",
          "name": "Env 2 Trigg",
          "type": "input",
          "signal": "trigger",
          "top": 796,
          "left": 212
        },
      ],
    },

    {
      "id": "ringmod",
      "name": "Ring Modulator",
      "controls": [
      ],
      "sockets": [
        {
          "id": "ringmod-in1",
          "name": "Ext Input 1",
          "type": "input",
          "signal": "audio",
          "top": 796,
          "left": 288
        },
        {
          "id": "ringmod-in2",
          "name": "Input 2",
          "type": "input",
          "signal": "audio",
          "top": 796,
          "left": 343
        },
        {
          "id": "ringmod-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 398
        },
        {
          "id": "ringmod-out",
          "name": "Output",
          "type": "output",
          "signal": "audio",
          "top": 796,
          "left": 453
        },
      ],
    },

    {
      "id": "lpfmixer",
      "name": "LPF Mixer",
      "controls": [
        {
          "id": "lpfmixer-osc1",
          "name": "Oscillator 1",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 778,
          "init": 180
        },
        {
          "id": "lpfmixer-osc2",
          "name": "Oscillator 2",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 778,
          "init": 30
        },
        {
          "id": "lpfmixer-osc3",
          "name": "Oscillator 3",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 778,
          "init": 30
        },
        {
          "id": "lpfmixer-rm",
          "name": "Ring Modulator",
          "type": "knob",
          "class": "knob-1",
          "top": 450,
          "left": 778,
          "init": 30
        },
        {
          "id": "lpfmixer-mmf",
          "name": "MMF",
          "type": "knob",
          "class": "knob-1",
          "top": 567,
          "left": 778,
          "init": 30
        }
      ],
      "sockets": [
      ],
    },

    {
      "id": "mmfmixer",
      "name": "MMF Mixer",
      "controls": [
        {
          "id": "mmfmixer-osc1",
          "name": "Oscillator 1",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 895,
          "init": 30
        },
        {
          "id": "mmfmixer-osc2",
          "name": "Oscillator 2",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 895,
          "init": 30
        },
        {
          "id": "mmfmixer-osc3",
          "name": "Oscillator 3",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 895,
          "init": 30
        },
        {
          "id": "mmfmixer-rm",
          "name": "Ring Modulator",
          "type": "knob",
          "class": "knob-1",
          "top": 450,
          "left": 895,
          "init": 30
        },
        {
          "id": "mmfmixer-noise",
          "name": "White Noise",
          "type": "knob",
          "class": "knob-1",
          "top": 567,
          "left": 895,
          "init": 30
        }
      ],
      "sockets": [
      ],
    },

    {
      "id": "lpf",
      "name": "LPF",
      "controls": [
        {
          "id": "lpf-cutoff",
          "name": "Cutoff",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 1037,
          "init": 330
        },
        {
          "id": "lpf-peak",
          "name": "Q-Peak",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 1037,
          "init": 30
        },
        {
          "id": "lpf-pole",
          "name": "Pole",
          "type": "switch",
          "class": "switch-2",
          "values": ["6 Pole LP", "BP", "4 Pole LP"],
          "top": 168,
          "left": 975,
          "init": 0
        },
        {
          "id": "lpf-env1",
          "name": "CM Env 1",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 1037,
          "init": 30
        },
        {
          "id": "lpf-env1-polarity",
          "name": "CM Env 1 Polarity",
          "type": "switch",
          "class": "switch-1",
          "values": ["Negative (Down)", "Positive (Up)"],
          "top": 332,
          "left": 1099,
          "init": 1
        },
        {
          "id": "lpf-env2",
          "name": "CM Env 2",
          "type": "knob",
          "class": "knob-1",
          "top": 450,
          "left": 1037,
          "init": 30
        },
        {
          "id": "lpf-env2-polarity",
          "name": "CM Env 2 Polarity",
          "type": "switch",
          "class": "switch-1",
          "values": ["Negative", "Positive"],
          "top": 449,
          "left": 1099,
          "init": 1
        },
        {
          "id": "lfp-osclfo",
          "name": "CM Osc 1 / LFO",
          "type": "knob",
          "class": "knob-1",
          "top": 567,
          "left": 1037,
          "init": 30
        },
        {
          "id": "lpf-osclfo-source",
          "name": "CM Osc / LFO Source",
          "type": "switch",
          "class": "switch-1",
          "values": ["LFO", "Osc 1"],
          "top": 566,
          "left": 1099,
          "init": 0
        },
      ],
      "sockets": [
        {
          "id": "lpf-in",
          "name": "Hi Z Input",
          "type": "input",
          "signal": "audio",
          "top": 681,
          "left": 1000
        },
        {
          "id": "lpf-out",
          "name": "Output",
          "type": "output",
          "signal": "audio",
          "top": 681,
          "left": 1075
        },
        {
          "id": "lpf-cutoff-in",
          "name": "Cutoff",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 987
        },
        {
          "id": "lpf-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1038
        },
        {
          "id": "lpf-peak-in",
          "name": "Q-Peak",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1088
        },
      ],
    },

    {
      "id": "mmf",
      "name": "MMF",
      "controls": [
        {
          "id": "mmf-cutoff",
          "name": "Cutoff",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 1202,
          "init": 30
        },
        {
          "id": "mmf-peak",
          "name": "Q-Peak",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 1202,
          "init": 30
        },
        {
          "id": "mmf-pole",
          "name": "Pole",
          "type": "switch",
          "class": "switch-2",
          "values": ["6", "4", "2"],
          "top": 168,
          "left": 1139,
          "init": 0
        },
        {
          "id": "mmf-mode",
          "name": "Mode",
          "type": "switch",
          "class": "switch-2",
          "values": ["BP", "HP", "LP"],
          "top": 168,
          "left": 1264,
          "init": 1
        },
        {
          "id": "mmf-env1",
          "name": "CM Env 1",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 1202,
          "init": 30
        },
        {
          "id": "mmf-env1-polarity",
          "name": "CM Env 1 Polarity",
          "type": "switch",
          "class": "switch-1",
          "values": ["Negative (Down)", "Positive (Up)"],
          "top": 332,
          "left": 1264,
          "init": 1
        },
        {
          "id": "mmf-env2",
          "name": "CM Env 2",
          "type": "knob",
          "class": "knob-1",
          "top": 450,
          "left": 1202,
          "init": 30
        },
        {
          "id": "mmf-env2-polarity",
          "name": "CM Env 2 Polarity",
          "type": "switch",
          "class": "switch-1",
          "values": ["Negative", "Positive"],
          "top": 449,
          "left": 1264,
          "init": 1
        },
        {
          "id": "mmf-osclfo",
          "name": "CM Osc 1 / LFO",
          "type": "knob",
          "class": "knob-1",
          "top": 567,
          "left": 1202,
          "init": 30
        },
        {
          "id": "mmf-osclfo-source",
          "name": "CM Osc / LFO Source",
          "type": "switch",
          "class": "switch-1",
          "values": ["LFO", "Osc 1"],
          "top": 566,
          "left": 1264,
          "init": 0
        },
      ],
      "sockets": [
        {
          "id": "mmf-in",
          "name": "Hi Z Input",
          "type": "input",
          "signal": "audio",
          "top": 681,
          "left": 1164
        },
        {
          "id": "mmf-out",
          "name": "Output",
          "type": "output",
          "signal": "audio",
          "top": 681,
          "left": 1239
        },
        {
          "id": "mmf-cutoff-in",
          "name": "Cutoff",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1152
        },
        {
          "id": "mmf-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1202
        },
        {
          "id": "mmf-peak-in",
          "name": "Q-Peak",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1252
        },
      ],
    },

    {
      "id": "env1",
      "name": "Env 1",
      "controls": [
        {
          "id": "env1-attack",
          "name": "Attack",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 1356,
          "init": 30
        },
        {
          "id": "env1-decay",
          "name": "Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 1356,
          "init": 30
        },
        {
          "id": "env1-sustain",
          "name": "Sustain",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 1356,
          "init": 330
        },
        {
          "id": "env1-release",
          "name": "Release",
          "type": "knob",
          "class": "knob-1",
          "top": 450,
          "left": 1356,
          "init": 30
        },
        {
          "id": "env1-decay",
          "name": "Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 567,
          "left": 1356,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "env1-out",
          "name": "Output",
          "type": "output",
          "signal": "modulation",
          "top": 681,
          "left": 1327
        },
        {
          "id": "env1-inv-out",
          "name": "Output",
          "type": "output",
          "signal": "modulation",
          "top": 681,
          "left": 1386
        },
        {
          "id": "env1-attack-in",
          "name": "Attack",
          "type": "input",
          "signal": "modulation",
          "top": 741,
          "left": 1309
        },
        {
          "id": "env1-sustain-in",
          "name": "Sustain",
          "type": "input",
          "signal": "modulation",
          "top": 741,
          "left": 1356
        },
        {
          "id": "env1-delay-in",
          "name": "Delay",
          "type": "input",
          "signal": "modulation",
          "top": 741,
          "left": 1404
        },
        {
          "id": "env1-decay-in",
          "name": "Decay",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1309
        },
        {
          "id": "env1-release-in",
          "name": "Release",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1356
        },
        {
          "id": "env1-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1404
        },
      ],
    },

    {
      "id": "env2",
      "name": "Env 2",
      "controls": [
        {
          "id": "env2-attack",
          "name": "Attack",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 1501,
          "init": 30
        },
        {
          "id": "env2-decay",
          "name": "Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 1501,
          "init": 30
        },
        {
          "id": "env2-sustain",
          "name": "Sustain",
          "type": "knob",
          "class": "knob-1",
          "top": 333,
          "left": 1501,
          "init": 330
        },
        {
          "id": "env2-release",
          "name": "Release",
          "type": "knob",
          "class": "knob-1",
          "top": 450,
          "left": 1501,
          "init": 30
        },
        {
          "id": "env2-decay",
          "name": "Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 567,
          "left": 1501,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "env2-out",
          "name": "Output",
          "type": "output",
          "signal": "modulation",
          "top": 681,
          "left": 1471
        },
        {
          "id": "env2-inv-out",
          "name": "Output",
          "type": "output",
          "signal": "modulation",
          "top": 681,
          "left": 1531
        },
        {
          "id": "env2-attack-in",
          "name": "Attack",
          "type": "input",
          "signal": "modulation",
          "top": 741,
          "left": 1454
        },
        {
          "id": "env2-sustain-in",
          "name": "Sustain",
          "type": "input",
          "signal": "modulation",
          "top": 741,
          "left": 1501
        },
        {
          "id": "env2-delay-in",
          "name": "Delay",
          "type": "input",
          "signal": "modulation",
          "top": 741,
          "left": 1549
        },
        {
          "id": "env2-decay-in",
          "name": "Decay",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1454
        },
        {
          "id": "env2-release-in",
          "name": "Release",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1501
        },
        {
          "id": "env2-level-in",
          "name": "Level",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1549
        },
      ],
    },

    {
      "id": "amp",
      "name": "Amp",
      "controls": [
        {
          "id": "amp-filtermix",
          "name": "Filter Mix",
          "type": "knob",
          "class": "knob-1",
          "top": 98,
          "left": 1641,
          "init": 30
        },
        {
          "id": "amp-overdrive",
          "name": "Overdrive",
          "type": "knob",
          "class": "knob-1",
          "top": 215,
          "left": 1641,
          "init": 30
        },
        {
          "id": "amp-output",
          "name": "Output Level",
          "type": "knob",
          "class": "knob-1",
          "top": 322,
          "left": 1641,
          "init": 180
        },
        {
          "id": "amp-envmix",
          "name": "Envelope Mix",
          "type": "knob",
          "class": "knob-1",
          "top": 437,
          "left": 1641,
          "init": 30
        },
        {
          "id": "amp-envmode",
          "name": "Envelope Mode",
          "type": "switch",
          "class": "switch-3",
          "values": ["Linear", "Log"],
          "top": 506,
          "left": 1641,
          "init": 0
        },
        {
          "id": "amp-velcv",
          "name": "Velocity CV",
          "type": "switch",
          "class": "switch-3",
          "values": ["Off", "On"],
          "top": 746,
          "left": 1641,
          "init": 0
        },
      ],
      "sockets": [
        {
          "id": "amp-ext-in",
          "name": "Ext Input",
          "type": "input",
          "signal": "audio",
          "top": 681,
          "left": 1609
        },
        {
          "id": "amp-out",
          "name": "Output",
          "type": "output",
          "signal": "audio",
          "top": 681,
          "left": 1674
        },
        {
          "id": "amp-vel-in",
          "name": "Ext Velocity",
          "type": "input",
          "signal": "modulation",
          "top": 796,
          "left": 1641
        },
      ],
    },

    {
      "id": "rear",
      "name": "Rear Panel",
      "controls": [
      ],
      "sockets": [
        {
          "id": "rear-cv-in",
          "name": "CV In",
          "type": "input",
          "signal": "modulation",
          "top": 914,
          "left": 62
        },
        {
          "id": "rear-gate-in",
          "name": "Gate In",
          "type": "input",
          "signal": "gate",
          "top": 914,
          "left": 102
        },
        {
          "id": "rear-trig-in",
          "name": "Trigg In",
          "type": "input",
          "signal": "trigger",
          "top": 956,
          "left": 62
        },
        {
          "id": "rear-strig-in",
          "name": "STrig In",
          "type": "input",
          "signal": "trigger",
          "top": 956,
          "left": 102
        },

        {
          "id": "rear-cv1-out",
          "name": "CV 1 Out",
          "type": "output",
          "signal": "modulation",
          "top": 914,
          "left": 142
        },
        {
          "id": "rear-cv2-out",
          "name": "CV 2 Out",
          "type": "output",
          "signal": "modulation",
          "top": 914,
          "left": 183
        },
        {
          "id": "rear-cvm-out",
          "name": "Master Out",
          "type": "output",
          "signal": "modulation",
          "top": 914,
          "left": 222
        },
        {
          "id": "rear-gate1-out",
          "name": "Gate 1 Out",
          "type": "output",
          "signal": "gate",
          "top": 914,
          "left": 263
        },
        {
          "id": "rear-gate2-out",
          "name": "Gate 2 Out",
          "type": "output",
          "signal": "gate",
          "top": 914,
          "left": 303
        },

        {
          "id": "rear-cv3-out",
          "name": "CV 3 Out",
          "type": "output",
          "signal": "modulation",
          "top": 956,
          "left": 142
        },
        {
          "id": "rear-cv4-out",
          "name": "CV 4 Out",
          "type": "output",
          "signal": "modulation",
          "top": 956,
          "left": 183
        },
        {
          "id": "rear-strig-out",
          "name": "STrig Out",
          "type": "output",
          "signal": "trigger",
          "top": 956,
          "left": 222
        },
        {
          "id": "rear-trig1-out",
          "name": "Trigg 1 Out",
          "type": "output",
          "signal": "trigger",
          "top": 956,
          "left": 263
        },
        {
          "id": "rear-trig2-out",
          "name": "Trigg 2 Out",
          "type": "output",
          "signal": "trigger",
          "top": 956,
          "left": 303
        },

      ],
    },

  ]
}
