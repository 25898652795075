import angular from 'angular';

import userService from './user.service';
import uiSettingsController from './ui-settings.controller';
import registerController from './register.controller';
import registerDirective from './register.directive';
import signinController from './signin.controller';
import signinDirective from './signin.directive';
import forgotController from './forgot.controller';
import forgotDirective from './forgot.directive';
import resetController from './reset.controller';
import resetDirective from './reset.directive';
import profileController from './profile.controller';
import profileDirective from './profile.directive';
import plansDirective from './plans.directive';
import paymentDirective from './payment.directive';
import upgradeMessageDirective from './upgrade-message.directive';

const module = angular.module('userModule', [])
  .service('UserService', userService)
  .controller('RegisterController', registerController)
  .controller('UiSettingsController', uiSettingsController)
  .controller('SigninController', signinController)
  .controller('ForgotController', forgotController)
  .controller('ResetController', resetController)
  .controller('ProfileController', profileController)
  .directive('registerModal', registerDirective)
  .directive('signinModal', signinDirective)
  .directive('fogotModal', forgotDirective)
  .directive('resetModal', resetDirective)
  .directive('profileModal', profileDirective)
  .directive('selectPlan', plansDirective)
  .directive('paymentForm', paymentDirective)
  .directive('upgradeMessage', upgradeMessageDirective);

export default module.name;
