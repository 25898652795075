export default {
  "name": "Ø Coast",
  "id": "Ø-coast",
  "vendor": "Make Noise",
  "width": 922,
  "height": 547,
  "scale": 1,
  "images": {
    "panel": "ocoast-panel.png",
    "thumbnail": "ocoast-thumbnail.png",
    "selector": "ocoast-selector.png"
  },
  "notes": "The Ø Coast is a single oscillator semi-modular synthesizer utilizing synthesis techniques from both so-called “west“ and “East“ coast styles.",
  "link": "http://www.makenoisemusic.com/synthesizers/ohcoast",
  "sections": [
    {
      "id": "ctrl",
      "name": "Ctrl",
      "controls": [
        {
          "id": "ctrl-pgma",
          "name": "Pgm A",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Off",
            "On"
          ],
          "top": 170,
          "left": 62,
          "init": 0
        },
        {
          "id": "ctrl-pgmb",
          "name": "Pgm B",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Off",
            "On"
          ],
          "top": 78,
          "left": 194,
          "init": 0
        }
      ],
      "sockets": [
        {
          "id": "ctrl-midi",
          "name": "Midi In",
          "type": "input",
          "signal": "unknown",
          "top": 227,
          "left": 62
        },
        {
          "id": "ctrl-cv",
          "name": "CV",
          "type": "output",
          "signal": "pitch",
          "top": 472,
          "left": 41
        },
        {
          "id": "ctrl-gate",
          "name": "Gate",
          "type": "output",
          "signal": "gate",
          "top": 472,
          "left": 93
        },
        {
          "id": "ctrl-tempo",
          "name": "Tempo",
          "type": "input",
          "signal": "trigger",
          "top": 137,
          "left": 195
        },
        {
          "id": "ctrl-clock",
          "name": "Clk",
          "type": "output",
          "signal": "clock",
          "top": 193,
          "left": 195
        },
        {
          "id": "ctrl-random",
          "name": "Random",
          "type": "output",
          "signal": "modulation",
          "top": 249,
          "left": 195
        }
      ]
    },
    {
      "id": "math",
      "name": "Voltage Math",
      "controls": [
        {
          "id": "math-atten",
          "name": "Attenuverter",
          "type": "knob",
          "class": "knob-2",
          "top": 382,
          "left": 194,
          "init": 180
        }
      ],
      "sockets": [
        {
          "id": "math-in1",
          "name": "Input 1",
          "type": "input",
          "signal": "unknown",
          "top": 308,
          "left": 142
        },
        {
          "id": "math-in2",
          "name": "Input 2",
          "type": "input",
          "signal": "unknown",
          "top": 308,
          "left": 195
        },
        {
          "id": "math-out1",
          "name": "Output 1",
          "type": "output",
          "signal": "unknown",
          "pass": [
            "math-in1",
            "math-in2"
          ],
          "top": 472,
          "left": 166
        },
        {
          "id": "math-out2",
          "name": "Output 2",
          "type": "output",
          "signal": "unknown",
          "pass": [
            "math-in1",
            "math-in2"
          ],
          "top": 472,
          "left": 220
        }
      ]
    },
    {
      "id": "osc",
      "name": "Oscillator",
      "controls": [
        {
          "id": "osc-pitch",
          "name": "Pitch",
          "type": "knob",
          "class": "knob-1",
          "top": 177,
          "left": 323,
          "init": 180
        },
        {
          "id": "osc-fine",
          "name": "Fine",
          "type": "knob",
          "class": "knob-3",
          "top": 299,
          "left": 323,
          "init": 180
        },
        {
          "id": "osc-fm",
          "name": "Lin FM",
          "type": "knob",
          "class": "knob-2",
          "top": 383,
          "left": 323,
          "init": 180
        },
        {
          "id": "osc-tone",
          "name": "Overtone",
          "type": "knob",
          "class": "knob-2",
          "top": 127,
          "left": 467,
          "init": 30
        },
        {
          "id": "osc-tone-cv",
          "name": "Overtone CV",
          "type": "knob",
          "class": "knob-2",
          "top": 206,
          "left": 467,
          "init": 30
        },
        {
          "id": "osc-mult",
          "name": "Multiply",
          "type": "knob",
          "class": "knob-2",
          "top": 293,
          "left": 467,
          "init": 180
        },
        {
          "id": "osc-mult-cv",
          "name": "Multiply CV",
          "type": "knob",
          "class": "knob-2",
          "top": 376,
          "left": 467,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "osc-tri",
          "name": "Triangle Out",
          "type": "output",
          "signal": "audio",
          "top": 77,
          "left": 294
        },
        {
          "id": "osc-square",
          "name": "Square Out",
          "type": "output",
          "signal": "audio",
          "top": 77,
          "left": 351
        },
        {
          "id": "osc-voct",
          "name": "1v\/Oct",
          "type": "input",
          "signal": "pitch",
          "top": 472,
          "left": 287
        },
        {
          "id": "osc-fm-in",
          "name": "Lin FM",
          "type": "input",
          "signal": "modulation",
          "top": 472,
          "left": 341
        },
        {
          "id": "osc-overtone-in",
          "name": "Overtone",
          "type": "input",
          "signal": "modulation",
          "top": 472,
          "left": 404
        },
        {
          "id": "osc-multiply-in",
          "name": "Multiply",
          "type": "input",
          "signal": "modulation",
          "top": 472,
          "left": 466
        }
      ]
    },
    {
      "id": "slope",
      "name": "Slope",
      "controls": [
        {
          "id": "slope-cycle",
          "name": "Cycle",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Off",
            "On"
          ],
          "top": 78,
          "left": 586,
          "init": 0
        },
        {
          "id": "slope-rise",
          "name": "Rise",
          "type": "knob",
          "class": "knob-2",
          "top": 154,
          "left": 587,
          "init": 30
        },
        {
          "id": "slope-fall",
          "name": "Fall",
          "type": "knob",
          "class": "knob-2",
          "top": 250,
          "left": 587,
          "init": 30
        },
        {
          "id": "slope-vari",
          "name": "Vari-Response",
          "type": "knob",
          "class": "knob-2",
          "top": 342,
          "left": 587,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "slope-time",
          "name": "Time",
          "type": "input",
          "signal": "modulation",
          "top": 419,
          "left": 536
        },
        {
          "id": "slope-eoc",
          "name": "EOC",
          "type": "output",
          "signal": "trigger",
          "top": 419,
          "left": 588
        },
        {
          "id": "slope-trig",
          "name": "Trig",
          "type": "input",
          "signal": "trigger",
          "top": 473,
          "left": 536
        },
        {
          "id": "slope-out",
          "name": "Slope Output",
          "type": "output",
          "signal": "modulation",
          "top": 473,
          "left": 588
        }
      ]
    },
    {
      "id": "contour",
      "name": "Contour",
      "controls": [
        {
          "id": "contour-onset",
          "name": "Onset",
          "type": "knob",
          "class": "knob-2",
          "top": 101,
          "left": 707,
          "init": 30
        },
        {
          "id": "contour-sustain",
          "name": "Onset",
          "type": "knob",
          "class": "knob-2",
          "top": 194,
          "left": 707,
          "init": 330
        },
        {
          "id": "contour-decay",
          "name": "Decay",
          "type": "knob",
          "class": "knob-2",
          "top": 288,
          "left": 707,
          "init": 30
        },
        {
          "id": "contour-vari",
          "name": "Vari-Response",
          "type": "knob",
          "class": "knob-2",
          "top": 382,
          "left": 707,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "contour-decay-in",
          "name": "Decay",
          "type": "input",
          "signal": "modulation",
          "top": 419,
          "left": 655
        },
        {
          "id": "contour-gate-in",
          "name": "Gate",
          "type": "input",
          "signal": "gate",
          "top": 473,
          "left": 655
        },
        {
          "id": "contour-eon-out",
          "name": "EON",
          "type": "output",
          "signal": "trigger",
          "top": 473,
          "left": 708
        },
        {
          "id": "contour-out",
          "name": "Contour Out",
          "type": "output",
          "signal": "modulation",
          "top": 473,
          "left": 760
        }
      ]
    },
    {
      "id": "balance",
      "name": "Balance",
      "controls": [
        {
          "id": "balance-atten",
          "name": "Balance",
          "type": "knob",
          "class": "knob-2",
          "top": 154,
          "left": 827,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "balance-ext",
          "name": "External Input",
          "type": "input",
          "signal": "audio",
          "top": 78,
          "left": 783
        },
        {
          "id": "balance-cv",
          "name": "Balance CV",
          "type": "input",
          "signal": "modulation",
          "top": 78,
          "left": 882
        }
      ]
    },
    {
      "id": "dynamics",
      "name": "Dynamics",
      "controls": [
        {
          "id": "dynamics-atten",
          "name": "Dynamics",
          "type": "knob",
          "class": "knob-2",
          "top": 289,
          "left": 827,
          "init": 180
        },
        {
          "id": "dynamics-level",
          "name": "Line Out Level",
          "type": "knob",
          "class": "knob-3",
          "top": 383,
          "left": 828,
          "init": 180
        }
      ],
      "sockets": [
        {
          "id": "dynamics-cv",
          "name": "Dynamics CV",
          "type": "input",
          "signal": "modulation",
          "top": 473,
          "left": 822
        },
        {
          "id": "dynamics-out",
          "name": "Output",
          "type": "output",
          "signal": "audio",
          "top": 473,
          "left": 873
        },
        {
          "id": "dynamics-line",
          "name": "Line Out",
          "type": "output",
          "signal": "audio",
          "top": 419,
          "left": 879
        }
      ]
    }
  ]
}

