import {throttle} from 'underscore';

export default ['AppSettings', 'UserService', 'PatchService',
  function(AppSettings, UserService, PatchService){
    return {
      restrict: 'E',
      templateUrl: 'patch-cables.html',
      replace: true,
      controller: ['$scope', '$element', function($scope, $element) {
        
        const dpiScale = AppSettings.dpiScale;
        const canvas = $element[0];
        const ctx = canvas.getContext('2d');

        $scope.drawCable = function(cable, index){
          const userSettings = UserService.getSettings();
          const cableType = userSettings.cableType ? userSettings.cableType : AppSettings.userSettings.cableType;
          const labels = {};
          let labelIndex = 1;

          const src = document.querySelector(`[data-slug="${cable.src}"]`);
          const srcDevice = document.querySelector(`[data-slug="${src.getAttribute('data-device')}"]`);
          let srcX = src.offsetLeft + srcDevice.offsetLeft;
          let srcY = src.offsetTop + srcDevice.offsetTop;
          
          let dst, dstDevice, dstX, dstY, color;
          if(cable.dst) {
            dst = document.querySelector(`[data-slug="${cable.dst}"]`);
            dstDevice = document.querySelector(`[data-slug="${dst.getAttribute('data-device')}"]`);
            dstX = dst.offsetLeft + dstDevice.offsetLeft;
            dstY = dst.offsetTop + dstDevice.offsetTop;
            color = PatchService.getCableColor(cable);
          }
          else if(cableType == 'cables') {
            // Temp cable
            const offset = canvas.getBoundingClientRect();
            const scale = parseFloat(document.querySelector('.patch-devices__inner').getAttribute('data-scale'));
            dstX = ($scope.mouseX - offset.left) / scale;
            dstY = ($scope.mouseY - offset.top) / scale;
            color = '#aaa';
          }
          else {
            return;
          }

          srcX *= dpiScale;
          srcY *= dpiScale;
          dstX *= dpiScale;
          dstY *= dpiScale;

          switch(cableType) {
            case 'cables':
              let centerX = 0;
              let centerY = 0;
              let width = AppSettings.cableWidth;

              // Calculate midpoint & droop
              const diff = Math.abs(srcX - dstX) / 2;

              if(srcX < dstX) {
                centerX = srcX + diff;
              }
              else {
                centerX = dstX + diff;
              }
              
              if(diff > 10) {
                // Sockets at different x positions, add vertical droop
                centerY = Math.max(srcY, dstY) + (AppSettings.cableDroop);
                centerY+= diff / 8; // Longer connections get a bit more droop
              }
              else {
                // Sockets at same x positions, add horizontal droop & less vertical droop
                centerX+= (index % 2 ? 30 : -30);
                centerY = Math.max(srcY, dstY) + (AppSettings.cableDroop / 3);
              }

              // Apply scale
              //width *= dpiScale;
              //centerX *= dpiScale;
              //centerY *= dpiScale;

              // Draw stroke
              ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
              ctx.shadowBlur = 5;
              ctx.shadowOffsetX = 3;
              ctx.shadowOffsetY = 3;
              ctx.lineWidth = width;
              ctx.lineCap = 'round';
              ctx.strokeStyle = color;
              ctx.beginPath();
              ctx.moveTo(srcX, srcY);
              ctx.quadraticCurveTo(centerX, centerY, dstX, dstY);
              ctx.stroke();

              // Draw ends
              /*
              let radius = width;
              ctx.beginPath();
              ctx.lineWidth = 0;
              ctx.fillStyle = color;
              ctx.arc(srcX, srcY, radius, 0, 2 * Math.PI);
              ctx.fill();
              ctx.arc(dstX, dstY, radius, 0, 2 * Math.PI);
              ctx.fill();
              */
              break;

            case 'numbered':
              let radius;
              if(userSettings.deviceSize === 'proportional') {
                radius = src.offsetWidth * 0.8;
              }
              else {
                radius = 30;
              }
              let textSize = radius * 0.9;

              if(typeof(labels[cable.group]) === 'undefined') {
                labels[cable.group] = labelIndex;
                labelIndex += 1;
              }

              let label = labels[cable.group];

              ctx.beginPath();
              ctx.lineWidth = '4';
              ctx.fillStyle = color;
              ctx.strokeStyle = 'black';
              ctx.arc(srcX, srcY, radius, 0, 2 * Math.PI);
              ctx.fill();
              ctx.stroke();

              ctx.beginPath();
              ctx.arc(dstX, dstY, radius, 0, 2 * Math.PI);
              ctx.fill();
              ctx.stroke();

              ctx.beginPath();
              ctx.fillStyle = 'black';
              ctx.font = `bold ${textSize}pt sans-serif`;
              ctx.fillText(
                label, 
                srcX - (ctx.measureText(label).width / 2), 
                srcY + (radius - (textSize * 0.7))
              );

              ctx.beginPath();
              ctx.fillStyle = 'black';
              ctx.font = `bold ${textSize}pt sans-serif`;
              ctx.fillText(
                label, 
                dstX - (ctx.measureText(label).width / 2), 
                dstY + (radius - (textSize * 0.7))
              );
              break;
          }
        }

        $scope.drawCables = function(){
          canvas.width = canvas.width;

          let index = 0;
          $scope.patch.cables.map(function(cable){
            $scope.drawCable(cable, index);
            index++;
          });

          // Draw the temp cable
          if($scope.tempCable) {
            $scope.drawCable($scope.tempCable, index + 1);
          }
        }

        $scope.$on('draw-cables', function(){
          $scope.drawCables();
        });

        $scope.drawPatching = throttle(function(event){
          $scope.mouseX = event.clientX;
          $scope.mouseY = event.clientY;
          $scope.drawCables();
        }, 100);

        $scope.$on('patching', function($event, data){
          if(data) {
            // Create temp cable object
            $scope.tempCable = {
              src: data.slug,
              dst: null,
              group: 0,
              index: 0,
              signal: data.config.signal,
            };
            window.addEventListener('mousemove', $scope.drawPatching);
          }
          else {
            $scope.tempCable = null;
            $scope.drawCables();
            window.removeEventListener('mousemove', $scope.drawPatching);
          }
        });
      }]
    };
  }
]
