export default {
  "name": "Subharmonicon",
  "id": "subharmonicon",
  "vendor": "Moog Music",
  "width": 1273,
  "height": 544,
  "scale": 1,
  "images": {
    "panel": "subharmonicon-panel.png",
    "thumbnail": "subharmonicon-thumbnail.png",
    "selector": "subharmonicon-selector.png"
  },
  "notes": "",
  "sections": [
    {
      "id": "vco1",
      "name": "VCO 1",
      "controls": [
        {
          "id": "vco1-wave",
          "name": "VCO 1 Wave",
          "type": "switch",
          "class": "switch-1",
          "values": ["Saw", "Shape", "Square"],
          "top": 117,
          "left": 380,
          "init": 0
        },
        {
          "id": "vco1-freq",
          "name": "VCO 1 Freq",
          "type": "knob",
          "class": "knob-1",
          "top": 117,
          "left": 457,
          "init": 180
        },
        {
          "id": "vco1-sub1-freq",
          "name": "Sub 1 Freq",
          "type": "knob",
          "class": "knob-2",
          "top": 222,
          "left": 400,
          "init": 180
        },
        {
          "id": "vco1-sub2-freq",
          "name": "Sub 2 Freq",
          "type": "knob",
          "class": "knob-2",
          "top": 222,
          "left": 513,
          "init": 180
        },
        {
          "id": "vco1-level",
          "name": "VCO 1 Level",
          "type": "knob",
          "class": "knob-2",
          "top": 363,
          "left": 457,
          "init": 180
        },
        {
          "id": "vco1-sub1-level",
          "name": "Sub 1 Level",
          "type": "knob",
          "class": "knob-2",
          "top": 456,
          "left": 400,
          "init": 30
        },
        {
          "id": "vco1-sub2-level",
          "name": "Sub 2 Level",
          "type": "knob",
          "class": "knob-2",
          "top": 456,
          "left": 513,
          "init": 30
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "seq1-assign",
      "name": "Seq 1 Assign",
      "controls": [
        {
          "id": "seq1-assign-osc1",
          "name": "Osc 1",
          "type": "switch",
          "class": "button-osc1",
          "values": ["Off", "On"],
          "top": 292,
          "left": 404,
          "init": 0
        },
        {
          "id": "seq1-assign-sub1",
          "name": "Sub 1",
          "type": "switch",
          "class": "button-sub1",
          "values": ["Off", "On"],
          "top": 292,
          "left": 457,
          "init": 0
        },
        {
          "id": "seq1-assign-sub2",
          "name": "Sub 2",
          "type": "switch",
          "class": "button-sub2",
          "values": ["Off", "On"],
          "top": 292,
          "left": 509,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "vco2",
      "name": "VCO 2",
      "controls": [
        {
          "id": "vco2-wave",
          "name": "VCO 2 Wave",
          "type": "switch",
          "class": "switch-1",
          "values": ["Saw", "Shape", "Square"],
          "top": 117,
          "left": 759,
          "init": 0
        },
        {
          "id": "vco2-freq",
          "name": "VCO 2 Freq",
          "type": "knob",
          "class": "knob-1",
          "top": 117,
          "left": 683,
          "init": 180
        },
        {
          "id": "vco2-sub1-freq",
          "name": "Sub 1 Freq",
          "type": "knob",
          "class": "knob-2",
          "top": 222,
          "left": 626,
          "init": 180
        },
        {
          "id": "vco2-sub2-freq",
          "name": "Sub 2 Freq",
          "type": "knob",
          "class": "knob-2",
          "top": 222,
          "left": 739,
          "init": 180
        },
        {
          "id": "vco2-level",
          "name": "VCO 2 Level",
          "type": "knob",
          "class": "knob-2",
          "top": 363,
          "left": 683,
          "init": 180
        },
        {
          "id": "vco1-sub1-level",
          "name": "Sub 1 Level",
          "type": "knob",
          "class": "knob-2",
          "top": 456,
          "left": 626,
          "init": 30
        },
        {
          "id": "vco1-sub2-level",
          "name": "Sub 2 Level",
          "type": "knob",
          "class": "knob-2",
          "top": 456,
          "left": 739,
          "init": 30
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "seq2-assign",
      "name": "Seq 2 Assign",
      "controls": [
        {
          "id": "seq2-assign-osc2",
          "name": "Osc 2",
          "type": "switch",
          "class": "button-osc2",
          "values": ["Off", "On"],
          "top": 292,
          "left": 629,
          "init": 0
        },
        {
          "id": "seq2-assign-sub1",
          "name": "Sub 1",
          "type": "switch",
          "class": "button-sub1",
          "values": ["Off", "On"],
          "top": 292,
          "left": 682,
          "init": 0
        },
        {
          "id": "seq2-assign-sub2",
          "name": "Sub 2",
          "type": "switch",
          "class": "button-sub2",
          "values": ["Off", "On"],
          "top": 292,
          "left": 734,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "common",
      "name": "Common",
      "controls": [
        {
          "id": "tempo",
          "name": "Tempo",
          "type": "knob",
          "class": "knob-1",
          "top": 456,
          "left": 114,
          "init": 180
        },
        {
          "id": "seq-oct",
          "name": "Seq Oct",
          "type": "switch",
          "class": "button-seq-oct",
          "values": ["5", "2", "1"],
          "top": 157,
          "left": 570,
          "init": 0
        },
        {
          "id": "quantize",
          "name": "Quantize",
          "type": "switch",
          "class": "button-quantize",
          "values": ["12-ET", "8-ET", "12-JI", "8-JI", "Off"],
          "top": 359,
          "left": 570,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "filter",
      "name": "Filter",
      "controls": [
        {
          "id": "vcf-cutoff",
          "name": "Cutoff",
          "type": "knob",
          "class": "knob-1",
          "top": 80,
          "left": 852,
          "init": 330
        },
        {
          "id": "vcf-resonance",
          "name": "Resonance",
          "type": "knob",
          "class": "knob-1",
          "top": 205,
          "left": 852,
          "init": 30
        },
        {
          "id": "vcf-egamt",
          "name": "VCF EG Amount",
          "type": "knob",
          "class": "knob-1",
          "top": 205,
          "left": 973,
          "init": 180
        },
        {
          "id": "vcf-attack",
          "name": "VCF Attack",
          "type": "knob",
          "class": "knob-1",
          "top": 330,
          "left": 852,
          "init": 30
        },
        {
          "id": "vcf-decay",
          "name": "VCF Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 330,
          "left": 973,
          "init": 180
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "vca",
      "name": "VCA",
      "controls": [
        {
          "id": "vca-volume",
          "name": "Volume",
          "type": "knob",
          "class": "knob-1",
          "top": 80,
          "left": 973,
          "init": 180
        },
        {
          "id": "vca-attack",
          "name": "VCA Attack",
          "type": "knob",
          "class": "knob-1",
          "top": 456,
          "left": 852,
          "init": 30
        },
        {
          "id": "vca-decay",
          "name": "VCA Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 456,
          "left": 973,
          "init": 180
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "seq1",
      "name": "Sequencer 1",
      "controls": [
        {
          "id": "seq1-step1",
          "name": "Step 1",
          "type": "knob",
          "class": "knob-3",
          "top": 80,
          "left": 75,
          "init": 30
        },
        {
          "id": "seq1-step2",
          "name": "Step 2",
          "type": "knob",
          "class": "knob-3",
          "top": 80,
          "left": 152,
          "init": 30
        },
        {
          "id": "seq1-step3",
          "name": "Step 3",
          "type": "knob",
          "class": "knob-3",
          "top": 80,
          "left": 229,
          "init": 30
        },
        {
          "id": "seq1-step4",
          "name": "Step 4",
          "type": "knob",
          "class": "knob-3",
          "top": 80,
          "left": 305,
          "init": 30
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "seq2",
      "name": "Sequencer 2",
      "controls": [
        {
          "id": "seq2-step1",
          "name": "Step 1",
          "type": "knob",
          "class": "knob-3",
          "top": 181,
          "left": 75,
          "init": 30
        },
        {
          "id": "seq2-step2",
          "name": "Step 2",
          "type": "knob",
          "class": "knob-3",
          "top": 181,
          "left": 152,
          "init": 30
        },
        {
          "id": "seq2-step3",
          "name": "Step 3",
          "type": "knob",
          "class": "knob-3",
          "top": 181,
          "left": 229,
          "init": 30
        },
        {
          "id": "seq2-step4",
          "name": "Step 4",
          "type": "knob",
          "class": "knob-3",
          "top": 181,
          "left": 305,
          "init": 30
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "poly",
      "name": "Polyrhythm",
      "controls": [
        {
          "id": "poly-rhythm1",
          "name": "Rhythm 1",
          "type": "knob",
          "class": "knob-3",
          "top": 283,
          "left": 75,
          "init": 30
        },
        {
          "id": "poly-rhythm1-seq1",
          "name": "Rhythm 1 Seq 1",
          "type": "switch",
          "class": "button-seq1",
          "values": ["Off", "On"],
          "top": 333,
          "left": 75,
          "init": 0
        },
        {
          "id": "poly-rhythm1-seq2",
          "name": "Rhythm 1 Seq 2",
          "type": "switch",
          "class": "button-seq2",
          "values": ["Off", "On"],
          "top": 370,
          "left": 75,
          "init": 0
        },
        {
          "id": "poly-rhythm2",
          "name": "Rhythm 2",
          "type": "knob",
          "class": "knob-3",
          "top": 283,
          "left": 152,
          "init": 30
        },
        {
          "id": "poly-rhythm2-seq1",
          "name": "Rhythm 2 Seq 1",
          "type": "switch",
          "class": "button-seq1",
          "values": ["Off", "On"],
          "top": 333,
          "left": 152,
          "init": 0
        },
        {
          "id": "poly-rhythm2-seq2",
          "name": "Rhythm 2 Seq 2",
          "type": "switch",
          "class": "button-seq2",
          "values": ["Off", "On"],
          "top": 370,
          "left": 152,
          "init": 0
        },
        {
          "id": "poly-rhythm3",
          "name": "Rhythm 3",
          "type": "knob",
          "class": "knob-3",
          "top": 283,
          "left": 229,
          "init": 30
        },
        {
          "id": "poly-rhythm3-seq1",
          "name": "Rhythm 3 Seq 1",
          "type": "switch",
          "class": "button-seq1",
          "values": ["Off", "On"],
          "top": 333,
          "left": 229,
          "init": 0
        },
        {
          "id": "poly-rhythm3-seq2",
          "name": "Rhythm 3 Seq 2",
          "type": "switch",
          "class": "button-seq2",
          "values": ["Off", "On"],
          "top": 370,
          "left": 229,
          "init": 0
        },
        {
          "id": "poly-rhythm4",
          "name": "Rhythm 4",
          "type": "knob",
          "class": "knob-3",
          "top": 283,
          "left": 305,
          "init": 30
        },
        {
          "id": "poly-rhythm4-seq1",
          "name": "Rhythm 4 Seq 1",
          "type": "switch",
          "class": "button-seq1",
          "values": ["Off", "On"],
          "top": 333,
          "left": 305,
          "init": 0
        },
        {
          "id": "poly-rhythm4-seq2",
          "name": "Rhythm 4 Seq 2",
          "type": "switch",
          "class": "button-seq2",
          "values": ["Off", "On"],
          "top": 370,
          "left": 305,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "patchbay",
      "name": "Patchbay",
      "controls": [],
      "sockets": [
        {
          "id": "patchbay-vco1-in",
          "name": "VCO 1 In",
          "type": "input",
          "signal": "pitch",
          "top": 81,
          "left": 1054
        },
        {
          "id": "patchbay-vco1sub-in",
          "name": "VCO 1 Sub",
          "type": "input",
          "signal": "pitch",
          "top": 81,
          "left": 1109
        },
        {
          "id": "patchbay-vco1pwm-in",
          "name": "VCO 1 PWM",
          "type": "input",
          "signal": "modulation",
          "top": 81,
          "left": 1164
        },
        {
          "id": "patchbay-vca-out",
          "name": "VCA Out",
          "type": "output",
          "signal": "modulation",
          "top": 81,
          "left": 1220
        },
        {
          "id": "patchbay-vco1-out",
          "name": "VCO 1 Out",
          "type": "output",
          "signal": "audio",
          "top": 136,
          "left": 1054
        },
        {
          "id": "patchbay-vco1sub1-out",
          "name": "VCO 1 Sub 1",
          "type": "output",
          "signal": "audio",
          "top": 136,
          "left": 1109
        },
        {
          "id": "patchbay-vco1sub2-out",
          "name": "VCO 1 Sub 2",
          "type": "output",
          "signal": "audio",
          "top": 136,
          "left": 1164
        },
        {
          "id": "patchbay-vca-in",
          "name": "VCA In",
          "type": "input",
          "signal": "modulation",
          "top": 136,
          "left": 1220
        },
        {
          "id": "patchbay-vco2-in",
          "name": "VCO 2 In",
          "type": "input",
          "signal": "pitch",
          "top": 191,
          "left": 1054
        },
        {
          "id": "patchbay-vco2sub-in",
          "name": "VCO 2 Sub",
          "type": "input",
          "signal": "pitch",
          "top": 191,
          "left": 1109
        },
        {
          "id": "patchbay-vco2pwm-in",
          "name": "VCO 2 PWM",
          "type": "input",
          "signal": "modulation",
          "top": 191,
          "left": 1164
        },
        {
          "id": "patchbay-vcaeg-out",
          "name": "VCA EG Out",
          "type": "output",
          "signal": "modulation",
          "top": 191,
          "left": 1220
        },
        {
          "id": "patchbay-vco2-out",
          "name": "VCO 2 Out",
          "type": "output",
          "signal": "audio",
          "top": 246,
          "left": 1054
        },
        {
          "id": "patchbay-vco2sub1-out",
          "name": "VCO 2 Sub 1",
          "type": "output",
          "signal": "audio",
          "top": 246,
          "left": 1109
        },
        {
          "id": "patchbay-vco2sub2-out",
          "name": "VCO 2 Sub 2",
          "type": "output",
          "signal": "audio",
          "top": 246,
          "left": 1164
        },
        {
          "id": "patchbay-cutoff-in",
          "name": "Cutoff",
          "type": "input",
          "signal": "modulation",
          "top": 246,
          "left": 1220
        },
        {
          "id": "patchbay-play-in",
          "name": "Play",
          "type": "input",
          "signal": "gate",
          "top": 301,
          "left": 1054
        },
        {
          "id": "patchbay-reset-in",
          "name": "Reset",
          "type": "input",
          "signal": "gate",
          "top": 301,
          "left": 1109
        },
        {
          "id": "patchbay-trigger-in",
          "name": "Trigger",
          "type": "input",
          "signal": "trigger",
          "top": 301,
          "left": 1164
        },
        {
          "id": "patchbay-vcfeg-out",
          "name": "VCF EG",
          "type": "output",
          "signal": "modulation",
          "top": 301,
          "left": 1220
        },
        {
          "id": "patchbay-rhythm1-in",
          "name": "Rhythm 1",
          "type": "input",
          "signal": "modulation",
          "top": 356,
          "left": 1054
        },
        {
          "id": "patchbay-rhythm2-in",
          "name": "Rhythm 2",
          "type": "input",
          "signal": "modulation",
          "top": 356,
          "left": 1109
        },
        {
          "id": "patchbay-rhythm3-in",
          "name": "Rhythm 3",
          "type": "input",
          "signal": "modulation",
          "top": 356,
          "left": 1164
        },
        {
          "id": "patchbay-rhythm4-in",
          "name": "Rhythm 4",
          "type": "input",
          "signal": "modulation",
          "top": 356,
          "left": 1220
        },
        {
          "id": "patchbay-seq1-out",
          "name": "Seq 1",
          "type": "output",
          "signal": "pitch",
          "top": 410,
          "left": 1054
        },
        {
          "id": "patchbay-seq1clk-out",
          "name": "Seq 1 Clk",
          "type": "output",
          "signal": "clock",
          "top": 410,
          "left": 1109
        },
        {
          "id": "patchbay-seq2-out",
          "name": "Seq 2",
          "type": "output",
          "signal": "pitch",
          "top": 410,
          "left": 1164
        },
        {
          "id": "patchbay-seq2clk-out",
          "name": "Seq 2 Clk",
          "type": "output",
          "signal": "clock",
          "top": 410,
          "left": 1220
        },
        {
          "id": "patchbay-midi-in",
          "name": "MIDI In",
          "type": "input",
          "signal": "pitch",
          "top": 465,
          "left": 1054
        },
        {
          "id": "patchbay-clock-in",
          "name": "Clock In",
          "type": "input",
          "signal": "clock",
          "top": 465,
          "left": 1109
        },
        {
          "id": "patchbay-clock-out",
          "name": "Clock Out",
          "type": "output",
          "signal": "clock",
          "top": 465,
          "left": 1164
        },
        {
          "id": "patchbay-trigger-out",
          "name": "Trigger Out",
          "type": "output",
          "signal": "trigger",
          "top": 465,
          "left": 1220
        },
      ],
    }
  ]
}
