import angular from 'angular';

import switchDirective from './switch.directive';
import knobDirective from './knob.directive'
import sliderDirective from './slider.directive';
import socketDirective from './socket.directive';

const module = angular.module('controlModule', [])
  .directive('switch', switchDirective)
  .directive('knob', knobDirective)
  .directive('slider', sliderDirective)
  .directive('socket', socketDirective);

export default module.name;
