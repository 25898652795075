export default {
  "name": "Model D",
  "id": "model-d",
  "vendor": "Moog Music",
  "width": 2750,
  "height": 1008,
  "scale": 0.9,
  "images": {
    "panel": "modeld-panel.png",
    "thumbnail": "modeld-thumbnail.png",
    "selector": "modeld-selector.png"
  },
  "notes": "",
  "link": "https://www.moogmusic.com/products/minimoog-model-d",
  "sections": [
    {
      "id": "controllers",
      "name": "Controllers",
      "controls": [
        {
          "id": "controllers-tune",
          "name": "Tune",
          "type": "knob",
          "class": "knob-2",
          "top": 274,
          "left": 183,
          "init": 180
        },
        {
          "id": "controllers-glide-rate",
          "name": "Glide Rate",
          "type": "knob",
          "class": "knob-2",
          "top": 457,
          "left": 92,
          "init": 30
        },
        {
          "id": "controllers-mod-mix",
          "name": "Modulation Mix",
          "type": "knob",
          "class": "knob-2",
          "top": 457,
          "left": 274,
          "init": 30
        },
        {
          "id": "controllers-mod-1",
          "name": "Modulation 1 Source",
          "type": "switch",
          "class": "switch-1",
          "values": ["Osc. 3", "Filter EG"],
          "top": 575,
          "left": 92,
          "init": 0
        },
        {
          "id": "controllers-mod-2",
          "name": "Modulation 2 Source",
          "type": "switch",
          "class": "switch-1",
          "values": ["Noise", "LFO"],
          "top": 575,
          "left": 274,
          "init": 0
        },

        {
          "id": "controllers-lfo-rate",
          "name": "LFO Rate",
          "type": "knob",
          "class": "knob-2",
          "top": 872,
          "left": 108,
          "init": 30
        },
        {
          "id": "controllers-glide",
          "name": "Glide",
          "type": "switch",
          "class": "switch-3",
          "values": ["Off", "On"],
          "top": 814,
          "left": 275,
          "init": 0
        },
        {
          "id": "controllers-decay",
          "name": "Decay",
          "type": "switch",
          "class": "switch-3",
          "values": ["Off", "On"],
          "top": 931,
          "left": 275,
          "init": 0
        }
      ]
    },

    {
      "id": "oscbank",
      "name": "Oscillator Bank",
      "controls": [
        {
          "id": "oscbank-osc1-range",
          "name": "Oscillator 1 Range",
          "type": "knob",
          "class": "knob-3",
          "values": ["Lo", "32'", "16'", "8'", "4'", "2'"],
          "range": [105, 255],
          "top": 183,
          "left": 533,
          "init": 135
        },
        {
          "id": "oscbank-osc1-wave",
          "name": "Oscillator 1 Waveform",
          "type": "knob",
          "class": "knob-3",
          "values": ["Triangle", "Sharktooth", "Ramp", "Pulse Wide", "Pulse Mid", "Pulse Narrow"],
          "range": [105, 255],
          "top": 183,
          "left": 896,
          "init": 105
        },

        {
          "id": "oscbank-osc2-range",
          "name": "Oscillator 2 Range",
          "type": "knob",
          "class": "knob-3",
          "values": ["Lo", "32'", "16'", "8'", "4'", "2'"],
          "range": [105, 255],
          "top": 363,
          "left": 533,
          "init": 135
        },
        {
          "id": "oscbank-osc2-wave",
          "name": "Oscillator 2 Waveform",
          "type": "knob",
          "class": "knob-3",
          "values": ["Triangle", "Sharktooth", "Ramp", "Pulse Wide", "Pulse Mid", "Pulse Narrow"],
          "range": [105, 255],
          "top": 363,
          "left": 896,
          "init": 105
        },
        {
          "id": "oscbank-osc2-freq",
          "name": "Oscillator 2 Frequency",
          "type": "knob",
          "class": "knob-1",
          "range": [20, 340],
          "top": 363,
          "left": 715,
          "init": 180
        },

        {
          "id": "oscbank-osc3-range",
          "name": "Oscillator 3 Range",
          "type": "knob",
          "class": "knob-3",
          "values": ["Lo", "32'", "16'", "8'", "4'", "2'"],
          "range": [105, 255],
          "top": 546,
          "left": 533,
          "init": 135
        },
        {
          "id": "oscbank-osc3-wave",
          "name": "Oscillator 3 Waveform",
          "type": "knob",
          "class": "knob-3",
          "values": ["Triangle", "Saw", "Ramp", "Pulse Wide", "Pulse Mid", "Pulse Narrow"],
          "range": [105, 255],
          "top": 546,
          "left": 896,
          "init": 105
        },
        {
          "id": "oscbank-osc3-freq",
          "name": "Oscillator 2 Frequency",
          "type": "knob",
          "class": "knob-1",
          "range": [20, 340],
          "top": 546,
          "left": 715,
          "init": 180
        },

        {
          "id": "oscbank-osc-mod",
          "name": "Oscillator Modulation",
          "type": "switch",
          "class": "switch-1",
          "values": ["Off", "On"],
          "top": 274,
          "left": 377,
          "init": 0
        },
        {
          "id": "oscbank-osc3-cont",
          "name": "Osc. 3 Control",
          "type": "switch",
          "class": "switch-2",
          "values": ["Off", "On"],
          "top": 550,
          "left": 414,
          "init": 1
        }
      ]
    },
    
    {
      "id": "mixer",
      "name": "Mixer",
      "controls": [
        {
          "id": "mixer-osc1-volume",
          "name": "Oscillator 1 Volume",
          "type": "knob",
          "class": "knob-2",
          "top": 184,
          "left": 1078,
          "init": 180
        },
        {
          "id": "mixer-osc2-volume",
          "name": "Oscillator 2 Volume",
          "type": "knob",
          "class": "knob-2",
          "top": 364,
          "left": 1078,
          "init": 30
        },
        {
          "id": "mixer-osc3-volume",
          "name": "Oscillator 3 Volume",
          "type": "knob",
          "class": "knob-2",
          "top": 547,
          "left": 1078,
          "init": 30
        },
        {
          "id": "mixer-ext-volume",
          "name": "External Input Volume",
          "type": "knob",
          "class": "knob-2",
          "top": 275,
          "left": 1441,
          "init": 30
        },
        {
          "id": "mixer-noise-volume",
          "name": "External Input Volume",
          "type": "knob",
          "class": "knob-2",
          "top": 456,
          "left": 1441,
          "init": 30
        },

        {
          "id": "mixer-osc1",
          "name": "Oscillator 1",
          "type": "switch",
          "class": "switch-4",
          "values": ["Off", "On"],
          "top": 180,
          "left": 1259,
          "init": 1
        },
        {
          "id": "mixer-ext",
          "name": "External Input",
          "type": "switch",
          "class": "switch-4",
          "values": ["Off", "On"],
          "top": 274,
          "left": 1259,
          "init": 0
        },
        {
          "id": "mixer-osc2",
          "name": "Oscillator 2",
          "type": "switch",
          "class": "switch-4",
          "values": ["Off", "On"],
          "top": 363,
          "left": 1256,
          "init": 0
        },
        {
          "id": "mixer-noise",
          "name": "External Input",
          "type": "switch",
          "class": "switch-4",
          "values": ["Off", "On"],
          "top": 456,
          "left": 1259,
          "init": 0
        },
        {
          "id": "mixer-osc3",
          "name": "Oscillator 3",
          "type": "switch",
          "class": "switch-4",
          "values": ["Off", "On"],
          "top": 545,
          "left": 1259,
          "init": 0
        },
        {
          "id": "mixer-noise-color",
          "name": "Noise Color",
          "type": "switch",
          "class": "switch-5",
          "values": ["Pink", "White"],
          "top": 456,
          "left": 1571,
          "init": 0
        },
        
      ]
    },

    {
      "id": "filter",
      "name": "Filter",
      "controls": [
        {
          "id": "filter-cutoff",
          "name": "Cutoff Frequency",
          "type": "knob",
          "class": "knob-2",
          "top": 184,
          "left": 1855,
          "init": 330
        },
        {
          "id": "filter-emphasis",
          "name": "Emphasis",
          "type": "knob",
          "class": "knob-2",
          "top": 184,
          "left": 2037,
          "init": 30
        },
        {
          "id": "filter-contour",
          "name": "Amount of Contour",
          "type": "knob",
          "class": "knob-2",
          "top": 184,
          "left": 2218,
          "init": 30
        },
        {
          "id": "filter-attack",
          "name": "Attack Time",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 1855,
          "init": 30
        },
        {
          "id": "filter-decay",
          "name": "Decay Time",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 2037,
          "init": 30
        },
        {
          "id": "filter-sustain",
          "name": "Sustain Level",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 2218,
          "init": 330
        },
        {
          "id": "filter-modulation",
          "name": "Filter Modulation",
          "type": "switch",
          "class": "switch-1",
          "values": ["Off", "On"],
          "top": 183,
          "left": 1700,
          "init": 0
        },
        {
          "id": "filter-keyboard-1",
          "name": "Keyboard Control 1",
          "type": "switch",
          "class": "switch-1",
          "values": ["Off", "On"],
          "top": 267,
          "left": 1700,
          "init": 0
        },
        {
          "id": "filter-keyboard-2",
          "name": "Keyboard Control 2",
          "type": "switch",
          "class": "switch-1",
          "values": ["Off", "On"],
          "top": 352,
          "left": 1700,
          "init": 0
        },
      ]
    },

    {
      "id": "loudness",
      "name": "Loudness Contour",
      "controls": [
        {
          "id": "loudness-attack",
          "name": "Attack Time",
          "type": "knob",
          "class": "knob-2",
          "top": 546,
          "left": 1855,
          "init": 30
        },
        {
          "id": "loudness-decay",
          "name": "Decay Time",
          "type": "knob",
          "class": "knob-2",
          "top": 546,
          "left": 2037,
          "init": 30
        },
        {
          "id": "loudness-sustain",
          "name": "Sustain Level",
          "type": "knob",
          "class": "knob-2",
          "top": 546,
          "left": 2218,
          "init": 330
        },
      ]
    },

    {
      "id": "output",
      "name": "Output",
      "controls": [
        {
          "id": "output-volume",
          "name": "Volume",
          "type": "knob",
          "class": "knob-2",
          "top": 183,
          "left": 2400,
          "init": 180
        },
        {
          "id": "output-phones-level",
          "name": "Phones Volume",
          "type": "knob",
          "class": "knob-2",
          "top": 546,
          "left": 2400,
          "init": 180
        },
        {
          "id": "output-on",
          "name": "On",
          "type": "switch",
          "class": "switch-4",
          "values": ["Off", "On"],
          "top": 183,
          "left": 2556,
          "init": 1
        },
        {
          "id": "output-a440",
          "name": "A-440",
          "type": "switch",
          "class": "switch-4",
          "values": ["Off", "On"],
          "top": 364,
          "left": 2400,
          "init": 0
        }
      ],
      "sockets": [
        {
          "id": "output-phones",
          "name": "Phones",
          "type": "output",
          "signal": "audio",
          "top": 547,
          "left": 2555
        },
      ]
    },

    {
      "id": "io",
      "name": "In / Out",
      "controls": [
      ],
      "sockets": [
        {
          "id": "io-afterpressure-out",
          "name": "After Pressure",
          "type": "output",
          "signal": "modulation",
          "top": 855,
          "left": 504
        },
        {
          "id": "io-pitch-out",
          "name": "Pitch",
          "type": "output",
          "signal": "pitch",
          "top": 855,
          "left": 572
        },
        {
          "id": "io-vtrig-out",
          "name": "V-Trig",
          "type": "output",
          "signal": "trigger",
          "top": 855,
          "left": 640
        },
        {
          "id": "io-velocity-out",
          "name": "Velocity",
          "type": "output",
          "signal": "modulation",
          "top": 855,
          "left": 708
        },

        {
          "id": "io-ext-in",
          "name": "Ext. Signal",
          "type": "input",
          "signal": "audio",
          "top": 855,
          "left": 887
        },
        {
          "id": "io-main-out1",
          "name": "Main Output Low",
          "type": "output",
          "signal": "audio",
          "top": 855,
          "left": 954
        },
        {
          "id": "io-main-out2",
          "name": "Main Output High",
          "type": "output",
          "signal": "audio",
          "top": 855,
          "left": 1021
        },

        {
          "id": "io-loudness-in",
          "name": "Loundess Control In",
          "type": "input",
          "signal": "modulation",
          "top": 855,
          "left": 2375
        },
        {
          "id": "io-filter-in",
          "name": "Filter Control In",
          "type": "input",
          "signal": "modulation",
          "top": 855,
          "left": 2442
        },
        {
          "id": "io-oscillator-in",
          "name": "Oscillator Control In",
          "type": "input",
          "signal": "pitch",
          "top": 855,
          "left": 2509
        },
        {
          "id": "io-modsrc-in",
          "name": "Mod Src In",
          "type": "input",
          "signal": "modulation",
          "top": 855,
          "left": 2577
        },
        {
          "id": "io-vtrig-in",
          "name": "Ext. V-Trig",
          "type": "input",
          "signal": "trigger",
          "top": 855,
          "left": 2676
        },
      ]
    },

  ]
}
