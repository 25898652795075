
export default ['$q', '$scope', 'CommonService', 'UserService', 'close',
  function($q, $scope, CommonService, UserService, close) {

    $scope.status = null;
    $scope.error = null;
    $scope.success = null;
    
    $scope.formdata = {
      email: '',
      password: '',
      remember: false,
    }

    $scope.init = function(){
      $scope.$watch('formdata', function(){
        $scope.canSubmit = $scope.userSigninForm.$valid;
      }, true);

      $scope.status = 'loading';
      $q.all([
        CommonService.getConfig()
      ])
      .then(function(result){
        $scope.status = null;
        $scope.config = result[0].data;
      });
    }

    $scope.submit = function(){
      $scope.error = null;
      $scope.success = null;
      $scope.status = 'submitting';

      const data = {
        email: $scope.formdata.email,
        password: $scope.formdata.password,
        remember: $scope.formdata.remember,
      };

      UserService.signIn(data).then(function(result){
        $scope.status = null;

        if(result.success) {
          $scope.success = true;
          location.reload(true);
        }
        else {
          $scope.error = result.error;
        }
      });
    }

    $scope.forgot = function(){
      close();
      $scope.$root.$broadcast('forgot');
    }

    $scope.register = function(){
      close();
      $scope.$root.$broadcast('register');
    }

    $scope.cancel = function(){
      close();
      history.replaceState(null, null, location.pathname);
    }

    $scope.init();
    //history.replaceState(null, null, location.pathname);

  }
]
