import angular from 'angular';

export default ['$q', '$scope', 'PatchService', 'data', 'close',
  function($q, $scope, PatchService, data, close) {
    $scope.status = null;

    $scope.quantityOptions = [
      { slug: 'any', title: 'Any' },
      { slug: '1', title: '1' },
      { slug: '2', title: '2' },
      { slug: '3', title: '3' },
      { slug: '4', title: '4' },
      { slug: '5', title: '5' },
    ];
    
    $scope.init = function(){
      $scope.status = 'loading';
      $scope.formdata = data;
      $scope.formdataClean = angular.copy($scope.formdata);

      $q.all([
        PatchService.getDevices(),
      ])
      .then(function(result){
        $scope.status = null;
        $scope.devices = result[0].data;
      });

      $scope.$watch('formdata', function(){
        $scope.canSubmit = !angular.equals($scope.formdata, $scope.formdataClean);
      }, true);
    }

    $scope.search = function(){
      close($scope.formdata);
    }

    $scope.cancel = function(){
      close(false);
    }

    $scope.init();
  }
]
