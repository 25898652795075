export default ['$scope', 'CommonService', 'content', 'close',
  function($scope, CommonService, content, close) {
    $scope.status = null;
    $scope.content = content;
    $scope.close = close;

    $scope.init = function(){
      $scope.status = 'loading';
      
      CommonService.getConfig()
      .then(function(result){
        $scope.status = null;
        $scope.config = result.data;
      });
    }

    $scope.upgrade = function(){
      $scope.close();
      $scope.$root.$broadcast('profile');
    }

    $scope.init();
  }
]
