
export default ['$scope', 'UserService', 'ModalService', 'close',
  function($scope, UserService, ModalService, close) {

    $scope.status = null;
    $scope.error = null;
    $scope.success = null;
    $scope.action = 'verify';
    
    $scope.formdata = {
      code: new URLSearchParams(window.location.search).get('reset'),
      password: '',
      repeat: '',
    };

    $scope.init = function(){
      $scope.$watch('formdata', function(){
        $scope.canSubmit = $scope.userResetForm.$valid;
      }, true);

      const data = {
        code: $scope.formdata.code,
      };

      $scope.status = 'loading';
      UserService.resetCheck(data).then(function(result){
        $scope.status = null;

        if(result.success) {
          $scope.action = 'reset';
        }
        else {
          $scope.error = result.error;
        }
      });
    }

    $scope.submit = function(){
      $scope.error = null;
      $scope.success = null;
      $scope.status = 'submitting';

      const data = {
        code: $scope.formdata.code,
        password: $scope.formdata.password,
        repeat: $scope.formdata.repeat,
      };

      UserService.resetPassword(data).then(function(result){
        $scope.status = null;

        if(result.success) {
          $scope.success = true;
          $scope.action = 'success';
        }
        else {
          $scope.error = result.error;
        }
      });
    }

    $scope.resend = function(){
      close();
      $scope.$root.$broadcast('forgot');
    }

    $scope.signin = function(){
      close();
      $scope.$root.$broadcast('signin');
    }

    $scope.cancel = function(){
      close();
      history.replaceState(null, null, location.pathname);
    }

    $scope.init();
    history.replaceState(null, null, location.pathname);

  }
]
