import patchbay from './patchbay/patchbay';
import grandmother from './grandmother/grandmother';
import ms20 from './ms20/ms20';
import sherman from './sherman/sherman';
import s1mk2 from './s1mk2/s1mk2';
import ocoast from './ocoast/ocoast';
import mother32 from './mother32/mother32';
import dfam from './dfam/dfam';
import erebus3 from './erebus3/erebus3';
import modeld from './modeld/modeld';
import subharmonicon from './subharmonicon/subharmonicon'

export default {
  'patchbay': patchbay,
  'Ø-coast': ocoast,
  'dfam': dfam,
  'erebus-v3': erebus3,
  'grandmother': grandmother,
  'model-d': modeld,
  'mother-32': mother32,
  'ms20': ms20,
  's1-mk2': s1mk2,
  'sherman-filterbank': sherman,
  'subharmonicon': subharmonicon,
};
