import angular from 'angular';

export default ['$timeout', 'ModalService', 'PatchService', 'UserService',
  function($timeout, ModalService, PatchService, UserService){
    return {
      restrict: 'E',
      templateUrl: 'patch-header.html',
      controller: ['$scope', function($scope){

        $scope.save = function(){
          if(!$scope.patch.title || !$scope.patch.category || !$scope.deviceCount()) {
            return;
          }

          const data = angular.copy($scope.patch);

          if($scope.user) {
            $scope.status = 'saving';

            PatchService.savePatch(data)
            .then(function(result){
              $timeout(function(){
                $scope.status = null;
              }, 500);

              if(result.success) {
                if($scope.patch.id) {
                  $scope.patch = angular.copy(result.data);
                }
                else {
                  location.href = result.data.link;
                }
              }
              else {
                ModalService.showModal({
                  templateUrl: 'save-patch.html',
                  controller: 'SavePatchController',
                  inputs: {
                    content: result.error,
                  },
                });
              }
            });
          }
          else {
            sessionStorage.setItem('patch', JSON.stringify({
              action: 'save',
              data: $scope.patch,
            }));

            $scope.$root.$broadcast('signin');
          }
        }

        $scope.copy = function(){
          ModalService.showModal({
            templateUrl: 'modal.html',
            controller: 'ModalController',
            inputs: {
              title: 'Copy Patch',
              content: 'Copy this patch to a new, unsaved patch?',
              ok: 'Yes',
              cancel: 'No',
            },
          }).then(function(modal) {
            modal.close.then(function(result) {
              if(result) {
                $scope.status = 'copying';
                let copy = angular.copy($scope.patch);
                copy.id = null;
                copy.title = null;
                copy.copy_id = $scope.patch.id;
                sessionStorage.setItem('patch', JSON.stringify({
                  action: 'copy',
                  data: copy,
                }));
                location.href = '/patch';
              }
            });
          });
        }

        $scope.delete = function(){
          ModalService.showModal({
            templateUrl: 'modal.html',
            controller: 'ModalController',
            inputs: {
              title: 'Delete Patch',
              content: 'Are you sure you want to delete this patch?',
              ok: 'Yes',
              cancel: 'No',
            },
          }).then(function(modal) {
            modal.close.then(function(result) {
              if(result) {
                const data = {
                  id: $scope.patch.id,
                };

                $scope.status = 'deleting';
                PatchService.deletePatch(data)
                .then(function(result){
                  if(result.success) {
                    location.href = $scope.user.link;
                  }
                });
              }
            });
          });
        }

        $scope.settings = function(){
          ModalService.showModal({
            templateUrl: 'ui-settings.html',
            controller: 'UiSettingsController',
          }).then(function(modal){
            modal.close.then(function(result) {
              if(result) {
                $scope.setupInterface();
              }
            });
          });
        }

        $scope.like = function(){
          const data = {
            id: $scope.patch.id,
          };

          $scope.patch.liked = !$scope.patch.liked;
          PatchService.likePatch(data);
        }

        $scope.actions = function(){
          //document.body.classList.toggle('-patch-actions');
          $scope.actionsActive = !$scope.actionsActive;
        }

        $scope.info = function(){
          //document.body.classList.toggle('-patch-info');
          $scope.infoActive = !$scope.infoActive;
          UserService.setSetting('patchInfo', $scope.infoActive)
        }

        $scope.$watch('permissions', function(permissions){
          if($scope.patch && ($scope.permissions.save || $scope.permissions.like || $scope.patch.description)) {
            $scope.actionsMenu = true;
          }

          if(($scope.patch && UserService.getSetting('patchInfo')) && (permissions.save || $scope.patch.description)) {
            $scope.info();
          }
        }, true);

      }]
    };
  }
]
