export default ['$q', '$http', 'AppSettings',
  function($q, $http, AppSettings){
    return {

      getUser: function(){
        if(typeof(window.APP.user) === 'undefined') {
          return $http.get('/api/user', {
            cache: true,
          })
          .then(function success(response){
            return response.data;
          }, 
          function error(error){
            return {
              success: false,
              error: error.config.url,
            }
          });
        }
        else {
          const deferral = $q.defer(); 
          deferral.resolve({ 
            success: true,
            data: window.APP.user,
          });
          return deferral.promise;
        }
      },
        
      signIn: function(data){
        return $http.post('/api/user/signin', data)
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      signOut: function(){
        return $http.post('/api/user/signout', {})
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });     
      },

      forgotPassword: function(data){
        return $http.post('/api/user/forgot', data)
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });    
      },

      resetCheck: function(data){
        return $http.post('/api/user/resetcheck', data)
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      resetPassword: function(data){
        return $http.post('/api/user/resetpassword', data)
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      register: function(data){
        return $http.post('/api/user/register', data)
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      updateUser: function(data){
        return $http.post('/api/user/update', data)
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      getPlans: function(){
        return $http.get('/api/user/plans', {
          cache: true,
        })
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      getSettings: function(){
        const data = localStorage.getItem('settings');
        return data ? JSON.parse(data) : {};
      },

      getSetting: function(key){
        const data = this.getSettings();
        return data && typeof(data[key]) !== 'undefined' ? data[key] : AppSettings.userSettings[key];
      },

      setSettings: function(data){
        localStorage.setItem('settings', JSON.stringify(data))
      },

      setSetting: function(key, value){
        const data = this.getSettings() || {};
        data[key] = value;
        this.setSettings(data);
      }

    };
  }
]

