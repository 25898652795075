export default ['$q', '$http',
  function($q, $http){
    return {

      searchPatches: function(data){
        return $http.get('/api/search', {
          params: data,
          cache: true,
        })
        .then(function success(response){
          return response.data;
        }, 
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

    };
  }
]

