export default [
  function() {
    return {
      restrict: 'E',
      scope: true,
      controller: ['$scope', function($scope){
        $scope.$root.$broadcast('signin');
      }]
    };
  }
]
