export default {
  "name": "MS-20",
  "id": "ms20",
  "vendor": "Korg",
  "width": 2260,
  "height": 729,
  "scale": 1,
  "images": {
    "panel": "ms20-panel.png",
    "thumbnail": "ms20-thumbnail.png",
    "selector": "ms20-selector.png"
  },
  "notes": "The infamous MS-20 is a dual oscillator, dual filter analog synthesizer first released in 1978, and reissued almost 40 years later in mini, kit and desktop module sizes.",
  "link": "https://www.korg.com/us/products/synthesizers/ms_20mini",
  "sections": [
    {
      "id": "vco1",
      "name": "VCO 1",
      "controls": [
        {
          "id": "vco1-wave",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-1",
          "range": [
            135,
            225
          ],
          "values": [
            "Triangle",
            "Sawtooth",
            "Pulse",
            "Noise"
          ],
          "top": 139,
          "left": 144,
          "init": 135
        },
        {
          "id": "vco1-pw",
          "name": "PW",
          "type": "knob",
          "class": "knob-2",
          "top": 293,
          "left": 144,
          "init": 180
        },
        {
          "id": "vco1-scale",
          "name": "Scale",
          "type": "knob",
          "class": "knob-1",
          "range": [
            135,
            225
          ],
          "values": [
            "32'",
            "16'",
            "8'",
            "4'"
          ],
          "top": 451,
          "left": 144,
          "init": 165
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "vco2",
      "name": "VCO 2",
      "controls": [
        {
          "id": "vco2-wave",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-1",
          "range": [
            135,
            225
          ],
          "values": [
            "Sawtooth",
            "Square",
            "Narrow",
            "Ring"
          ],
          "top": 139,
          "left": 312,
          "init": 135
        },
        {
          "id": "vco2-pitch",
          "name": "Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 293,
          "left": 312,
          "init": 180
        },
        {
          "id": "vco2-scale",
          "name": "Scale",
          "type": "knob",
          "class": "knob-1",
          "range": [
            135,
            225
          ],
          "values": [
            "16'",
            "8'",
            "4'",
            "2'"
          ],
          "top": 451,
          "left": 312,
          "init": 135
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "global",
      "name": "Global",
      "controls": [
        {
          "id": "global-porta",
          "name": "Portamento",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 144,
          "init": 180
        },
        {
          "id": "global-tune",
          "name": "Master Tune",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 312,
          "init": 180
        },
        {
          "id": "global-volume",
          "name": "Volume",
          "type": "knob",
          "class": "knob-1",
          "range": [
            30,
            300
          ],
          "top": 119,
          "left": 2001,
          "init": 180
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "mixer",
      "name": "VCO Mixer",
      "controls": [
        {
          "id": "mixer-vco1",
          "name": "Potamento",
          "type": "knob",
          "class": "knob-2",
          "top": 139,
          "left": 470,
          "init": 180
        },
        {
          "id": "mixer-vco2",
          "name": "Master Tune",
          "type": "knob",
          "class": "knob-2",
          "top": 309,
          "left": 470,
          "init": 180
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "fmmod",
      "name": "Frequency Modulation",
      "controls": [
        {
          "id": "fmmod-mg",
          "name": "MG/T.Ext",
          "type": "knob",
          "class": "knob-2",
          "top": 516,
          "left": 470,
          "init": 30
        },
        {
          "id": "fmmod-eg",
          "name": "EG1/Ext",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 470,
          "init": 30
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "hpf",
      "name": "Highpass Filter",
      "controls": [
        {
          "id": "hpf-cutoff",
          "name": "Cutoff Frequency",
          "type": "knob",
          "class": "knob-1",
          "top": 137,
          "left": 621,
          "init": 30
        },
        {
          "id": "hpf-peak",
          "name": "Peak",
          "type": "knob",
          "class": "knob-2",
          "top": 309,
          "left": 621,
          "init": 30
        },
        {
          "id": "hpf-mg",
          "name": "MG/T.Ext",
          "type": "knob",
          "class": "knob-2",
          "top": 516,
          "left": 621,
          "init": 30
        },
        {
          "id": "hpf-eg",
          "name": "EG2/Ext",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 621,
          "init": 30
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "lpf",
      "name": "Lowpass Filter",
      "controls": [
        {
          "id": "lpf-cutoff",
          "name": "Cutoff Frequency",
          "type": "knob",
          "class": "knob-1",
          "top": 137,
          "left": 773,
          "init": 330
        },
        {
          "id": "lpf-peak",
          "name": "Peak",
          "type": "knob",
          "class": "knob-2",
          "top": 309,
          "left": 773,
          "init": 30
        },
        {
          "id": "lpf-mg",
          "name": "MG/T.Ext",
          "type": "knob",
          "class": "knob-2",
          "top": 516,
          "left": 773,
          "init": 30
        },
        {
          "id": "lpf-eg",
          "name": "EG2/Ext",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 773,
          "init": 30
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "mg",
      "name": "Modulation Generator",
      "controls": [
        {
          "id": "mg-wave",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-2",
          "top": 516,
          "left": 923,
          "init": 180
        },
        {
          "id": "mg-freq",
          "name": "Frequency",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 923,
          "init": 180
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "eg1",
      "name": "Envelope Generator 1",
      "controls": [
        {
          "id": "eg1-delay",
          "name": "Delay Time",
          "type": "knob",
          "class": "knob-2",
          "top": 397,
          "left": 1075,
          "init": 30
        },
        {
          "id": "eg1-attack",
          "name": "Attack Time",
          "type": "knob",
          "class": "knob-2",
          "top": 516,
          "left": 1075,
          "init": 30
        },
        {
          "id": "eg1-release",
          "name": "Release Time",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 1075,
          "init": 30
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "eg2",
      "name": "Envelope Generator 2",
      "controls": [
        {
          "id": "eg2-hold",
          "name": "Hold Time",
          "type": "knob",
          "class": "knob-2",
          "top": 137,
          "left": 1226,
          "init": 30
        },
        {
          "id": "eg2-attack",
          "name": "Attack Time",
          "type": "knob",
          "class": "knob-2",
          "top": 278,
          "left": 1226,
          "init": 30
        },
        {
          "id": "eg2-delay",
          "name": "Decay Time",
          "type": "knob",
          "class": "knob-2",
          "top": 397,
          "left": 1226,
          "init": 30
        },
        {
          "id": "eg2-sustain",
          "name": "Sustain Level",
          "type": "knob",
          "class": "knob-2",
          "top": 516,
          "left": 1226,
          "init": 330
        },
        {
          "id": "eg2-release",
          "name": "Release Time",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 1226,
          "init": 30
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "esp",
      "name": "External Signal Processor",
      "controls": [
        {
          "id": "esp-signal",
          "name": "Signal Level",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 1380,
          "init": 30
        },
        {
          "id": "esp-lowcut",
          "name": "Low Cut Freq",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 1500,
          "init": 30
        },
        {
          "id": "esp-highcut",
          "name": "High Cut Freq",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 1619,
          "init": 30
        },
        {
          "id": "esp-adjust",
          "name": "CV Adjust",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 1739,
          "init": 30
        },
        {
          "id": "esp-threshold",
          "name": "Threshold Level",
          "type": "knob",
          "class": "knob-2",
          "top": 636,
          "left": 1858,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "esp-sigin",
          "name": "Signal In",
          "type": "input",
          "signal": "audio",
          "top": 530,
          "left": 1361
        },
        {
          "id": "esp-preampout",
          "name": "Preamp Out",
          "type": "output",
          "signal": "audio",
          "top": 530,
          "left": 1520
        },
        {
          "id": "esp-bpfout",
          "name": "Band Pass Filter Out",
          "type": "output",
          "signal": "audio",
          "top": 530,
          "left": 1678
        },
        {
          "id": "esp-fvout",
          "name": "FV CV Out",
          "type": "output",
          "signal": "pitch",
          "top": 530,
          "left": 1838
        },
        {
          "id": "esp-envout",
          "name": "Env Out",
          "type": "output",
          "signal": "modulation",
          "top": 530,
          "left": 2048
        },
        {
          "id": "esp-trigout",
          "name": "Trig Out",
          "type": "output",
          "signal": "trigger",
          "top": 530,
          "left": 2126
        }
      ]
    },
    {
      "id": "patchbay",
      "name": "Patchbay",
      "controls": [
        
      ],
      "sockets": [
        {
          "id": "pb-total",
          "name": "Total",
          "type": "input",
          "signal": "modulation",
          "top": 154,
          "left": 1361
        },
        {
          "id": "pb-vco-freq",
          "name": "VCO Freq",
          "type": "input",
          "signal": "modulation",
          "top": 154,
          "left": 1476
        },
        {
          "id": "pb-extin",
          "name": "Ext Signal In",
          "type": "input",
          "signal": "audio",
          "top": 154,
          "left": 1567
        },
        {
          "id": "pb-hpf-freq",
          "name": "HPF Cutoff Freq",
          "type": "input",
          "signal": "modulation",
          "top": 154,
          "left": 1658
        },
        {
          "id": "pb-lpf-freq",
          "name": "LPF Cutoff Freq",
          "type": "input",
          "signal": "modulation",
          "top": 154,
          "left": 1757
        },
        {
          "id": "pb-vca-gain",
          "name": "VCA Initial Gain",
          "type": "input",
          "signal": "modulation",
          "top": 154,
          "left": 1861
        },
        {
          "id": "pb-out-main",
          "name": "Signal Out",
          "type": "output",
          "signal": "audio",
          "top": 75,
          "left": 2127
        },
        {
          "id": "pb-out-phones",
          "name": "Phones",
          "type": "output",
          "signal": "audio",
          "top": 150,
          "left": 2127
        },
        {
          "id": "pb-mg-tri",
          "name": "Modulation Generator Triangle",
          "type": "output",
          "signal": "modulation",
          "top": 258,
          "left": 1361
        },
        {
          "id": "pb-mg-square",
          "name": "Modulation Generator Square",
          "type": "output",
          "signal": "modulation",
          "top": 258,
          "left": 1519
        },
        {
          "id": "pb-eg1",
          "name": "Envelope Generator 1 Out",
          "type": "output",
          "signal": "modulation",
          "top": 258,
          "left": 1599
        },
        {
          "id": "pb-eg1-rev",
          "name": "Envelope Generator 1 Rev Out",
          "type": "output",
          "signal": "modulation",
          "top": 339,
          "left": 1599
        },
        {
          "id": "pb-eg1-trig",
          "name": "Envelope Generator 1 Trig Out",
          "type": "input",
          "signal": "modulation",
          "top": 339,
          "left": 1802
        },
        {
          "id": "pb-eg2-rev",
          "name": "Envelope Generator 2 Rev Out",
          "type": "output",
          "signal": "modulation",
          "top": 258,
          "left": 1904
        },
        {
          "id": "pb-sh-clock",
          "name": "Sample & Hold Clock",
          "type": "clock",
          "signal": "modulation",
          "top": 338,
          "left": 1440
        },
        {
          "id": "pb-sh-in",
          "name": "Sample & Hold In",
          "type": "input",
          "signal": "any",
          "top": 419,
          "left": 1361
        },
        {
          "id": "pb-sh-out",
          "name": "Sample & Hold Out",
          "type": "output",
          "signal": "modulation",
          "top": 419,
          "left": 1519
        },
        {
          "id": "pb-vca-cont",
          "name": "VCA Control Input",
          "type": "input",
          "signal": "modulation",
          "top": 338,
          "left": 1678
        },
        {
          "id": "pb-vca-in",
          "name": "VCA Input",
          "type": "input",
          "signal": "unknown",
          "pass": [
            "pb-vca-in",
            "pb-vca-out"
          ],
          "top": 419,
          "left": 1599
        },
        {
          "id": "pb-vca-out",
          "name": "VCA Output",
          "type": "output",
          "signal": "unknown",
          "pass": [
            "pb-vca-in",
            "pb-vca-out"
          ],
          "top": 419,
          "left": 1722
        },
        {
          "id": "pb-noise-pink",
          "name": "Noise Generator Pink",
          "type": "output",
          "signal": "audio",
          "top": 419,
          "left": 1802
        },
        {
          "id": "pb-noise-white",
          "name": "Noise Generator White",
          "type": "output",
          "signal": "audio",
          "top": 419,
          "left": 1961
        },
        {
          "id": "pb-vco-cv",
          "name": "VCO 1+2 CV In",
          "type": "input",
          "signal": "modulation",
          "top": 258,
          "left": 2048
        },
        {
          "id": "pb-vco2-cv",
          "name": "VCO 2 CV In",
          "type": "input",
          "signal": "modulation",
          "top": 299,
          "left": 1972
        },
        {
          "id": "pb-kbd-cv",
          "name": "KBD CV Out",
          "type": "output",
          "signal": "pitch",
          "top": 258,
          "left": 2127
        },
        {
          "id": "pb-trig-in",
          "name": "Trig In",
          "type": "input",
          "signal": "trigger",
          "top": 338,
          "left": 2048
        },
        {
          "id": "pb-trig-out",
          "name": "Trig Out",
          "type": "output",
          "signal": "trigger",
          "top": 338,
          "left": 2127
        },
        {
          "id": "pb-wheel",
          "name": "Wheel",
          "type": "output",
          "signal": "modulation",
          "top": 418,
          "left": 2048
        },
        {
          "id": "pb-button",
          "name": "Button",
          "type": "output",
          "signal": "gate",
          "top": 418,
          "left": 2127
        }
      ]
    }
  ]
}
