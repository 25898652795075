export default [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'subnav.html',
      replace: true,
      scope: {
        heading: '@',
      },
      controller: ['$scope', function($scope){
        $scope.subnav = [];

        $scope.init = function(){

          Array.from(document.querySelectorAll('[data-subnav-section]')).map(function(section){
            const title = section.querySelector('[data-subnav-title]');
            const data = {
              id: title ? title.id : null,
              title: title ? title.innerText : null,
              items: [],
            };

            Array.from(section.querySelectorAll('[data-subnav-item]')).map(function(item){
              const title = item.querySelector('[data-subnav-subtitle]');
              data.items.push({
                id: item.id,
                title: title.innerText,
              });
            });

            $scope.subnav.push(data);
          });

          window.addEventListener('hashchange', function(){
            const id = location.hash.split('#!').pop();
            if(id) {
              $scope.goto(id);
            }
          }, false);

          window.addEventListener('load', function(){
            const id = location.hash.split('#!').pop();
            if(id) {
              $scope.goto(id);
            }
          }, false);

        }

        $scope.goto = function(id){
          const el = document.getElementById(id);
          if(el) {
            window.scrollTo({
              top: el.offsetTop - 190,
              behavior: 'smooth',
            });

            history.replaceState(null, null, `#!${id}`);

            el.classList.add('-active');
            setTimeout(function(){
              el.classList.remove('-active');
            }, 3000);
          }
        }

        $scope.init();

      }]
    };
  }
]
