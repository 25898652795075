export default ['ModalService', 'UserService',
  function(ModalService, UserService) {
    return {
      restrict: 'AE',
      scope: true,
      controller: ['$scope', function($scope){

        $scope.toggle = function() {
          document.body.classList.toggle('-nav-menu');
        }

        $scope.register = function(){
          ModalService.showModal({
            templateUrl: 'register.html',
            controller: 'RegisterController',
          });
        }

        $scope.signIn = function(){
          ModalService.showModal({
            templateUrl: 'signin.html',
            controller: 'SigninController',
          });
        }

        $scope.forgot = function(){
          ModalService.showModal({
            templateUrl: 'forgot.html',
            controller: 'ForgotController',
          });
        }

        $scope.reset = function(){
          ModalService.showModal({
            templateUrl: 'reset.html',
            controller: 'ResetController',
          });
        }

        $scope.profile = function(){
          ModalService.showModal({
            templateUrl: 'profile.html',
            controller: 'ProfileController',
          });
        }

        $scope.signOut = function(){
          UserService.signOut()
          .then(function(){
            location.href = '/';
          });
        }

        $scope.$on('register', function(){
          $scope.register();
        });

        $scope.$on('signin', function(){
          $scope.signIn();
        });

        $scope.$on('forgot', function(){
          $scope.forgot();
        });

        $scope.$on('reset', function(){
          $scope.reset();
        });

        $scope.$on('profile', function(){
          $scope.profile();
        });

      }]
    };
  }
]
