export default [
  function(){
    return {
      restrict: 'E',
      templateUrl: 'result-item.html',
      replace: true,
      controller: function(){
      }
    };
  }
]
