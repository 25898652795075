export default [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'switch.html',
      replace: true,
      scope: {
        model: '=',
        config: '=',
      },
      controller: ['$scope', function($scope){
        $scope.click = function($event){
          $event.stopPropagation();

          if(typeof($scope.config.values[$scope.model + 1]) !== 'undefined') {
            $scope.model+= 1;
          }
          else {
            $scope.model = 0;
          }
        }

        $scope.mousedown = function($event){
          $event.stopPropagation();
        }
      }]
    };
  }
]
