export default ['$scope', 'AppSettings', 'PatchService', 'canAddDevice', 'canAddPatchbay', 'close',
  function($scope, AppSettings, PatchService, canAddDevice, canAddPatchbay, close) {

    $scope.status = null;
    $scope.error = null;
    $scope.success = null;

    $scope.init = function(){
      $scope.status = 'loading';
      PatchService.getDevices()
      .then(function(result){
        $scope.status = null;

        $scope.addDevices = result.data;
        $scope.canAddDevice = canAddDevice;
        $scope.canAddPatchbay = canAddPatchbay;
      });
    }

    $scope.add = function(data){
      close(data);
    }

    $scope.cancel = function(){
      close(false);
    }

    $scope.init();

  }
]
