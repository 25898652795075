export default ['$scope', 'title', 'content', 'ok', 'cancel', 'close',
  function($scope, title, content, ok, cancel, close) {

    $scope.title = title;
    $scope.content = content;
    $scope.ok = ok;
    $scope.cancel = cancel;
    $scope.close = close;

  }
];
