export default {
  "name": "Mother 32",
  "id": "mother-32",
  "vendor": "Moog Music",
  "width": 1273,
  "height": 544,
  "scale": 1,
  "images": {
    "panel": "mother32-panel.png",
    "thumbnail": "mother32-thumbnail.png",
    "selector": "mother32-selector.png"
  },
  "notes": "The Mother-32 is a semi-modular analogue synthesizer from Moog Music, with a built-in sequencer.",
  "sections": [
    {
      "id": "vco",
      "name": "VCO",
      "controls": [
        {
          "id": "vco-freq",
          "name": "Frequency",
          "type": "knob",
          "class": "knob-1",
          "top": 84,
          "left": 118,
          "init": 180
        },
        {
          "id": "vco-glide",
          "name": "Glide",
          "type": "knob",
          "class": "knob-1",
          "top": 201,
          "left": 118,
          "init": 30
        },
        {
          "id": "vco-pw",
          "name": "Pulse Width",
          "type": "knob",
          "class": "knob-1",
          "top": 84,
          "left": 354,
          "init": 180
        },
        {
          "id": "vco-mix",
          "name": "Mix",
          "type": "knob",
          "class": "knob-1",
          "top": 84,
          "left": 472,
          "init": 30
        },
        {
          "id": "vco-mod-amt",
          "name": "VCO Mod Amount",
          "type": "knob",
          "class": "knob-1",
          "top": 201,
          "left": 354,
          "init": 180
        },
        {
          "id": "vco-wave",
          "name": "VCO Wave",
          "type": "switch",
          "class": "switch-1",
          "values": ["Saw", "Pulse"],
          "top": 83,
          "left": 236,
          "init": 0
        },
        {
          "id": "vco-mod-src",
          "name": "VCO Mod Source",
          "type": "switch",
          "class": "switch-1",
          "values": ["LFO", "EG/VCO Mod"],
          "top": 200,
          "left": 236,
          "init": 0
        },
        {
          "id": "vco-mod-dest",
          "name": "VCO Mod Dest",
          "type": "switch",
          "class": "switch-1",
          "values": ["Frequency", "Pulse Width"],
          "top": 200,
          "left": 472,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "vcf",
      "name": "VCF",
      "controls": [
        {
          "id": "vcf-cutoff",
          "name": "Cutoff",
          "type": "knob",
          "class": "knob-1",
          "top": 84,
          "left": 614,
          "init": 330
        },
        {
          "id": "vcf-reso",
          "name": "Resonance",
          "type": "knob",
          "class": "knob-1",
          "top": 84,
          "left": 756,
          "init": 30
        },
        {
          "id": "vcf-mod-amt",
          "name": "VCF Mod Amount",
          "type": "knob",
          "class": "knob-1",
          "top": 201,
          "left": 826,
          "init": 30
        },
        {
          "id": "vcf-mode",
          "name": "VCF Mode",
          "type": "switch",
          "class": "switch-1",
          "values": ["Low Pass", "High Pass"],
          "top": 200,
          "left": 590,
          "init": 0
        },
        {
          "id": "vcf-mod-src",
          "name": "VCF Mod Source",
          "type": "switch",
          "class": "switch-1",
          "values": ["LFO", "EG"],
          "top": 200,
          "left": 708,
          "init": 0
        },
        {
          "id": "vcf-mod-polarity",
          "name": "VCF Mod Polarity",
          "type": "switch",
          "class": "switch-1",
          "values": ["-", "+"],
          "top": 200,
          "left": 944,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "vca",
      "name": "VCA",
      "controls": [
        {
          "id": "vca-volume",
          "name": "Volume",
          "type": "knob",
          "class": "knob-1",
          "top": 84,
          "left": 944,
          "init": 330
        },
        {
          "id": "vca-mode",
          "name": "VCA Mode",
          "type": "switch",
          "class": "switch-1",
          "values": ["EG", "On"],
          "top": 83,
          "left": 855,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "lfo",
      "name": "LFO",
      "controls": [
        {
          "id": "lfo-rate",
          "name": "LFO Rate",
          "type": "knob",
          "class": "knob-1",
          "top": 319,
          "left": 354,
          "init": 180
        },
        {
          "id": "lfo-wave",
          "name": "LFO Wave",
          "type": "switch",
          "class": "switch-1",
          "values": ["Triangle", "Square"],
          "top": 319,
          "left": 473,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "eg",
      "name": "EG",
      "controls": [
        {
          "id": "eg-attack",
          "name": "Attack",
          "type": "knob",
          "class": "knob-1",
          "top": 319,
          "left": 590,
          "init": 30
        },
        {
          "id": "eg-decay",
          "name": "Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 319,
          "left": 826,
          "init": 180
        },
        {
          "id": "eg-sustain",
          "name": "Sustain",
          "type": "switch",
          "class": "switch-1",
          "values": ["Off", "On"],
          "top": 319,
          "left": 708,
          "init": 0
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "mix",
      "name": "VC Mixer",
      "controls": [
        {
          "id": "mix-amnt",
          "name": "VC Mix",
          "type": "knob",
          "class": "knob-1",
          "top": 319,
          "left": 944,
          "init": 180
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "global",
      "name": "Global",
      "controls": [
        {
          "id": "global-tempo",
          "name": "Tempo / Gate Length",
          "type": "knob",
          "class": "knob-1",
          "top": 319,
          "left": 195,
          "init": 180
        },
      ],
      "sockets": [
      ]
    },
    {
      "id": "patchbay",
      "name": "Patchbay",
      "controls": [
      ],
      "sockets": [
        {
          "id": "patchbay-extaudio-in",
          "name": "Ext Audio",
          "type": "input",
          "signal": "audio",
          "top": 78,
          "left": 1054
        },
        {
          "id": "patchbay-mixcv-in",
          "name": "Mix CV",
          "type": "input",
          "signal": "modulation",
          "top": 78,
          "left": 1109
        },
        {
          "id": "patchbay-vcacv-in",
          "name": "VCA CV",
          "type": "input",
          "signal": "modulation",
          "top": 78,
          "left": 1164
        },
        {
          "id": "patchbay-vca-out",
          "name": "VCA Out",
          "type": "output",
          "signal": "audio",
          "top": 78,
          "left": 1220
        },

        {
          "id": "patchbay-noise-out",
          "name": "Noise",
          "type": "output",
          "signal": "audio",
          "top": 133,
          "left": 1054
        },
        {
          "id": "patchbay-vcfcutoff-in",
          "name": "VCF Cutoff",
          "type": "input",
          "signal": "modulation",
          "top": 133,
          "left": 1109
        },
        {
          "id": "patchbay-vcfres-in",
          "name": "VCF Res",
          "type": "input",
          "signal": "modulation",
          "top": 133,
          "left": 1164
        },
        {
          "id": "patchbay-vcf-out",
          "name": "VCF Out",
          "type": "output",
          "signal": "audio",
          "top": 133,
          "left": 1220
        },

        {
          "id": "patchbay-vcovoct-in",
          "name": "VCO 1v/Oct",
          "type": "input",
          "signal": "pitch",
          "top": 188,
          "left": 1054
        },
        {
          "id": "patchbay-vcolinfm-in",
          "name": "VCO Lin FM",
          "type": "input",
          "signal": "audio",
          "top": 188,
          "left": 1109
        },
        {
          "id": "patchbay-vcosaw-out",
          "name": "VCO Saw",
          "type": "output",
          "signal": "audio",
          "top": 188,
          "left": 1164
        },
        {
          "id": "patchbay-vcopulse-out",
          "name": "VCO Pulse",
          "type": "output",
          "signal": "audio",
          "top": 188,
          "left": 1220
        },

        {
          "id": "patchbay-vcomod-in",
          "name": "VCO Mod",
          "type": "input",
          "signal": "modulation",
          "top": 243,
          "left": 1054
        },
        {
          "id": "patchbay-lforate-in",
          "name": "LFO Rate",
          "type": "input",
          "signal": "modulation",
          "top": 243,
          "left": 1109
        },
        {
          "id": "patchbay-lfotri-out",
          "name": "LFO Tri",
          "type": "output",
          "signal": "modulation",
          "top": 243,
          "left": 1164
        },
        {
          "id": "patchbay-lfosquare-out",
          "name": "LFO Square",
          "type": "output",
          "signal": "modulation",
          "top": 243,
          "left": 1220
        },

        {
          "id": "patchbay-mix1-in",
          "name": "Mix 1",
          "type": "input",
          "signal": "unknown",
          "top": 298,
          "left": 1054
        },
        {
          "id": "patchbay-mix2-in",
          "name": "Mix 2",
          "type": "input",
          "signal": "unknown",
          "top": 298,
          "left": 1109
        },
        {
          "id": "patchbay-mixctrl-in",
          "name": "VC Mix Ctrl",
          "type": "input",
          "signal": "modulation",
          "top": 298,
          "left": 1164
        },
        {
          "id": "patchbay-vcmix-out",
          "name": "VC Mix",
          "type": "output",
          "signal": "unknown",
          "pass": ["patchbay-mix1-in", "patchbay-mix2-in"],
          "top": 298,
          "left": 1220
        },

        {
          "id": "patchbay-mult-in",
          "name": "Mult",
          "type": "input",
          "signal": "unknown",
          "top": 353,
          "left": 1054
        },
        {
          "id": "patchbay-mult1-out",
          "name": "Mult 1",
          "type": "output",
          "signal": "unknown",
          "pass": ["patchbay-mult-in"],
          "top": 353,
          "left": 1109
        },
        {
          "id": "patchbay-mult2-out",
          "name": "Mult 2",
          "type": "output",
          "signal": "unknown",
          "pass": ["patchbay-mult-in"],
          "top": 353,
          "left": 1164
        },
        {
          "id": "patchbay-assign-out",
          "name": "Assign",
          "type": "output",
          "signal": "unknown",
          "top": 353,
          "left": 1220
        },

        {
          "id": "patchbay-gate-in",
          "name": "Gate",
          "type": "input",
          "signal": "gate",
          "top": 407,
          "left": 1054
        },
        {
          "id": "patchbay-eg-out",
          "name": "EG",
          "type": "output",
          "signal": "modulation",
          "top": 407,
          "left": 1109
        },
        {
          "id": "patchbay-kb-out",
          "name": "KB",
          "type": "output",
          "signal": "pitch",
          "top": 407,
          "left": 1164
        },
        {
          "id": "patchbay-gate-out",
          "name": "Gate",
          "type": "output",
          "signal": "gate",
          "top": 407,
          "left": 1220
        },

        {
          "id": "patchbay-tempo-in",
          "name": "Tempo",
          "type": "input",
          "signal": "modulation",
          "top": 462,
          "left": 1054
        },
        {
          "id": "patchbay-runstop-in",
          "name": "Run / Stop",
          "type": "input",
          "signal": "gate",
          "top": 462,
          "left": 1109
        },
        {
          "id": "patchbay-reset-in",
          "name": "Reset",
          "type": "input",
          "signal": "gate",
          "top": 462,
          "left": 1164
        },
        {
          "id": "patchbay-hold-in",
          "name": "Hold",
          "type": "input",
          "signal": "gate",
          "top": 462,
          "left": 1220
        },
      ]
    }
  ]
}
