export default ['$timeout', 'AppSettings', 'PatchService', 'UserService',
  function($timeout, AppSettings, PatchService, UserService) {
    return {
      restrict: 'E',
      templateUrl: 'patch-device.html',
      replace: true,
      scope: {
        device: '=',
        instance: '=',
        role: '@',
      },
      controller: ['$scope', function($scope){

        $scope.deviceStyle = function(){
          let width;
          let height;

          const display = UserService.getSetting('deviceSize');
          if(display === 'proportional' || $scope.device.id === 'patchbay') {
            width = ($scope.device.width * $scope.device.scale) * AppSettings.displayScale;
            height = ($scope.device.height * $scope.device.scale) * AppSettings.displayScale;
          }
          else {
            width = AppSettings.fixedWidth;
            height = $scope.device.height * (AppSettings.fixedWidth / $scope.device.width);
          }

          return {
            width: width,
            height: height,
          };
        }

        $scope.controlStyle = function(control){
          let top;
          let left;
          let scale;

          const display = UserService.getSetting('deviceSize');
          if(display === 'proportional' || $scope.device.id === 'patchbay') {
            top = (control.top * $scope.device.scale) * AppSettings.displayScale;
            left = (control.left * $scope.device.scale) * AppSettings.displayScale;
            scale = $scope.device.scale * AppSettings.displayScale;
          }
          else {
            const factor = (AppSettings.fixedWidth / $scope.device.width);
            top = (control.top * factor) * AppSettings.displayScale;
            left = (control.left * factor) * AppSettings.displayScale;
            scale = factor;
          }

          return { 
            top: top, 
            left: left, 
            transform: `translate(-50%, -50%) scale(${scale})`,
          };
        }

        $scope.actions = function(){
          $scope.showactions = true;
          $timeout(function(){
            $scope.showactions = false;
          }, 5000);
        }

        $scope.up = function($event){
          $event.stopPropagation();
          $scope.$root.$broadcast('device-up', $scope.instance.slug);
        }

        $scope.down = function($event){
          $event.stopPropagation();
          $scope.$root.$broadcast('device-down', $scope.instance.slug);
        }

        $scope.remove = function($event){
          $event.stopPropagation();
          $scope.$root.$broadcast('device-remove', $scope.instance.slug);
        }

        // Release cable over no socket
        $scope.mouseup = function(){
          PatchService.nowPatching(null);
          $scope.$root.$broadcast('patching', null);
        }
      }]
    };
  }
]
