import angular from 'angular';

import patchService from './patch.service';
import patchDirective from './patch.directive';
import patchHeaderDirective from './patch-header.directive';
import patchDevicesDirective from './patch-devices.directive';
import patchDeviceDirective from './patch-device.directive';
import patchCablesDirective from './patch-cables.directive';
import chooseDeviceController from './choose-device.controller';
import savePatchController from './save-patch.controller';

const module = angular.module('patchModule', [])
  .service('PatchService', patchService)
  .directive('patch', patchDirective)
  .directive('patchHeader', patchHeaderDirective)
  .directive('patchDevices', patchDevicesDirective)
  .directive('patchDevice', patchDeviceDirective)
  .directive('patchCables', patchCablesDirective)
  .controller('ChooseDeviceController', chooseDeviceController)
  .controller('SavePatchController', savePatchController);

export default module.name;
