import angular from 'angular';

export default ['$scope', 'AppSettings', 'UserService', 'close',
  function($scope, AppSettings, UserService, close) {
    
    $scope.cableColors = AppSettings.cableColors;

    $scope.formdata = {
      cableType: UserService.getSetting('cableType'),
      cableColor: UserService.getSetting('cableColor'),
      deviceSize: UserService.getSetting('deviceSize'),
    };

    $scope.init = function(){
      $scope.formdataClean = angular.copy($scope.formdata);
      $scope.$watch('formdata', function(){
        $scope.canSubmit = !angular.equals($scope.formdata, $scope.formdataClean);
      }, true);
    }

    $scope.submit = function(){
      Object.keys($scope.formdata).map(function(key){
        UserService.setSetting(key, $scope.formdata[key]);
      });
      close(true);
    }

    $scope.cancel = function(){
      close(false);
    }

    $scope.init();

  }
]
