import angular from 'angular';
import moment from 'moment';

export default ['$q', '$scope', 'UserService', 'CommonService', 'close',
  function($q, $scope, UserService, CommonService, close) {

    $scope.status = null;
    $scope.error = null;
    $scope.success = new URLSearchParams(window.location.search).get('success');
    $scope.selectedPlan = null;
    $scope.user = null;
    $scope.config = null;

    $scope.formdata = {
      plan: '',
      email: '',
      username: '',
      subscribe: '',
      password: '',
      passwordNew: '',
    }

    $scope.init = function(){
      $scope.status = 'loading';

      UserService.getUser()
      .then(function(result){
        if(result.success) {
          $scope.user = result.data;
          $scope.profile();
        }
        else {
          close();
          $scope.$root.$broadcast('signin');
        }
      });
    }

    $scope.profile = function(){
      $q.all([
        UserService.getPlans(),
        CommonService.getConfig()
      ])
      .then(function(result){
        $scope.status = null;
        $scope.plans = result[0].data;
        $scope.$watch('formdata.plan', function(value){
          $scope.plans.forEach(function(item){
            if(item.id == value) {
              $scope.selectedPlan = item;
            }
          });
        });

        $scope.config = result[1].data;
        $scope.paymentReturn = $scope.config.upgrade_return;
        document.querySelector('#payment').action = $scope.config.payment_url;
      });

      $scope.formdata.plan = $scope.user.plan.id;
      $scope.formdata.email = $scope.user.email;
      $scope.formdata.username = $scope.user.username;
      $scope.formdataClean = angular.copy($scope.formdata);

      $scope.$watch('formdata', function(){
        $scope.canSubmit = $scope.userProfileForm.$valid && !angular.equals($scope.formdata, $scope.formdataClean);
      }, true);

      if($scope.user.plan_expires != '0') {
        $scope.expires = moment.unix($scope.user.plan_expires).format('MMMM Do YYYY');
        const now = parseInt(moment().format('X'));
        const then = parseInt($scope.user.plan_expires);

        if(now < then) {
          $scope.plan = $scope.user.plan;
          $scope.choosePlan = false;
          $scope.userType = 'paid';
        }
        else {
          $scope.plan = $scope.user.plan_last;
          $scope.choosePlan = true;
          $scope.userType = 'expired';
        }
      }
      else {
        $scope.plan = $scope.user.plan;
        $scope.choosePlan = true;
        $scope.userType = 'free';
      }

      $scope.$watchGroup(['error', 'success'], function(values){
        if(values[0] || values[1]) {
          setTimeout(function(){
            document.querySelector('.form__message').scrollIntoView({ behavior: 'smooth' });
          });
        }
      });
    }

    $scope.submit = function(){
      $scope.error = false;
      $scope.success = false;
      $scope.status = 'submitting';

      const userData = {
        email: $scope.formdata.email,
        username: $scope.formdata.username,
        password: $scope.formdata.password,
        password_new: $scope.formdata.passwordNew,
      };

      UserService.updateUser(userData)
      .then(function(result){
        if(result.success) {
          if($scope.user.plan.id != $scope.selectedPlan.id && parseFloat($scope.selectedPlan.price) > 0) {
            const postbackData = {
              user: result.data.id,
              plan: $scope.selectedPlan.id,
            };

            $scope.postbackData = JSON.stringify(postbackData);
            setTimeout(function(){
              document.querySelector('#payment').submit();
            }, 333);
          }
          else {
            $scope.status = null;
            $scope.success = true;
          }
        }
        else {
          $scope.status = null;
          $scope.error = result.error;
        }
      });
    }

    $scope.password = function(){
      $scope.changePassword = !$scope.changePassword;
      if($scope.changePassword) {
        setTimeout(function(){
          document.querySelector('#password').scrollIntoView({ behavior: 'smooth' });
        });
      }
    }

    $scope.cancel = function(){
      close();
      history.replaceState(null, null, location.pathname);
    }

    $scope.init();
    history.replaceState(null, null, location.pathname);

  }
]
