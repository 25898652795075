export default {
  "name": "DFAM",
  "id": "dfam",
  "vendor": "Moog Music",
  "width": 1273,
  "height": 544,
  "scale": 1,
  "images": {
    "panel": "dfam-panel.png",
    "thumbnail": "dfam-thumbnail.png",
    "selector": "dfam-selector.png"
  },
  "notes": "The Drummer From Another Mother is a semi-modular analogue drum synthesizer from Moog Music, with a built-in sequencer.",
  "link": "https://www.moogmusic.com/products/dfam-drummer-another-mother",
  "sections": [
    {
      "id": "vco12",
      "name": "VCO 1 & 2",
      "controls": [
        {
          "id": "vco12-decay",
          "name": "VCO Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 106,
          "left": 97,
          "init": 180
        },
        {
          "id": "vco12-vco1-eg",
          "name": "VCO 1 EG Amount",
          "type": "knob",
          "class": "knob-1",
          "top": 106,
          "left": 271,
          "init": 30
        },
        {
          "id": "vco12-vco1-freq",
          "name": "VCO 1 Frequency",
          "type": "knob",
          "class": "knob-1",
          "top": 106,
          "left": 389,
          "init": 180
        },
        {
          "id": "vco12-vco1-wave",
          "name": "VCO 1 Wave",
          "type": "switch",
          "class": "switch-1",
          "values": ["Triangle", "Square"],
          "top": 106,
          "left": 477,
          "init": 0
        },
        {
          "id": "vco12-fm",
          "name": "1-2 FM Amount",
          "type": "knob",
          "class": "knob-1",
          "top": 236,
          "left": 97,
          "init": 30
        },
        {
          "id": "vco12-vco2-eg",
          "name": "VCO 2 EG Amount",
          "type": "knob",
          "class": "knob-1",
          "top": 236,
          "left": 271,
          "init": 30
        },
        {
          "id": "vco12-vco2-freq",
          "name": "VCO 2 Frequency",
          "type": "knob",
          "class": "knob-1",
          "top": 236,
          "left": 389,
          "init": 180
        },
        {
          "id": "vco12-vco2-wave",
          "name": "VCO 2 Wave",
          "type": "switch",
          "class": "switch-1",
          "values": ["Triangle", "Square"],
          "top": 236,
          "left": 477,
          "init": 0
        },
        {
          "id": "vco12-seqpitch",
          "name": "Seq Pitch Mod",
          "type": "switch",
          "class": "switch-2",
          "values": ["VCO 2", "Off", "VCO 1&2"],
          "top": 106,
          "left": 186,
          "init": 2
        },
        {
          "id": "vco12-sync",
          "name": "Hard Sync",
          "type": "switch",
          "class": "switch-1",
          "values": ["Off", "On"],
          "top": 236,
          "left": 186,
          "init": 0
        }
      ],
      "sockets": [
      ]
    },

    {
      "id": "mixer",
      "name": "Mixer",
      "controls": [
        {
          "id": "mixer-vco1",
          "name": "VCO 1 Level",
          "type": "knob",
          "class": "knob-2",
          "top": 84,
          "left": 554,
          "init": 180
        },
        {
          "id": "mixer-noise",
          "name": "Noise / Ext Level",
          "type": "knob",
          "class": "knob-2",
          "top": 172,
          "left": 554,
          "init": 30
        },
        {
          "id": "mixer-vco2",
          "name": "VCO 2 Level",
          "type": "knob",
          "class": "knob-2",
          "top": 260,
          "left": 554,
          "init": 30
        }
      ]
    },

    {
      "id": "vcf",
      "name": "VCF",
      "controls": [
        {
          "id": "vcf-mode",
          "name": "VCF",
          "type": "switch",
          "class": "switch-1",
          "values": ["LP", "HP"],
          "top": 106,
          "left": 636,
          "init": 0
        },
        {
          "id": "vcf-cutoff",
          "name": "Cutoff",
          "type": "knob",
          "class": "knob-1",
          "top": 106,
          "left": 727,
          "init": 330
        },
        {
          "id": "vcf-reso",
          "name": "Resonance",
          "type": "knob",
          "class": "knob-1",
          "top": 106,
          "left": 845,
          "init": 30
        },
        {
          "id": "vcf-decay",
          "name": "VCF Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 236,
          "left": 662,
          "init": 180
        },
        {
          "id": "vcf-eg",
          "name": "VCF EG Amount",
          "type": "knob",
          "class": "knob-1",
          "top": 236,
          "left": 780,
          "init": 180
        },
        {
          "id": "vcf-noise",
          "name": "Noise / VCF Mod",
          "type": "knob",
          "class": "knob-1",
          "top": 236,
          "left": 899,
          "init": 30
        }
      ]
    },

    {
      "id": "vca",
      "name": "VCA",
      "controls": [
        {
          "id": "vca-eg",
          "name": "VCA EG",
          "type": "switch",
          "class": "switch-1",
          "values": ["Slow", "Fast"],
          "top": 106,
          "left": 936,
          "init": 0
        },
        {
          "id": "vca-volume",
          "name": "Volume",
          "type": "knob",
          "class": "knob-1",
          "top": 106,
          "left": 1025,
          "init": 180
        },
        {
          "id": "vca-decay",
          "name": "Decay",
          "type": "knob",
          "class": "knob-1",
          "top": 236,
          "left": 1025,
          "init": 180
        }
      ]
    },

    {
      "id": "seq",
      "name": "Sequencer",
      "controls": [
        {
          "id": "seq-tempo",
          "name": "Tempo",
          "type": "knob",
          "class": "knob-1",
          "top": 364,
          "left": 209,
          "init": 180
        },

        {
          "id": "seq-pitch1",
          "name": "Step 1 Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 346,
          "init": 180
        },
        {
          "id": "seq-pitch2",
          "name": "Step 2 Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 443,
          "init": 180
        },
        {
          "id": "seq-pitch3",
          "name": "Step 3 Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 540,
          "init": 180
        },
        {
          "id": "seq-pitch4",
          "name": "Step 4 Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 638,
          "init": 180
        },
        {
          "id": "seq-pitch5",
          "name": "Step 5 Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 735,
          "init": 180
        },
        {
          "id": "seq-pitch6",
          "name": "Step 6 Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 832,
          "init": 180
        },
        {
          "id": "seq-pitch7",
          "name": "Step 7 Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 929,
          "init": 180
        },
        {
          "id": "seq-pitch8",
          "name": "Step 8 Pitch",
          "type": "knob",
          "class": "knob-2",
          "top": 352,
          "left": 1027,
          "init": 180
        },

        {
          "id": "seq-vel1",
          "name": "Step 1 Velocity",
          "type": "knob",
          "class": "knob-3",
          "top": 448,
          "left": 346,
          "init": 30
        },
        {
          "id": "seq-vel2",
          "name": "Step 2 Velocity",
          "type": "knob",
          "class": "knob-3",
          "top": 448,
          "left": 443,
          "init": 30
        },
        {
          "id": "seq-vel3",
          "name": "Step 3 Velocity",
          "type": "knob",
          "class": "knob-3",
          "top": 448,
          "left": 540,
          "init": 30
        },
        {
          "id": "seq-vel4",
          "name": "Step 4 Velocity",
          "type": "knob",
          "class": "knob-3",
          "top": 448,
          "left": 638,
          "init": 30
        },
        {
          "id": "seq-vel5",
          "name": "Step 5 Velocity",
          "type": "knob",
          "class": "knob-3",
          "top": 448,
          "left": 735,
          "init": 30
        },
        {
          "id": "seq-vel6",
          "name": "Step 6 Velocity",
          "type": "knob",
          "class": "knob-3",
          "top": 448,
          "left": 832,
          "init": 30
        },
        {
          "id": "seq-vel7",
          "name": "Step 7 Velocity",
          "type": "knob",
          "class": "knob-3",
          "top": 448,
          "left": 929,
          "init": 30
        },
        {
          "id": "seq-vel8",
          "name": "Step 8 Velocity",
          "type": "knob",
          "class": "knob-3",
          "top": 448,
          "left": 1027,
          "init": 30
        }
      ]
    },

    {
      "id": "patchbay",
      "name": "Patchbay",
      "controls": [],
      "sockets": [
        {
          "id": "patchbay-trigger-in",
          "name": "Trigger",
          "type": "input",
          "signal": "trigger",
          "top": 78,
          "left": 1109
        },
        {
          "id": "patchbay-vcacv-in",
          "name": "VCA CV",
          "type": "input",
          "signal": "modulation",
          "top": 78,
          "left": 1164
        },
        {
          "id": "patchbay-vca-out",
          "name": "VCA",
          "type": "output",
          "signal": "audio",
          "top": 78,
          "left": 1220
        },
        {
          "id": "patchbay-velocity-in",
          "name": "Velocity",
          "type": "input",
          "signal": "modulation",
          "top": 133,
          "left": 1109
        },
        {
          "id": "patchbay-vcadecay-in",
          "name": "VCA Decay",
          "type": "input",
          "signal": "modulation",
          "top": 133,
          "left": 1164
        },
        {
          "id": "patchbay-vcaeg-out",
          "name": "VCA EG",
          "type": "output",
          "signal": "modulation",
          "top": 133,
          "left": 1220
        },
        {
          "id": "patchbay-extaudio-in",
          "name": "Ext Audio",
          "type": "input",
          "signal": "audio",
          "top": 188,
          "left": 1109
        },
        {
          "id": "patchbay-vcfdecay-in",
          "name": "VCF Decay",
          "type": "input",
          "signal": "modulation",
          "top": 188,
          "left": 1164
        },
        {
          "id": "patchbay-vcfeg-out",
          "name": "VCF EG",
          "type": "output",
          "signal": "modulation",
          "top": 188,
          "left": 1220
        },
        {
          "id": "patchbay-noiselevel-in",
          "name": "Noise Level",
          "type": "input",
          "signal": "modulation",
          "top": 243,
          "left": 1109
        },
        {
          "id": "patchbay-vcodecay-in",
          "name": "VCO Decay",
          "type": "input",
          "signal": "modulation",
          "top": 243,
          "left": 1164
        },
        {
          "id": "patchbay-vcoeg-out",
          "name": "VCO EG",
          "type": "output",
          "signal": "modulation",
          "top": 243,
          "left": 1220
        },
        {
          "id": "patchbay-cvfmod-in",
          "name": "VCF Mod",
          "type": "input",
          "signal": "modulation",
          "top": 298,
          "left": 1109
        },
        {
          "id": "patchbay-vco1cv-in",
          "name": "VCO 1 CV",
          "type": "input",
          "signal": "modulation",
          "top": 298,
          "left": 1164
        },
        {
          "id": "patchbay-vco1-out",
          "name": "VCO 1",
          "type": "output",
          "signal": "audio",
          "top": 298,
          "left": 1220
        },
        {
          "id": "patchbay-fmamt-in",
          "name": "1 - 2 FM Amt",
          "type": "input",
          "signal": "modulation",
          "top": 353,
          "left": 1109
        },
        {
          "id": "patchbay-vco2cv-in",
          "name": "VCO 2 CV",
          "type": "input",
          "signal": "modulation",
          "top": 353,
          "left": 1164
        },
        {
          "id": "patchbay-vco2-out",
          "name": "VCO 2",
          "type": "output",
          "signal": "audio",
          "top": 353,
          "left": 1220
        },
        {
          "id": "patchbay-tempo-in",
          "name": "Tempo",
          "type": "input",
          "signal": "modulation",
          "top": 407,
          "left": 1109
        },
        {
          "id": "patchbay-runstop-in",
          "name": "Run / Stop",
          "type": "input",
          "signal": "gate",
          "top": 407,
          "left": 1164
        },
        {
          "id": "patchbay-adv-in",
          "name": "Adv / Clock",
          "type": "input",
          "signal": "clock",
          "top": 407,
          "left": 1220
        },
        {
          "id": "patchbay-trig-out",
          "name": "Trigger Out",
          "type": "output",
          "signal": "trigger",
          "top": 462,
          "left": 1109
        },
        {
          "id": "patchbay-velo-out",
          "name": "Velocity Out",
          "type": "output",
          "signal": "modulation",
          "top": 462,
          "left": 1164
        },
        {
          "id": "patchbay-pitch-out",
          "name": "Pitch Out",
          "type": "output",
          "signal": "pitch",
          "top": 462,
          "left": 1220
        }
      ]
    }

  ]
}
