import angular from 'angular';

export default [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'knob.html',
      replace: true,
      scope: {
        model: '=',
        config: '=',
      },
      controller: ['$scope', '$element', function($scope, $element){

        $scope.delta = null;
        $scope.oldPos = null;
        $scope.options = Object.assign({
          //sensitivity: 1,
          range: [30, 330],
          step: 1,
        }, $scope.config);

        // Snap step amount to discreet values
        if($scope.options.values) {
          $scope.options.step = ($scope.options.range[1] - $scope.options.range[0]) / ($scope.options.values.length - 1);
        }

        $scope.init = function(){
          $scope.el = $element[0];
          $scope.el.addEventListener('mousedown', $scope.onStart);
          $scope.el.addEventListener('touchstart', $scope.onStart);
        }

        $scope.onStart = function(event) {
          event.preventDefault();
          event.stopPropagation();
          
          $scope.oldPos = event.pageY;
          $scope.delta = 0;
          angular.element(document.body).addClass('-dragging');

          window.addEventListener('mousemove', $scope.onMove);
          window.addEventListener('touchmove', $scope.onMove);
          window.addEventListener('mouseup', $scope.onStop);
          window.addEventListener('touchend', $scope.onStop);
        }

        $scope.onMove = function(event) {
          event.preventDefault();

          // Quantise mouse move delta to step size
          const newPos = event.pageY;
          $scope.delta = Math.round(($scope.oldPos - newPos) / $scope.options.step) * $scope.options.step;
          
          if(Math.abs($scope.delta) >= $scope.options.step) {
            $scope.oldPos = event.pageY;
            const newValue = Math.round($scope.model + $scope.delta);
            let degrees;
            
            if(newValue > $scope.model) {
              if(newValue < $scope.options.range[1]) {
                degrees = newValue;
              }
              else {
                degrees = $scope.options.range[1];
              }
            }
            else {
              if(newValue > $scope.options.range[0]) {
                degrees = newValue;
              }
              else {
                degrees = $scope.options.range[0];
              }
            }

            $scope.$apply(function(){
              $scope.model = degrees;
            });
          }
        }

        $scope.onStop = function(){
          angular.element(document.body).removeClass('-dragging');
          window.removeEventListener('mousemove', $scope.onMove);
          window.removeEventListener('touchmove', $scope.onMove);
          window.removeEventListener('mouseup', $scope.onStop);
          window.removeEventListener('touchend', $scope.onStop);
        }

        $scope.click = function($event){
          $event.stopPropagation();
        }

        $scope.init();
      }]
    };
  }
]
