import angular from 'angular';

export default [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'slider.html',
      replace: true,
      scope: {
        model: '=',
        config: '=',
      },
      controller: ['$scope', '$element', function($scope, $element){
        
        $scope.delta = null;
        $scope.oldPos = null;
        $scope.options = Object.assign({
          range: [0, 100],
          step: 1,
          direction: 'v',
        }, $scope.config);

        $scope.init = function(){
          $scope.el = $element[0].querySelector('*');
          $scope.el.addEventListener('mousedown', $scope.onStart);
          $scope.el.addEventListener('touchstart', $scope.onStart);

          if($scope.options.direction === 'h') {
            $scope.eventKey = 'pageX';
            $scope.styleKey = 'left';
          }
          else {
            $scope.eventKey = 'pageY';
            $scope.styleKey = 'bottom';
          }

          $scope.$watch('model', function(value){
            requestAnimationFrame(function(){
              $scope.el.style[$scope.styleKey] = `${value}%`;
            });
          });
        }

        $scope.onStart = function(event) {
          event.preventDefault();
          event.stopPropagation();
          
          $scope.oldPos = event[$scope.eventKey];
          $scope.delta = 0;
          angular.element(document.body).addClass('-dragging');

          window.addEventListener('mousemove', $scope.onMove);
          window.addEventListener('touchmove', $scope.onMove);
          window.addEventListener('mouseup', $scope.onStop);
          window.addEventListener('touchend', $scope.onStop);
        }

        $scope.onMove = function(event) {
          event.preventDefault();

          // Quantise mouse move delta to step size
          const newPos = event[$scope.eventKey];
          $scope.delta = Math.round(($scope.oldPos - newPos) / $scope.options.step) * $scope.options.step;
          
          if(Math.abs($scope.delta) >= $scope.options.step) {
            $scope.oldPos = event[$scope.eventKey];

            let newValue;
            if($scope.options.direction === 'h') {
              newValue = Math.round($scope.model - $scope.delta);
            }
            else {
              newValue = Math.round($scope.model + $scope.delta);
            }
            
            let degrees;
            
            if(newValue > $scope.model) {
              if(newValue < $scope.options.range[1]) {
                degrees = newValue;
              }
              else {
                degrees = $scope.options.range[1];
              }
            }
            else {
              if(newValue > $scope.options.range[0]) {
                degrees = newValue;
              }
              else {
                degrees = $scope.options.range[0];
              }
            }

            $scope.$apply(function(){
              $scope.model = degrees;
            });
          }
        }

        $scope.onStop = function(){
          angular.element(document.body).removeClass('-dragging');
          window.removeEventListener('mousemove', $scope.onMove);
          window.removeEventListener('touchmove', $scope.onMove);
          window.removeEventListener('mouseup', $scope.onStop);
          window.removeEventListener('touchend', $scope.onStop);
        }

        $scope.scaleRange = function(val, x1, x2, y1, y2){
          return y1 + (val - x1) * (y2 - y1) / (x2 - x1);
        }

        $scope.valueToStyle = function(val){
          return $scope.scaleRange(val, $scope.options.value[0], $scope.options.value[1], $scope.options.range[0], $scope.options.range[1]);
        }

        $scope.styleToValue = function(val){
          return $scope.scaleRange(val, $scope.options.range[0], $scope.options.range[1], $scope.options.value[0], $scope.options.value[1]);
        }

        $scope.click = function($event){
          $event.stopPropagation();
        }

        $scope.init();

      }]
    };
  }
]
