export default ['$q', '$http',
  function($q, $http){
    return {

      getConfig: function(){
        if(typeof(window.APP.config) === 'undefined') {
          return $http.get('/api/system/config', {
            cache: true,
          })
          .then(function success(response){
            return response.data;
          }, 
          function error(error){
            return {
              success: false,
              error: error.config.url,
            }
          });
        }
        else {
          const deferral = $q.defer(); 
          deferral.resolve({ 
            success: true,
            data: window.APP.config,
          });
          return deferral.promise;
        }
      },

    };
  }
]

