import angular from 'angular';

import searchService from './search.service';
import advancedSearchController from './advanced-search.controller';
import searchDirective from './search.directive';
import searchHeaderDirective from './search-header.directive';
import resultItemDirective from './result-item.directive';

const module = angular.module('searchModule', [])
  .service('SearchService', searchService)
  .directive('search', searchDirective)
  .directive('searchHeader', searchHeaderDirective)
  .directive('resultItem', resultItemDirective)
  .controller('AdvancedSearchController', advancedSearchController);

export default module.name;
