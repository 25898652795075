export default ['AppSettings', 'PatchService',
  function(AppSettings, PatchService) {
    return {
      restrict: 'E',
      templateUrl: 'socket.html',
      replace: true,
      scope: {
        slug: '@',
        device: '@',
        config: '=',
        destinations: '=',
      },
      controller: ['$scope', function($scope){
        $scope.status = null;

        $scope.makeConnection = function(canCancel){
          const src = PatchService.nowPatching();

          if(src) {
            // Cancel patching
            if(src.slug === $scope.slug) {
              if(canCancel) {
                $scope.endPatching();
              }
            }
            // Finish patching
            else if(src.config.type !== $scope.config.type || (src.config.type === 'input' && $scope.config.type === 'input' && AppSettings.chainSockets)) {
              const data = [
                {
                  slug: src.slug,
                  device: src.device,
                  config: src.config,
                },
                { 
                  slug: $scope.slug,
                  device: $scope.device,
                  config: $scope.config,
                }
              ];

              if($scope.config.type === 'output' || src.config.type === 'input') {
                data.reverse();
              }

              $scope.endPatching();
              $scope.$root.$broadcast('add-cable', data);
            }
          }
        }

        $scope.endPatching = function(){
          PatchService.nowPatching(null);
          $scope.$root.$broadcast('patching', null);
        }

        $scope.mousedown = function($event){
          $event.stopPropagation();
          const src = PatchService.nowPatching();

          // Start patching
          if(!src) {
            const data = {
              slug: $scope.slug,
              device: $scope.device,
              config: $scope.config,
            };

            PatchService.nowPatching(data);
            $scope.$root.$broadcast('patching', data);
          }
          else {
            $scope.makeConnection(true);
          }
        }

        $scope.mouseup = function($event){
          $event.stopPropagation();
          $scope.makeConnection(false);
        }

        $scope.dblclick = function($event){
          $event.stopPropagation();
          const data = $scope.slug;
          $scope.$root.$broadcast('remove-cable', data);
        }

        $scope.click = function(){
        }

        $scope.$on('patching', function($event, data){
          if(!data) {
            $scope.status = null;
          }
          else if($scope.slug === data.slug) {
            $scope.status = 'source';
          }
          else if(data.config.type !== $scope.config.type) {
            $scope.status = 'destination';
          }
          else if(data.config.type === 'input' && $scope.config.type === 'input' && AppSettings.chainSockets) {
            $scope.status = 'destination';
          }
        });
      }]
    };
  }
]
