
export default ['$q', '$scope', 'UserService', 'CommonService', 'close',
  function($q, $scope, UserService, CommonService, close) {

    $scope.status = null;
    $scope.error = null;
    $scope.success = new URLSearchParams(window.location.search).get('success');
    $scope.selectedPlan = null;
    
    $scope.formdata = {
      id: '',
      email: '',
      username: '',
      password: '',
      plan: '',
      subscribe: 1,
      terms: 0,
    }

    $scope.init = function(){
      $scope.$watch('formdata', function(){
        $scope.canSubmit = $scope.userRegisterForm.$valid;
      }, true);

      $scope.status = 'loading';
      $q.all([
        CommonService.getConfig(),
        UserService.getPlans(),
      ])
      .then(function(result){
        $scope.status = null;
        $scope.config = result[0].data;
        $scope.plans = result[1].data;
        $scope.paymentReturn = $scope.config.register_return;
        document.querySelector('#payment').action = $scope.config.payment_url;

        $scope.$watch('formdata.plan', function(value){
          $scope.plans.forEach(function(item){
            if(item.id == value) {
              $scope.selectedPlan = item;
            }
          });
        });
      });

      $scope.$watchGroup(['error', 'success'], function(values){
        if(values.reduce((a, b) => a + b, 0)) {
          document.querySelector('#modal__scroll').scrollTo(0, 9999);
        }
      });
    }

    $scope.submit = function(){
      $scope.error = null;
      $scope.success = null;
      $scope.status = 'submitting';

      const userData = {
        email: $scope.formdata.email,
        username: $scope.formdata.username,
        password: $scope.formdata.password,
        subscribe: $scope.formdata.subscribe,
        terms: $scope.formdata.terms,
      };

      UserService.register(userData).then(function(result){
        if(result.success) {
          if(parseFloat($scope.selectedPlan.price) > 0) {
            const postbackData = {
              user: result.data.id,
              plan: $scope.selectedPlan.id,
            };

            $scope.postbackData = JSON.stringify(postbackData);
            setTimeout(function(){
              document.querySelector('#payment').submit();
            }, 100);
          }
          else {
            $scope.status = null;
            $scope.success = true;
          }
        }
        else {
          $scope.status = null;
          $scope.error = result.error;
        }
      });
    }

    $scope.signin = function(){
      close(true);
      $scope.$root.$broadcast('signin');
    }

    $scope.cancel = function(){
      close(false);
      history.replaceState(null, null, location.pathname);
    }

    history.replaceState(null, null, location.pathname);
    if(!$scope.success) {
      $scope.init();
    }

  }
]
