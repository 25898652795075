import angular from 'angular';

export default ['$q', 'AppSettings', 'CommonService', 'UserService', 'PatchService', 'ModalService',
  function($q, AppSettings, CommonService, UserService, PatchService, ModalService){
    return {
      restrict: 'E',
      templateUrl: 'patch.html',
      replace: true,
      scope: {
        id: '@',
      },
      controller: ['$scope', function($scope){

        $scope.status = null;
        $scope.error = null;
        $scope.success = null;

        $scope.user = null;
        $scope.devices = null;
        $scope.patch = null;
        $scope.permissions = {};
        $scope.passthrough = {};
        $scope.els = {};
        $scope.destinations = [];

        $scope.scrollbarWidth = 20 //TODO;
        $scope.minWidth = 960;
        $scope.resizeTimeout = null;
        $scope.cableGroup = 0;

        $scope.init = function(){
          $scope.status = 'loading';

          $q.all([
            CommonService.getConfig(),
            UserService.getUser(),
            PatchService.getDevices(),
            PatchService.getCategories(),
            PatchService.getPatch($scope.id)
          ])
          .then(function(result){
            $scope.status = null;
            $scope.config = result[0].data;
            $scope.user = result[1].data;
            $scope.devices = result[2].data;
            $scope.categories = result[3].data;

            if(result[4].success) {
              $scope.patch = result[4].data;

              if(!$scope.patch.id) {
                if(sessionStorage.getItem('patch')) {
                  const stored = JSON.parse(sessionStorage.getItem('patch'));
                  sessionStorage.removeItem('patch');
                  $scope.patch = stored.data;
                  $scope.setupPermissions();
                  
                  if(stored.action === 'save' && $scope.user) {
                    $scope.save();
                  }
                }
                else {
                  if(AppSettings.alwaysAddPatchbay) {
                    $scope.addDevice(PatchService.getDevice('patchbay'));
                  }

                  const device = new URLSearchParams(window.location.search).get('device');
                  if(device && typeof($scope.devices[device]) !== 'undefined') {
                    $scope.addDevice($scope.devices[device]);
                  }
                }
              }

              $scope.setupPermissions();
              $scope.setupInterface();
              $scope.setupCables();
              $scope.setupDevices();
            }
            else {
              $scope.error = result[4].error;
            }
          });
        }

        /** Create a lookup table of all passthrough ports based on patch devices
         */
        $scope.setupDevices = function(){
          $scope.passthrough = {};

          Object.values($scope.patch.devices).map(function(device){
            Object.values($scope.devices[device.id].lookup).map(function(item){
              if(typeof(item.pass) !== 'undefined') {
                $scope.passthrough[`${device.slug}.${item.id}`] = {
                  signal: item.signal,
                  sockets: item.pass.map((value) => {
                    return `${device.slug}.${value}`;
                  })
                };
              }
            });
          });
        }

        /** Set up user permissions based on login status & membership plan
         */
        $scope.setupPermissions = function(){
          if($scope.user) {
            $scope.permissions = {
              create: !$scope.user.plan.permissions.max_patches || parseInt($scope.user.patch_count) < $scope.user.plan.permissions.max_patches ? true : false,
              save: !$scope.patch.id || $scope.patch.user_id == $scope.user.id,
              copy: $scope.patch.id && $scope.patch.user_id == $scope.user.id ? true : false,
              delete: $scope.patch.id && $scope.patch.user_id == $scope.user.id ? true : false,
              like: $scope.patch.id ? true : false,
              device: !$scope.user.plan.permissions.max_devices || $scope.deviceCount() < $scope.user.plan.permissions.max_devices || !$scope.deviceCount('patchbay'),
              private: $scope.user.plan.permissions.private_patches || ($scope.patch.id && $scope.user.plan_last.permissions.private_patches) ? true : false,
              media: $scope.user.plan.permissions.patch_media || ($scope.patch.id && $scope.user.plan_last.permissions.patch_media) ? true : false,
            };
          }
          else {
            $scope.permissions = {
              create: true,
              device: !$scope.patch.id && $scope.patch.devices.length < 5,
              save: !$scope.patch.id,
              copy: false,
              delete: false,
              like: false,
              private: true,
              media: true,
            };
          }
        }

        /** Set up interface sizing
         */
        $scope.setupInterface = function(){

          $scope.els = {
            outer: document.querySelector('[data-patch-outer]'),
            inner: document.querySelector('[data-patch-inner]'),
            cables: document.querySelector('[data-patch-cables]'),
          };

          let width = 0;
          let height = 0;

          const display = UserService.getSetting('deviceSize');
          if(display === 'fixed') {
            width = AppSettings.fixedWidth;
          }

          $scope.patch.devices.map(function(device){
            if(display === 'proportional' || device.id === 'patchbay') {
              height+= $scope.devices[device.id].height * $scope.devices[device.id].scale * AppSettings.displayScale;
              if($scope.devices[device.id].width * $scope.devices[device.id].scale * AppSettings.displayScale > width) {
                width = $scope.devices[device.id].width * $scope.devices[device.id].scale * AppSettings.displayScale;
              }
            }
            else {
              height+= $scope.devices[device.id].height * (AppSettings.fixedWidth / $scope.devices[device.id].width);
            }
          });
          
          height += (30 * ($scope.patch.devices.length - 1));

          $scope.els.inner.style.width = width;
          $scope.els.inner.style.height = height;
          $scope.els.cables.width = width * AppSettings.dpiScale;
          $scope.els.cables.height = height * AppSettings.dpiScale;

          $scope.scaleInterface();
          window.addEventListener('resize', function(){
            $scope.scaleInterface();
          }, false);

          setTimeout(function(){
            $scope.$root.$broadcast('draw-cables');
          }, 100);
        }

        /** Determine signal type of cables patched from passthrough sockets
         */
        $scope.setupCables = function(){
          $scope.patch.cables.map(function(cable){
            $scope.destinations.push(cable.dst);

            if(cable.group > $scope.cableGroup) {
              $scope.cableGroup = cable.group + 1;
            }

            if(typeof($scope.passthrough[cable.src]) !== 'undefined') {
              let signal;
              let upstream = [];
              $scope.patch.cables.map(function(item){
                if($scope.passthrough[cable.src].sockets.indexOf(item.dst) > -1) {
                  upstream.push(item.signal);
                }
              });

              if(upstream.length) {
                if(upstream.indexOf('audio') > -1) {
                  signal = 'audio';
                }
                else if(upstream.length === 1) {
                  signal = upstream[0];
                }
                else if(upstream.length > 1) {
                  signal = 'mod';
                }
              }
              else {
                signal = $scope.passthrough[cable.src].signal;
              }

              cable.signal = signal;
              return cable;
            }
          });
        }

        /** Scale interface based on viewport size
         */
        $scope.scaleInterface = function(){
          const outer = $scope.els.outer;
          const inner = $scope.els.inner;
          clearTimeout($scope.resizeTimeout);

          $scope.resizeTimeout = setTimeout(function(){
            if(inner.offsetWidth > 0) {
              let factor = outer.offsetWidth / inner.offsetWidth;
              if(inner.offsetWidth * factor < $scope.minWidth) {
                factor = $scope.minWidth / inner.offsetWidth;
                outer.style.overflowX = 'scroll';
              }
              else {
                outer.style.overflowX = 'hidden';
              }

              factor = Math.min(factor, 1);

              inner.style.transform = `scale(${factor})`;
              inner.setAttribute('data-scale', factor);
              setTimeout(function(){
                outer.style.height = (inner.offsetHeight * factor) + $scope.scrollbarWidth;
              });
            }
            else {
              inner.setAttribute('data-scale', 1);
              setTimeout(function(){
                outer.style.height = 0;
              });
            }
          }, 10);
        }

        /** Invoke the Add Device modal
         */
        $scope.chooseDevice = function(){
          const canAddDevice = !$scope.user || !$scope.user.plan.permissions.max_devices || $scope.deviceCount() < $scope.user.plan.permissions.max_devices;
          const canAddPatchbay = $scope.deviceCount('patchbay') === 0;
          
          ModalService.showModal({
            templateUrl: 'choose-device.html',
            controller: 'ChooseDeviceController',
            inputs: {
              canAddDevice: canAddDevice,
              canAddPatchbay: canAddPatchbay,
            },
          }).then(function(modal) {
            modal.close.then(function(result) {
              if(result) {
                $scope.addDevice(result);
              }
            });
          });
        }

        /** Add a device to the patch
         */
        $scope.addDevice = function(device){
          let data = {
            id: device.id,
            name: device.name,
            slug: `${device.id}-${new Date().getTime()}`,
            controls: {},
          };

          device.sections.map(function(section){
            section.controls.map(function(control){
              data.controls[control.id] = control.init;
            });
          });

          let patchbay;
          if(AppSettings.keepPatchbayAtBottom && $scope.patch.devices.length) {
            if($scope.patch.devices[$scope.patch.devices.length - 1].id === 'patchbay') {
              patchbay = $scope.patch.devices.pop();
            }
          }

          $scope.patch.devices.push(data);
          if(patchbay) {
            $scope.patch.devices.push(patchbay);
          }

          $scope.setupPermissions();
          $scope.setupInterface();
          $scope.setupDevices();
        }

        /** Remove a device from the patch 
         */
        $scope.removeDevice = function(slug){
          $scope.patch.devices = $scope.patch.devices.filter(function(device){
            return device.slug !== slug;
          });

          $scope.patch.cables = $scope.patch.cables.filter(function(cable){
            return cable.src.indexOf(slug) === -1 && cable.dst.indexOf(slug) === -1;
          });

          $scope.setupPermissions();
          $scope.setupInterface();
          $scope.setupDevices();
          $scope.setupCables();
        }

        /** Move a device up or down
         */
        $scope.moveDevice = function(slug, direction){
          let device;
          let position;

          $scope.patch.devices.map(function(item, index){
            if(item.slug === slug) {
              device = angular.copy(item);
              position = index;
            }
          });

          if((direction === -1 && position > 0) || (direction === 1 && position < $scope.deviceCount() -1)) {
            $scope.patch.devices.splice(position, 1);
            $scope.patch.devices.splice(position + direction, 0, device);
            setTimeout(function(){
              $scope.$root.$broadcast('draw-cables');
            }, 50);
          }
        }

        /** Add a cable to the patch
         */
        $scope.addCable = function(data){
          const src = data[0];
          const dst = data[1];
          const signal = src.config.signal;
          let group;

          if($scope.destinations.indexOf(dst.slug) === -1 || AppSettings.chainSockets) {
            $scope.patch.cables.map(function(item){
              if(src.slug === item.src || src.slug === item.dst || dst.slug === item.src || dst.slug === item.dst) {
                group = item.group;
              }
            });

            if(typeof(group) === 'undefined') {
              group = $scope.cableGroup;
              $scope.cableGroup+= 1;
            }

            $scope.patch.cables.push({
              index: $scope.patch.cables.length,
              group: group,
              signal: signal,
              src: src.slug,
              dst: dst.slug,
            });

            $scope.setupCables();
            $scope.$root.$broadcast('draw-cables');
          }
        }

        /** Remove a cable from the patch
         */
        $scope.removeCable = function(data){
          $scope.patch.cables = $scope.patch.cables.filter(function(item){
            return item.src !== data && item.dst !== data;
          });

          $scope.setupCables();
          $scope.$root.$broadcast('draw-cables');
        }

        /** Count the instances of a specific device, or non-default devices, in a patch
         */
        $scope.deviceCount = function(id){
          let count = 0;
          $scope.patch.devices.map(function(item){
            if(id && item.id === id) {
              count+= 1;
            }
            else if(!id && item.id !== 'patchbay') {
              count+= 1;
            }
          });

          return count;
        }

        /** Event listeners
         */
        $scope.$on('add-cable', function($event, data){
          $scope.addCable(data);
        });

        $scope.$on('remove-cable', function($event, slug){
          $scope.removeCable(slug);
        });

        $scope.$on('device-remove', function($event, slug){
          $scope.removeDevice(slug);
        });

        $scope.$on('device-up', function($event, slug){
          $scope.moveDevice(slug, -1);
        });

        $scope.$on('device-down', function($event, slug){
          $scope.moveDevice(slug, 1);
        });

        $scope.init();

        $scope.debug = function(){
          let output = [
            'permissions: ' + JSON.stringify($scope.permissions, null, 2),
            'destinations: ' + JSON.stringify($scope.destinations, null, 2),
            'patch: ' + JSON.stringify($scope.patch, null, 2),
            'passthrough: ' + JSON.stringify($scope.passthrough, null, 2),
            'user: ' + JSON.stringify($scope.user, null, 2),
          ];
          return output.join('\n\n');
        }
      }]
    };
  }
]
