export default {
  "name": "Sherman Filterbank",
  "id": "sherman-filterbank",
  "vendor": "Sherman",
  "width": 1720,
  "height": 460,
  "scale": 1,
  "images": {
    "panel": "sherman-panel.png",
    "thumbnail": "sherman-thumbnail.png",
    "selector": "sherman-selector.png"
  },
  "notes": "The Sherman Filterbank is dual analog filter & VCA with built-in modulation. While not fully modular, It does feature modulation patch points for integrating into a (semi) modular setup.",
  "link": "http://www.sherman.be/products",
  "sections": [
    {
      "id": "input",
      "name": "Input",
      "controls": [
        {
          "id": "input-level",
          "name": "Input",
          "type": "knob",
          "class": "knob-sm-g",
          "top": 81,
          "left": 395,
          "init": 180
        },
        {
          "id": "input-fm",
          "name": "FM",
          "type": "knob",
          "class": "knob-sm-b",
          "top": 128,
          "left": 479,
          "init": 30
        },
        {
          "id": "input-hi",
          "name": "Hi Boost / Cut",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Hi Cut",
            "Off",
            "Hi Boost"
          ],
          "top": 83,
          "left": 284,
          "init": 1
        },
        {
          "id": "input-sensi",
          "name": "Sensitrig / Limit",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Limit",
            "Off",
            "Sensitrig"
          ],
          "top": 156,
          "left": 332,
          "init": 1
        },
        {
          "id": "input-trans",
          "name": "Octave / Quint",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "+ Quint",
            "Off",
            "+ Octave"
          ],
          "top": 223,
          "left": 282,
          "init": 1
        },
        {
          "id": "input-track",
          "name": "Track",
          "type": "switch",
          "class": "switch-1",
          "values": [
            "Track Low",
            "Off",
            "Track"
          ],
          "top": 296,
          "left": 292,
          "init": 1
        }
      ],
      "sockets": [
      ]
    },
    {
      "id": "adsr",
      "name": "ADSR",
      "controls": [
        {
          "id": "adsr-a",
          "name": "Attack",
          "type": "knob",
          "class": "knob-sm-y",
          "top": 128,
          "left": 598,
          "init": 30
        },
        {
          "id": "adsr-d",
          "name": "Decay",
          "type": "knob",
          "class": "knob-sm-y",
          "top": 81,
          "left": 710,
          "init": 30
        },
        {
          "id": "adsr-s",
          "name": "Sustain / Sens",
          "type": "knob",
          "class": "knob-depth",
          "top": 128,
          "left": 823,
          "init": 180
        },
        {
          "id": "adsr-r",
          "name": "Release",
          "type": "knob",
          "class": "knob-sm-y",
          "top": 128,
          "left": 945,
          "init": 30
        },
        {
          "id": "adsr-mode",
          "name": "Mode",
          "type": "switch",
          "class": "switch-3",
          "values": [
            "Env. Follow",
            "ADSR"
          ],
          "top": 82,
          "left": 993,
          "init": 0
        }
      ],
      "sockets": [
      ]
    },
    {
      "id": "lfo",
      "name": "LFO",
      "controls": [
        {
          "id": "lfo-speed",
          "name": "Speed",
          "type": "knob",
          "class": "knob-sm-b",
          "top": 81,
          "left": 1067,
          "init": 180
        },
        {
          "id": "lfo-depth",
          "name": "Depth",
          "type": "knob",
          "class": "knob-sm-b",
          "top": 81,
          "left": 1190,
          "init": 180
        },
        {
          "id": "lfo-mode",
          "name": "Mode",
          "type": "switch",
          "class": "switch-2",
          "values": [
            "AR Trig",
            "Triangle",
            "Ramp"
          ],
          "top": 149,
          "left": 1066,
          "init": 1
        }
      ],
      "sockets": [
      ]
    },
    {
      "id": "output",
      "name": "Output",
      "controls": [
        {
          "id": "output-am",
          "name": "AM",
          "type": "knob",
          "class": "knob-sm-g",
          "top": 81,
          "left": 1317,
          "init": 30
        },
        {
          "id": "output-route",
          "name": "Par / Ser",
          "type": "knob",
          "class": "knob-sm-o",
          "top": 81,
          "left": 1496,
          "init": 30
        },
        {
          "id": "output-mix",
          "name": "Byp / Eff",
          "type": "knob",
          "class": "knob-sm-w",
          "top": 81,
          "left": 1617,
          "init": 330
        },
        {
          "id": "output-a",
          "name": "Attack",
          "type": "knob",
          "class": "knob-sm-b",
          "top": 162,
          "left": 1435,
          "init": 30
        },
        {
          "id": "output-r",
          "name": "Release",
          "type": "knob",
          "class": "knob-sm-b",
          "top": 162,
          "left": 1557,
          "init": 330
        }
      ],
      "sockets": [
      ]
    },
    {
      "id": "filter1",
      "name": "Filter 1",
      "controls": [
        {
          "id": "filter1-freq",
          "name": "Freq 1",
          "type": "knob",
          "class": "knob-lg",
          "top": 244,
          "left": 395,
          "init": 330
        },
        {
          "id": "filter1-env",
          "name": "Envelope Depth",
          "type": "knob",
          "class": "knob-depth",
          "top": 245,
          "left": 517,
          "init": 180
        },
        {
          "id": "filter1-reso",
          "name": "Reso 1",
          "type": "knob",
          "class": "knob-sm-o",
          "top": 244,
          "left": 640,
          "init": 30
        },
        {
          "id": "filter1-shape",
          "name": "Shape",
          "type": "knob",
          "class": "knob-sm-w",
          "top": 244,
          "left": 762,
          "init": 30
        },
        {
          "id": "filter1-band",
          "name": "Band",
          "type": "knob",
          "class": "knob-sm-r",
          "top": 244,
          "left": 884,
          "init": 180
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "filter2",
      "name": "Filter 2",
      "controls": [
        {
          "id": "filter2-harmonics",
          "name": "Harmonics",
          "type": "knob",
          "class": "knob-harmonics",
          "values": [
            "Free",
            "1",
            "1.5",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "16"
          ],
          "range": [
            30,
            360
          ],
          "top": 244,
          "left": 1006,
          "init": 30
        },
        {
          "id": "filter2-freq",
          "name": "Freq 2",
          "type": "knob",
          "class": "knob-lg",
          "top": 244,
          "left": 1129,
          "init": 330
        },
        {
          "id": "filter2-env",
          "name": "Envelope Depth",
          "type": "knob",
          "class": "knob-depth",
          "top": 244,
          "left": 1251,
          "init": 180
        },
        {
          "id": "filter2-reso",
          "name": "Reso 2",
          "type": "knob",
          "class": "knob-sm-o",
          "top": 244,
          "left": 1373,
          "init": 30
        },
        {
          "id": "filter2-shape",
          "name": "Shape",
          "type": "knob",
          "class": "knob-sm-w",
          "top": 244,
          "left": 1496,
          "init": 30
        },
        {
          "id": "filter2-band",
          "name": "Band",
          "type": "knob",
          "class": "knob-sm-r",
          "top": 244,
          "left": 1618,
          "init": 180
        }
      ],
      "sockets": [
        
      ]
    },
    {
      "id": "io",
      "name": "Input / Output",
      "controls": [
        
      ],
      "sockets": [
        {
          "id": "io-pedal",
          "name": "Pedal",
          "type": "input",
          "signal": "modulation",
          "top": 418,
          "left": 292
        },
        {
          "id": "io-input",
          "name": "Signal In",
          "type": "input",
          "signal": "audio",
          "top": 418,
          "left": 381
        },
        {
          "id": "io-fm",
          "name": "FM In",
          "type": "input",
          "signal": "unknown",
          "top": 418,
          "left": 470
        },
        {
          "id": "io-trig-adsr",
          "name": "Trigger ADSR",
          "type": "input",
          "signal": "trigger",
          "top": 418,
          "left": 558
        },
        {
          "id": "io-link-in",
          "name": "Link In",
          "type": "input",
          "signal": "modulation",
          "top": 418,
          "left": 648
        },
        {
          "id": "io-link-out",
          "name": "Link Out",
          "type": "output",
          "signal": "modulation",
          "top": 418,
          "left": 736
        },
        {
          "id": "io-adsr-out",
          "name": "ADSR Out",
          "type": "output",
          "signal": "modulation",
          "top": 418,
          "left": 824
        },
        {
          "id": "io-am-in",
          "name": "AM In",
          "type": "input",
          "signal": "unknown",
          "top": 418,
          "left": 1351
        },
        {
          "id": "io-trig-ar",
          "name": "Trigger AR",
          "type": "input",
          "signal": "trigger",
          "top": 418,
          "left": 1440
        },
        {
          "id": "io-out-1",
          "name": "Out 1",
          "type": "output",
          "signal": "audio",
          "top": 418,
          "left": 1528
        },
        {
          "id": "io-out-main",
          "name": "Main Out",
          "type": "output",
          "signal": "audio",
          "top": 418,
          "left": 1617
        }
      ]
    }
  ]
}
