import angular from 'angular';

export default ['$q', '$http', 'AppSettings', 'Devices', 'UserService',
  function($q, $http, AppSettings, Devices, UserService){
    return {

      getCategories: function(){
        if(typeof(window.APP.categories) === 'undefined') {
          return $http.get('/api/patch/categories', {
            cache: true,
          })
          .then(function success(response){
            return response.data;
          }, 
          function error(error){
            return {
              success: false,
              error: error.config.url,
            }
          });
        }
        else {
          const deferral = $q.defer(); 
          deferral.resolve({ 
            success: true,
            data: window.APP.categories,
          });
          return deferral.promise;
        }
      },

      getDevices: function(noPatchbay){
        const deferral = $q.defer();
        let devices;

        if(this.getDevicesCache) {
          devices = this.getDevicesCache;
        }
        else {
          devices = angular.copy(Devices);
          if(noPatchbay) {
            delete devices['patchbay'];
          }

          // Build lookup table of controls/sockets by id
          Object.keys(devices).forEach(key => {
            devices[key].lookup = {};
            devices[key].sections.forEach(function(section){
              if(typeof(section.controls) !== 'undefined') {
                section.controls.forEach(function(item){
                  devices[key].lookup[item.id] = item;
                });
              }
              
              if(typeof(section.sockets) !== 'undefined') {
                section.sockets.forEach(function(item){
                  devices[key].lookup[item.id] = item;
                });
              }
            });
          });
          this.getDevicesCache = devices;
        }

        deferral.resolve({ 
          success: true,
          data: devices,
        });

        return deferral.promise;
      },

      getDevice: function(id){
        return this.getDevicesCache[id];
      },

      getPatch: function(id){
        if(id) {
          if(typeof(window.APP.patch) === 'undefined') {
            return $http.get(`/api/patch/get/${id}`)
            .then(function success(response){
              return response.data;
            }, 
            function error(error){
              return {
                success: false,
                error: error.config.url,
              }
            });
          }
          else {
            const deferral = $q.defer(); 
            deferral.resolve({ 
              success: true,
              data: window.APP.patch,
            });
            return deferral.promise;
          }
        }
        else {
          const deferral = $q.defer(); 
          deferral.resolve({ 
            success: true,
            data: {
              id: null,
              copy_id: null,
              user: null,
              title: null,
              slug: null,
              description: null,
              notes: null,
              media: null,
              private: null,
              category: null,
              tags: null,
              devices: [],
              cables: [],
            },
          });
          return deferral.promise;
        }
      },

      savePatch: function(data){
        return $http.post('/api/patch/save', data)
        .then(function(response){
          return response.data;
        },
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      deletePatch: function(data){
        return $http.post('/api/patch/delete', data)
        .then(function(response){
          return response.data;
        },
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      likePatch: function(data){
        return $http.post('/api/patch/like', data)
        .then(function(response){
          return response.data;
        },
        function error(error){
          return {
            success: false,
            error: error.config.url,
          }
        });
      },

      nowPatchingData: null,
      nowPatching: function(data){
        if(typeof(data) !== 'undefined') {
          this.nowPatchingData = data;
        }
        else {
          return this.nowPatchingData;
        }
      },

      getCableColor: function(cable){
        const cableColor = UserService.getSetting('cableColor') || AppSettings.userSettings.cableColor;
        let color;

        switch(cableColor) {
          case 'group':
            color = AppSettings.cableColors.group[(parseInt(cable.group) + AppSettings.cableColors.group.length) % AppSettings.cableColors.group.length];
            break;

          case 'signal':
            color = AppSettings.cableColors.signal[cable.signal];
            break;
        }

        return color;
      },

    };
  }
]

