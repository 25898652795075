export default {
  "name": "Erebus V3",
  "id": "erebus-v3",
  "vendor": "Dreadbox",
  "width": 1320,
  "height": 650,
  "scale": 1,
  "images": {
    "panel": "erebus3-panel.png",
    "thumbnail": "erebus3-thumbnail.png",
    "selector": "erebus3-selector.png"
  },
  "notes": "The Erebus 3 is the newest incarnation of Dreadbox's desktop analogue synthesizer.",
  "link": "https://www.dreadbox-fx.com/erebus3",
  "sections": [
    {
      "id": "osc12",
      "name": "Osc 1 & 2",
      "controls": [
        {
          "id": "osc1-tune",
          "name": "Osc 1 Tune",
          "type": "knob",
          "class": "knob-1",
          "top": 157,
          "left": 114,
          "init": 180
        },
        {
          "id": "osc1-glide",
          "name": "Osc 1 Glide",
          "type": "slider",
          "class": "slider-1",
          "top": 212,
          "left": 212,
          "init": 0
        }, 
        {
          "id": "osc1-octave",
          "name": "Osc 1 Octave",
          "type": "switch",
          "class": "switch-1",
          "values": ["-1", "0", "+1"],
          "top": 271,
          "left": 86,
          "init": 1
        }, 
        {
          "id": "osc1-wave",
          "name": "Osc 1 Wave",
          "type": "switch",
          "class": "switch-1",
          "values": ["Pulse", "Off", "Saw"],
          "top": 271,
          "left": 143,
          "init": 0
        }, 
        {
          "id": "osc2-tune",
          "name": "Osc 2 Tune",
          "type": "knob",
          "class": "knob-1",
          "top": 157,
          "left": 445,
          "init": 180
        },
        {
          "id": "osc2-glide",
          "name": "Osc 2 Glide",
          "type": "slider",
          "class": "slider-1",
          "top": 212,
          "left": 347,
          "init": 0
        }, 
        {
          "id": "osc2-octave",
          "name": "Osc 2 Octave",
          "type": "switch",
          "class": "switch-1",
          "values": ["-1", "0", "+1"],
          "top": 271,
          "left": 474,
          "init": 1
        }, 
        {
          "id": "osc2-wave",
          "name": "Osc 2 Wave",
          "type": "switch",
          "class": "switch-1",
          "values": ["Triangle", "Off", "Saw"],
          "top": 271,
          "left": 418,
          "init": 0
        }, 
        {
          "id": "osc12-mix",
          "name": "Mix",
          "type": "slider",
          "class": "slider-2",
          "direction": "h",
          "top": 61,
          "left": 267,
          "init": 0
        },
        {
          "id": "osc12-ring-half",
          "name": "Triple Ring 1/2",
          "type": "slider",
          "class": "slider-1",
          "top": 212,
          "left": 259,
          "init": 0
        }, 
        {
          "id": "osc12-ring-third",
          "name": "Triple Ring 1/3",
          "type": "slider",
          "class": "slider-1",
          "top": 212,
          "left": 301,
          "init": 0
        }, 
      ],
      "sockets": [
      ],
    },

    {
      "id": "osc3",
      "name": "Osc 3",
      "controls": [
        {
          "id": "osc3-tune",
          "name": "Tune",
          "type": "knob",
          "class": "knob-1",
          "top": 157,
          "left": 716,
          "init": 180
        },
        {
          "id": "osc3-wave",
          "name": "Waveform",
          "type": "knob",
          "class": "knob-2",
          "range": [40, 220],
          "values": ["Saw", "Ramp", "Pulse", "Triangle", "Sine", "Double", "Noise"],
          "top": 83,
          "left": 612,
          "init": 40
        },
        {
          "id": "osc3-fine",
          "name": "Fine",
          "type": "knob",
          "class": "knob-2",
          "top": 83,
          "left": 820,
          "init": 180
        },
        {
          "id": "osc3-level",
          "name": "Level",
          "type": "knob",
          "class": "knob-2",
          "top": 252,
          "left": 612,
          "init": 30
        },
        {
          "id": "osc3-fm",
          "name": "FM",
          "type": "knob",
          "class": "knob-2",
          "top": 252,
          "left": 820,
          "init": 30
        },
      ],
      "sockets": [
      ]
    },

    {
      "id": "lfo",
      "name": "LFO",
      "controls": [
        {
          "id": "lfo-rate",
          "name": "Rate",
          "type": "slider",
          "class": "slider-1",
          "top": 212,
          "left": 921,
          "init": 0
        }, 
        {
          "id": "lfo-depth",
          "name": "Depth",
          "type": "slider",
          "class": "slider-1",
          "top": 212,
          "left": 963,
          "init": 0
        }
      ],
      "sockets": [
      ],
    },

    {
      "id": "filter",
      "name": "Filter",
      "controls": [
        {
          "id": "filter-cutoff",
          "name": "Cutoff",
          "type": "knob",
          "class": "knob-1",
          "top": 500,
          "left": 97,
          "init": 330
        },
        {
          "id": "filter-res",
          "name": "Res",
          "type": "knob",
          "class": "knob-2",
          "top": 391,
          "left": 159,
          "init": 30
        },
        {
          "id": "filter-mode",
          "name": "Mode",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 219,
          "init": 0
        }
      ],
      "sockets": [
      ],
    },

    {
      "id": "envelope",
      "name": "Envelope",
      "controls": [
        {
          "id": "envelope-loop",
          "name": "Loop",
          "type": "switch",
          "class": "switch-2",
          "values": ["Off", "On"],
          "top": 359,
          "left": 468,
          "init": 0
        },
        {
          "id": "envelope-a",
          "name": "A",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 293,
          "init": 0
        },
        {
          "id": "envelope-d",
          "name": "D",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 335,
          "init": 0
        },
        {
          "id": "envelope-s",
          "name": "S",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 377,
          "init": 100
        },
        {
          "id": "envelope-r",
          "name": "R",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 419,
          "init": 0
        },
        {
          "id": "envelope-level",
          "name": "Level",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 469,
          "init": 100
        }
      ],
    },

    {
      "id": "amp",
      "name": "Amp",
      "controls": [
        {
          "id": "amp-a",
          "name": "A",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 539,
          "init": 0
        },
        {
          "id": "amp-d",
          "name": "D",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 580,
          "init": 0
        },
        {
          "id": "amp-s",
          "name": "S",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 622,
          "init": 100
        },
        {
          "id": "amp-r",
          "name": "R",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 664,
          "init": 0
        },
        {
          "id": "amp-level",
          "name": "Level",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 715,
          "init": 100
        }
      ],
    },

    {
      "id": "echo",
      "name": "Echo",
      "controls": [
        {
          "id": "echo-mix",
          "name": "Mix",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 786,
          "init": 0
        },
        {
          "id": "echo-feed",
          "name": "Feed",
          "type": "slider",
          "class": "slider-1",
          "top": 487,
          "left": 828,
          "init": 50
        },
        {
          "id": "echo-time",
          "name": "Time",
          "type": "knob",
          "class": "knob-1",
          "top": 480,
          "left": 912,
          "init": 180
        }
      ],
    },

    {
      "id": "clock",
      "name": "Clock",
      "controls": [
        {
          "id": "clock-rate",
          "name": "Rate",
          "type": "knob",
          "class": "knob-3",
          "top": 219,
          "left": 1275,
          "init": 180
        },
        {
          "id": "clock-duty",
          "name": "Duty",
          "type": "knob",
          "class": "knob-3",
          "top": 325,
          "left": 1275,
          "init": 180
        }
      ],
      "sockets": [
        {
          "id": "clock-cv-in",
          "name": "Rate CV",
          "type": "input",
          "signal": "modulation",
          "top": 160,
          "left": 1275
        },
        {
          "id": "clock-out",
          "name": "Out",
          "type": "output",
          "signal": "clock",
          "top": 395,
          "left": 1275
        },
      ],
    },

    {
      "id": "patchbay",
      "name": "Patchbay",
      "controls": [
        {
          "id": "pb-vca-level",
          "name": "VCA Level",
          "type": "knob",
          "class": "knob-3",
          "top": 278,
          "left": 1195,
          "init": 30
        },
        {
          "id": "pb-sh-level",
          "name": "S+H Level",
          "type": "knob",
          "class": "knob-3",
          "top": 336,
          "left": 1195,
          "init": 30
        }
      ],
      "sockets": [
        {
          "id": "pb-gate-out",
          "name": "Gate Out",
          "type": "output",
          "signal": "gate",
          "top": 160,
          "left": 1027
        },
        {
          "id": "pb-mod-out",
          "name": "Mod Out",
          "type": "output",
          "signal": "modulation",
          "top": 160,
          "left": 1083
        },
        {
          "id": "pb-env-out",
          "name": "Env Out",
          "type": "output",
          "signal": "modulation",
          "top": 160,
          "left": 1139
        },
        {
          "id": "pb-noise-out",
          "name": "Noise Out",
          "type": "output",
          "signal": "audio",
          "top": 160,
          "left": 1195
        },
        {
          "id": "pb-cv-out",
          "name": "CV Out",
          "type": "output",
          "signal": "pitch",
          "top": 219,
          "left": 1027
        },
        {
          "id": "pb-lfo-out",
          "name": "LFO Out",
          "type": "output",
          "signal": "modulation",
          "top": 219,
          "left": 1083
        },
        {
          "id": "pb-osc3-out",
          "name": "Osc 3 Out",
          "type": "output",
          "signal": "audio",
          "top": 219,
          "left": 1139
        },

        {
          "id": "pb-vca-out1",
          "name": "VCA Out +",
          "type": "output",
          "signal": "unknown",
          "pass": ["pb-vca-in"],
          "top": 219,
          "left": 1195
        },
        {
          "id": "pb-vca-in",
          "name": "VCA In",
          "type": "input",
          "signal": "unknown",
          "top": 278,
          "left": 1027
        },
        {
          "id": "pb-vcacv-in",
          "name": "VCA CV",
          "type": "input",
          "signal": "modulation",
          "top": 278,
          "left": 1083
        },
        {
          "id": "pb-vca-out2",
          "name": "VCA Out -",
          "type": "output",
          "signal": "unknown",
          "pass": ["pb-vca-in"],
          "top": 278,
          "left": 1139
        },

        {
          "id": "pb-sh-in",
          "name": "S+H In",
          "type": "input",
          "signal": "modulation",
          "top": 336,
          "left": 1027
        },
        {
          "id": "pb-sh-clock",
          "name": "S+H Clock",
          "type": "input",
          "signal": "clock",
          "top": 336,
          "left": 1083
        },
        {
          "id": "pb-sh-out",
          "name": "S+H Out",
          "type": "output",
          "signal": "modulation",
          "top": 336,
          "left": 1139
        },

        {
          "id": "pb-sw-x",
          "name": "SW X",
          "type": "input",
          "signal": "unknown",
          "top": 395,
          "left": 1027
        },
        {
          "id": "pb-sw-y",
          "name": "SW Y",
          "type": "input",
          "signal": "unknown",
          "top": 395,
          "left": 1083
        },
        {
          "id": "pb-sw-clock",
          "name": "SW Clock",
          "type": "input",
          "signal": "clock",
          "top": 395,
          "left": 1139
        },
        {
          "id": "pb-sw-z",
          "name": "SW Z",
          "type": "output",
          "signal": "unknown",
          "pass": ["pb-sw-x", "pb-sw-y"],
          "top": 395,
          "left": 1195
        },

        {
          "id": "pb-cv1-in",
          "name": "CV 1 In",
          "type": "input",
          "signal": "pitch",
          "top": 454,
          "left": 1027
        },
        {
          "id": "pb-cv2-in",
          "name": "CV 2 In",
          "type": "input",
          "signal": "pitch",
          "top": 454,
          "left": 1083
        },
        {
          "id": "pb-cv3-in",
          "name": "CV 3 In",
          "type": "input",
          "signal": "pitch",
          "top": 454,
          "left": 1139
        },
        {
          "id": "pb-cvall-in",
          "name": "CV All In",
          "type": "input",
          "signal": "pitch",
          "top": 454,
          "left": 1195
        },

        {
          "id": "pb-pw1-in",
          "name": "PW 1 In",
          "type": "input",
          "signal": "modulation",
          "top": 512,
          "left": 1027
        },
        {
          "id": "pb-echo-in",
          "name": "Echo CV In",
          "type": "input",
          "signal": "modulation",
          "top": 512,
          "left": 1083
        },
        {
          "id": "pb-pw3-in",
          "name": "PW 3 In",
          "type": "input",
          "signal": "modulation",
          "top": 512,
          "left": 1139
        },
        {
          "id": "pb-gate-in",
          "name": "Gate In",
          "type": "input",
          "signal": "gate",
          "top": 512,
          "left": 1195
        },

        {
          "id": "pb-cut-in",
          "name": "Cut In",
          "type": "input",
          "signal": "modulation",
          "top": 571,
          "left": 1027
        },
        {
          "id": "pb-res-in",
          "name": "Res In",
          "type": "input",
          "signal": "modulation",
          "top": 571,
          "left": 1083
        },
        {
          "id": "pb-filter-in",
          "name": "Filter In",
          "type": "input",
          "signal": "audio",
          "top": 571,
          "left": 1139
        },
        {
          "id": "pb-amp-in",
          "name": "Amp In",
          "type": "input",
          "signal": "audio",
          "top": 571,
          "left": 1195
        },

        {
          "id": "pb-mult1",
          "name": "Multi 1",
          "type": "any",
          "signal": "unknown",
          "pass": ["pb-mult1", "pb-mult2", "pb-mult3"],
          "top": 454,
          "left": 1275
        },
        {
          "id": "pb-mult2",
          "name": "Multi 2",
          "type": "any",
          "signal": "unknown",
          "pass": ["pb-mult1", "pb-mult2", "pb-mult3"],
          "top": 512,
          "left": 1275
        },
        {
          "id": "pb-mult3",
          "name": "Multi 3",
          "type": "any",
          "signal": "unknown",
          "pass": ["pb-mult1", "pb-mult2", "pb-mult3"],
          "top": 571,
          "left": 1275
        }

      ],
    }

  ]
}
